body.tn-syos--viewing-seats {
    .tn-syos__layout-container, .tn-syos-screen-container, .tn-syos-seat-container, .tn-syos-seat-container-viewport {
        min-height: 85vh !important;
        @media (min-width: $screen-sm-min) {
            min-height: 840px !important;
        }
    }
    .tn-syos-seat-container-viewport__content-wrapper {
        top: 40px;
        bottom: 40px;
        width: 1200px;
        max-width: 98%;
        margin: -2% auto 0;
    }
    .tn-syos-seat-container-viewport {
    }
    .tn-syos-seat-map {
        position: relative;
        z-index: 20;
        &::before {
            content: '';
            position: absolute;
            top: -86%;
            left: -7%;
            width: 115%;
            height: 360%;
            // top: -20%;
            // left: -5%;
            // width: 110%;
            // height: 200%;
            background: url('https://s3-eu-west-1.amazonaws.com/trp-tnew-template/qa/img/background.png');
            background-size: 72.5%;
            background-position: top center;
            background-repeat: no-repeat;
            z-index: 10;
        }
    }
    .tn-syos-seat-map__svg {
        max-height: 100%;
        position: relative;
        overflow: visible;
        background: transparent;
        z-index: 30;
    }
    .tn-syos-seat-map__non-seats {
        visibility: hidden;
    }
    .tn-syos-zoom-control {
        z-index: 40;
    }
}

body.state--venue-guildhall {
    .tn-syos-seat-map {
        position: relative;
        &::before {
            content: "Stage";
            font-size: 80px;
            position: absolute;
            background: #000;
            color: #fff;
            bottom: -3%;
            width: 2200px;
            right: 17%;
            padding: 50px 0;
            text-align: center;
            z-index: 3000;
        }
        &::after {
            content: '';
            position: absolute;
            background: #000;
            color: #fff;
            bottom: 74%;
            width: 2200px;
            right: 17%;
            height: 20px;
            text-align: center;
            z-index: 3000;
        }
    }
    .tn-syos-seat-map__non-seats {
        visibility: visible;
    }
}

.tn-syos {
    .tn-syos__btn-add-to-cart,
    .tn-syos-mobile-cart-controls__summary-subtotal {
        font-size: 1em;
        @media (min-width: $screen-sm-min) {
            font-size: 1.25em;
        }
    }
}

// If seatmap ID is 587, show the seat and row numbers
body.seatmap--587 {
    .tn-syos-seat-map__non-seats {
        visibility: visible;
    }
}
