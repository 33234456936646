/* ==========================================================================
   #GLOBAL FOOTER styles
   ========================================================================== */

.c-global-footer {
    width: 100%;
    max-width: $max-site-width;
    text-align: center;
    margin: $padding-large-vertical 0 $padding-large-vertical 0;

    button {
        border: none;
        text-decoration: underline;
        @include font-bold;
    }
}

