/* ==========================================================================
   #TEXT SIZES
   ========================================================================== */


$font-size-tiny: 60% !default;
$font-size-small: 80% !default;
$font-size-regular: 100% !default;
$font-size-medium: 130% !default;
$font-size-large: 180% !default;
$font-size-xl: 240% !default;


.o-txt-tiny {
   font-size: $font-size-tiny; // 60%
   line-height: 1;
}

@mixin font-size-tiny {
   font-size: $font-size-tiny; // 60%
   line-height: 1;
}

.o-txt-small {
   font-size: $font-size-small; // 80%
   line-height: 1;

   @include mq($from: tablet) {
       font-size: 100%;
   }
}

@mixin font-size-small {
   font-size: $font-size-small; // 80%
   line-height: 1;

   @include mq($from: tablet) {
       font-size: 100%;
   }
}

.o-txt-regular {
   font-size: $font-size-regular; // 100%
   line-height: 1.2;

   @include mq($from: tablet) {
       font-size: 120%;
   }
}

@mixin font-size-regular {
   font-size: $font-size-regular; // 100%
   line-height: 1.2;

   @include mq($from: tablet) {
       font-size: 120%;
   }


   @include mq($from: superwide) {
       .u-1\/1\@desktop &, .u-1\/2\@desktop {
           font-size: 125%;
       }
   }
}

.o-txt-medium {
   font-size: $font-size-medium; // 120%
   line-height: 1.3;

   @include mq($from: tablet) {
       font-size: 160%;
       line-height: 1.3;
   }
}

@mixin font-size-medium {
   font-size: $font-size-medium; // 120%
   line-height: 1.3;

   @include mq($from: tablet) {
       font-size: 160%;
       line-height: 1.3;
   }
}

.o-txt-large {
   font-size: $font-size-large; // 160%
   line-height: 1.1;

   @include mq($from: tablet) {
       font-size: 240%;
       line-height: 1.3;
   }
}

@mixin font-size-large {
   font-size: $font-size-large; // 160%
   line-height: 1.2;

   @include mq($from: tablet) {
       font-size: 240%;
       line-height: 1.3;
   }
}

.o-txt-xl {
   font-size: $font-size-xl; // 240%

   @include mq($from: tablet) {
       font-size: 400%;
   }
}

@mixin font-size-xl {
   font-size: $font-size-xl; // 240%

   @include mq($from: tablet) {
       font-size: 400%;
       line-height: 1.3;
   }
}

.o-txt-center {
   text-align: center;
}

h1 {
   @include font-size-large;
}

h2, h3 {
   @include font-size-medium;
}

h4, h5, h6 {
   @include font-size-regular;
}
