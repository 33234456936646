@supports not (-ms-high-contrast: none) {
    /* Non-IE styles here */
 
 .tn-syos-seat-map__seat {

    // Left Section 
    // Row A
    &[data-tn-seat-id="559183"] { transform: matrix(1, 0, 0, 1, 580, 830); } // Seat A50
    &[data-tn-seat-id="559184"] { transform: matrix(1, 0, 0, 1, 580, 760); } // Seat A49

    &[data-tn-seat-id="559105"] { transform: matrix(1, 0, 0, 1, 430, 560); } // Seat A48
    &[data-tn-seat-id="559026"] { transform: matrix(1, 0, 0, 1, 290, 700); } // Seat A47

    &[data-tn-seat-id="558947"] { transform: matrix(1, 0, 0, 1, 200, 450); } // Seat A46
    &[data-tn-seat-id="558868"] { transform: matrix(1, 0, 0, 1, 60, 590); } // Seat A45

    &[data-tn-seat-id="558709"] { transform: matrix(1, 0, 0, 1, 110, 470); } // Seat A44
    &[data-tn-seat-id="558710"] { transform: matrix(1, 0, 0, 1, 90, 420); }
    &[data-tn-seat-id="558711"] { transform: matrix(1, 0, 0, 1, 70, 370); }
    &[data-tn-seat-id="558712"] { transform: matrix(1, 0, 0, 1, 50, 320); }
    &[data-tn-seat-id="558713"] { transform: matrix(1, 0, 0, 1, 30, 270); }
    &[data-tn-seat-id="558714"] { transform: matrix(1, 0, 0, 1, 10, 220); }
    &[data-tn-seat-id="558715"] { transform: matrix(1, 0, 0, 1, -10, 170); }
    &[data-tn-seat-id="558716"] { transform: matrix(1, 0, 0, 1, -30, 110); }
    &[data-tn-seat-id="558717"] { transform: matrix(1, 0, 0, 1, -50, 60); }
    &[data-tn-seat-id="558718"] { transform: matrix(1, 0, 0, 1, -70, 10); } // Seat A35

    // Row B
    &[data-tn-seat-id="558628"] { transform: matrix(1, 0, 0, 1, 110, 430); } // Seat B46
    &[data-tn-seat-id="558629"] { transform: matrix(1, 0, 0, 1, 90, 380); }
    &[data-tn-seat-id="558630"] { transform: matrix(1, 0, 0, 1, 70, 330); }
    &[data-tn-seat-id="558631"] { transform: matrix(1, 0, 0, 1, 50, 280); }
    &[data-tn-seat-id="558632"] { transform: matrix(1, 0, 0, 1, 30, 230); }
    &[data-tn-seat-id="558633"] { transform: matrix(1, 0, 0, 1, 10, 180); }
    &[data-tn-seat-id="558634"] { transform: matrix(1, 0, 0, 1, -10, 130); }
    &[data-tn-seat-id="558635"] { transform: matrix(1, 0, 0, 1, -30, 80); }
    &[data-tn-seat-id="558636"] { transform: matrix(1, 0, 0, 1, -50, 30); }
    &[data-tn-seat-id="558637"] { transform: matrix(1, 0, 0, 1, -70, -20); } // Seat B37

    // Row C
    &[data-tn-seat-id="558547"] { transform: matrix(1, 0, 0, 1, 150, 350); } // Seat C48
    &[data-tn-seat-id="558548"] { transform: matrix(1, 0, 0, 1, 130, 300); }
    &[data-tn-seat-id="558549"] { transform: matrix(1, 0, 0, 1, 110, 250); }
    &[data-tn-seat-id="558550"] { transform: matrix(1, 0, 0, 1, 90, 200); }
    &[data-tn-seat-id="558551"] { transform: matrix(1, 0, 0, 1, 70, 150); }
    &[data-tn-seat-id="558552"] { transform: matrix(1, 0, 0, 1, 50, 100); }
    &[data-tn-seat-id="558553"] { transform: matrix(1, 0, 0, 1, 30, 50); }
    &[data-tn-seat-id="558554"] { transform: matrix(1, 0, 0, 1, 10, -10); }
    &[data-tn-seat-id="558555"] { transform: matrix(1, 0, 0, 1, -10, -60); }
    &[data-tn-seat-id="558556"] { transform: matrix(1, 0, 0, 1, -30, -110); } // Seat C39

    // Row D
    &[data-tn-seat-id="558467"] { transform: matrix(1, 0, 0, 1, 120, 260); } // Seat D48
    &[data-tn-seat-id="558468"] { transform: matrix(1, 0, 0, 1, 100, 210); }
    &[data-tn-seat-id="558469"] { transform: matrix(1, 0, 0, 1, 80, 160); }
    &[data-tn-seat-id="558470"] { transform: matrix(1, 0, 0, 1, 60, 110); }
    &[data-tn-seat-id="558471"] { transform: matrix(1, 0, 0, 1, 40, 60); }
    &[data-tn-seat-id="558472"] { transform: matrix(1, 0, 0, 1, 20, 10); }
    &[data-tn-seat-id="558473"] { transform: matrix(1, 0, 0, 1, 0, -40); }
    &[data-tn-seat-id="558474"] { transform: matrix(1, 0, 0, 1, -20, -90); }
    &[data-tn-seat-id="558475"] { transform: matrix(1, 0, 0, 1, -40, -140); } // Seat D40

    // Row E
    &[data-tn-seat-id="558387"] { transform: matrix(1, 0, 0, 1, 90, 170); } // Seat E48
    &[data-tn-seat-id="558388"] { transform: matrix(1, 0, 0, 1, 70, 120); }
    &[data-tn-seat-id="558389"] { transform: matrix(1, 0, 0, 1, 50, 70); }
    &[data-tn-seat-id="558390"] { transform: matrix(1, 0, 0, 1, 30, 20); }
    &[data-tn-seat-id="558391"] { transform: matrix(1, 0, 0, 1, 10, -30); }
    &[data-tn-seat-id="558392"] { transform: matrix(1, 0, 0, 1, -10, -80); }
    &[data-tn-seat-id="558393"] { transform: matrix(1, 0, 0, 1, -30, -130); }
    &[data-tn-seat-id="558394"] { transform: matrix(1, 0, 0, 1, -50, -180); }
    &[data-tn-seat-id="558395"] { transform: matrix(1, 0, 0, 1, -70, -230); } // Seat E40

    // Row F
    &[data-tn-seat-id="558307"] { transform: matrix(1, 0, 0, 1, 60, 80); } // Seat F47
    &[data-tn-seat-id="558308"] { transform: matrix(1, 0, 0, 1, 40, 30); }
    &[data-tn-seat-id="558309"] { transform: matrix(1, 0, 0, 1, 20, -20); }
    &[data-tn-seat-id="558310"] { transform: matrix(1, 0, 0, 1, 0, -70); }
    &[data-tn-seat-id="558311"] { transform: matrix(1, 0, 0, 1, -20, -120); }
    &[data-tn-seat-id="558312"] { transform: matrix(1, 0, 0, 1, -40, -180); }
    &[data-tn-seat-id="558313"] { transform: matrix(1, 0, 0, 1, -60, -230); }
    &[data-tn-seat-id="558314"] { transform: matrix(1, 0, 0, 1, -80, -280); } // Seat F40

    // Row G
    &[data-tn-seat-id="558226"] { transform: matrix(1, 0, 0, 1, 20, 50); } // Seat G46
    &[data-tn-seat-id="558227"] { transform: matrix(1, 0, 0, 1, 0, 0); }
    &[data-tn-seat-id="558228"] { transform: matrix(1, 0, 0, 1, -20, -50); }
    &[data-tn-seat-id="558229"] { transform: matrix(1, 0, 0, 1, -40, -100); }
    &[data-tn-seat-id="558230"] { transform: matrix(1, 0, 0, 1, -60, -150); }
    &[data-tn-seat-id="558231"] { transform: matrix(1, 0, 0, 1, -80, -200); }
    &[data-tn-seat-id="558232"] { transform: matrix(1, 0, 0, 1, -100, -250); }
    &[data-tn-seat-id="558233"] { transform: matrix(1, 0, 0, 1, -120, -310); }
    &[data-tn-seat-id="558234"] { transform: matrix(1, 0, 0, 1, -130, -370); } // Seat G38

    // Middle Section
    // Row A
    &[data-tn-seat-id="558726"] { transform: matrix(1, 0, 0, 1, -390, -20); } // Seat A34
    &[data-tn-seat-id="558727"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="558728"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="558729"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="558730"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="558731"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="558732"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="558733"] { transform: matrix(1, 0, 0, 1, -380, -20); }
    &[data-tn-seat-id="558734"] { transform: matrix(1, 0, 0, 1, -380, -20); }
    &[data-tn-seat-id="558735"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="558736"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="558737"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="558738"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="558739"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="558740"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="558741"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="558742"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="558743"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="558744"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="558745"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="558746"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="558747"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="558748"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="558749"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="558750"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="558751"] { transform: matrix(1, 0, 0, 1, -390, 0); }
    &[data-tn-seat-id="558752"] { transform: matrix(1, 0, 0, 1, -400, 50); }
    &[data-tn-seat-id="558753"] { transform: matrix(1, 0, 0, 1, -400, 100); }
    &[data-tn-seat-id="558754"] { transform: matrix(1, 0, 0, 1, -410, 160); } // Seat A6

    // Row B
    &[data-tn-seat-id="558645"] { transform: matrix(1, 0, 0, 1, -330, -90); } // Seat B36
    &[data-tn-seat-id="558646"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="558647"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="558648"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="558649"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="558650"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="558651"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="558652"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="558653"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="558654"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="558655"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="558656"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="558657"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="558658"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="558659"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="558660"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="558661"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="558662"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="558663"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="558664"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="558665"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="558666"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="558667"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="558668"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="558669"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="558670"] { transform: matrix(1, 0, 0, 1, -330, -85); } // Seat B11

    // Row C
    &[data-tn-seat-id="558564"] { transform: matrix(1, 0, 0, 1, -310, -140); } // Seat C38
    &[data-tn-seat-id="558565"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="558566"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="558567"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="558568"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="558569"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="558570"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="558571"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="558572"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="558573"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="558574"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="558575"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="558576"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="558577"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="558578"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="558579"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="558580"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="558581"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="558582"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="558583"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="558584"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="558585"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="558586"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="558587"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="558588"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="558589"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="558590"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="558591"] { transform: matrix(1, 0, 0, 1, -300, -135); } // Seat C11

    // Row D
    &[data-tn-seat-id="558483"] { transform: matrix(1, 0, 0, 1, -290, -200); } // Seat D39
    &[data-tn-seat-id="558484"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="558485"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="558486"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="558487"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="558488"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="558489"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="558490"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="558491"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="558492"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="558493"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="558494"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="558495"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="558496"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="558497"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="558498"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="558499"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="558500"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="558501"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="558502"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="558503"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="558504"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="558505"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="558506"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="558507"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="558508"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="558509"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="558510"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="558511"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="558512"] { transform: matrix(1, 0, 0, 1, -290, -195); } // Seat D10

    // Row E
    &[data-tn-seat-id="558403"] { transform: matrix(1, 0, 0, 1, -360, -260); } // Seat E39
    &[data-tn-seat-id="558404"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558405"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558406"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558407"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558408"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558409"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558410"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558411"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558412"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558413"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558414"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558415"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558416"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558417"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558418"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558419"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558420"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558421"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558422"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558423"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558424"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558425"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558426"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558427"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558428"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558429"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558430"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558431"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558432"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="558433"] { transform: matrix(1, 0, 0, 1, -350, -250); } // Seat E9

    // Row F
    &[data-tn-seat-id="558322"] { transform: matrix(1, 0, 0, 1, -340, -320); } // Seat F39
    &[data-tn-seat-id="558323"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558324"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558325"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558326"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558327"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558328"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558329"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558330"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558331"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558332"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558333"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558334"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558335"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558336"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558337"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558338"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558339"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558340"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558341"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558342"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558343"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558344"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558345"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558346"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558347"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558348"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558349"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558350"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558351"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558352"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="558353"] { transform: matrix(1, 0, 0, 1, -340, -320); } // Seat F8

    // Row G
    &[data-tn-seat-id="558241"] { transform: matrix(1, 0, 0, 1, -360, -390); } // Seat G37
    &[data-tn-seat-id="558242"] { transform: matrix(1, 0, 0, 1, -365, -390); }
    &[data-tn-seat-id="558243"] { transform: matrix(1, 0, 0, 1, -370, -390); }
    &[data-tn-seat-id="558244"] { transform: matrix(1, 0, 0, 1, -370, -390); }
    &[data-tn-seat-id="558245"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558246"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558247"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558248"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558249"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558250"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558251"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558252"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558253"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558254"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558255"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558256"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558257"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558258"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558259"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558260"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558261"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558262"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558263"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558264"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558265"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558266"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558267"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558268"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558269"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558270"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558271"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558272"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558273"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558274"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558275"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558276"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558277"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="558278"] { transform: matrix(1, 0, 0, 1, -360, -390); } // Seat G4

    // Right Section
    // Row AA
    &[data-tn-seat-id="559233"] { transform: matrix(1, 0, 0, 1, -650, 530); } // Seat AA4
    &[data-tn-seat-id="559234"] { transform: matrix(1, 0, 0, 1, -670, 580); }
    &[data-tn-seat-id="559235"] { transform: matrix(1, 0, 0, 1, -680, 640); }
    &[data-tn-seat-id="559236"] { transform: matrix(1, 0, 0, 1, -690, 700); } // Seat AA1

    // Row BB
    &[data-tn-seat-id="559153"] { transform: matrix(1, 0, 0, 1, -610, 450); } // Seat BB5
    &[data-tn-seat-id="559154"] { transform: matrix(1, 0, 0, 1, -630, 500); }
    &[data-tn-seat-id="559155"] { transform: matrix(1, 0, 0, 1, -640, 560); }
    &[data-tn-seat-id="559156"] { transform: matrix(1, 0, 0, 1, -650, 620); }
    &[data-tn-seat-id="559157"] { transform: matrix(1, 0, 0, 1, -670, 670); } // Seat BB1

    // Row CC
    &[data-tn-seat-id="559073"] { transform: matrix(1, 0, 0, 1, -560, 390); } // Seat CC6
    &[data-tn-seat-id="559074"] { transform: matrix(1, 0, 0, 1, -580, 440); }
    &[data-tn-seat-id="559075"] { transform: matrix(1, 0, 0, 1, -600, 490); }
    &[data-tn-seat-id="559076"] { transform: matrix(1, 0, 0, 1, -610, 550); }
    &[data-tn-seat-id="559077"] { transform: matrix(1, 0, 0, 1, -620, 610); }
    &[data-tn-seat-id="559078"] { transform: matrix(1, 0, 0, 1, -640, 660); } // Seat CC1

    // Row DD
    &[data-tn-seat-id="558993"] { transform: matrix(1, 0, 0, 1, -520, 330); } // Seat DD7
    &[data-tn-seat-id="558994"] { transform: matrix(1, 0, 0, 1, -540, 380); }
    &[data-tn-seat-id="558995"] { transform: matrix(1, 0, 0, 1, -560, 430); }
    &[data-tn-seat-id="558996"] { transform: matrix(1, 0, 0, 1, -580, 480); }
    &[data-tn-seat-id="558997"] { transform: matrix(1, 0, 0, 1, -600, 530); }
    &[data-tn-seat-id="558998"] { transform: matrix(1, 0, 0, 1, -620, 580); }
    &[data-tn-seat-id="558999"] { transform: matrix(1, 0, 0, 1, -630, 630); } // Seat DD1

    // Row EE
    &[data-tn-seat-id="558913"] { transform: matrix(1, 0, 0, 1, -460, 270); } // Seat EE7
    &[data-tn-seat-id="558914"] { transform: matrix(1, 0, 0, 1, -480, 320); }
    &[data-tn-seat-id="558915"] { transform: matrix(1, 0, 0, 1, -500, 370); }
    &[data-tn-seat-id="558916"] { transform: matrix(1, 0, 0, 1, -520, 420); }
    &[data-tn-seat-id="558917"] { transform: matrix(1, 0, 0, 1, -540, 470); }
    &[data-tn-seat-id="558918"] { transform: matrix(1, 0, 0, 1, -560, 520); }
    &[data-tn-seat-id="558919"] { transform: matrix(1, 0, 0, 1, -580, 570); } // Seat EE1

    // Row FF
    &[data-tn-seat-id="558833"] { transform: matrix(1, 0, 0, 1, -410, 210); } // Seat FF7
    &[data-tn-seat-id="558834"] { transform: matrix(1, 0, 0, 1, -430, 260); }
    &[data-tn-seat-id="558835"] { transform: matrix(1, 0, 0, 1, -450, 310); }
    &[data-tn-seat-id="558836"] { transform: matrix(1, 0, 0, 1, -470, 360); }
    &[data-tn-seat-id="558837"] { transform: matrix(1, 0, 0, 1, -490, 410); }
    &[data-tn-seat-id="558838"] { transform: matrix(1, 0, 0, 1, -510, 460); }
    &[data-tn-seat-id="558839"] { transform: matrix(1, 0, 0, 1, -530, 510); } // Seat FF1

    // Row GG
    &[data-tn-seat-id="558765"] { transform: matrix(1, 0, 0, 1, -810, 1040); } // Seat GG2
    &[data-tn-seat-id="558766"] { transform: matrix(1, 0, 0, 1, -820, 1120); } // Seat GG1

    // Row A
    &[data-tn-seat-id="558755"] { transform: matrix(1, 0, 0, 1, -420, 210); } // Seat A5
    &[data-tn-seat-id="558756"] { transform: matrix(1, 0, 0, 1, -430, 260); }
    &[data-tn-seat-id="558757"] { transform: matrix(1, 0, 0, 1, -450, 310); }
    &[data-tn-seat-id="558758"] { transform: matrix(1, 0, 0, 1, -470, 360); }
    &[data-tn-seat-id="558759"] { transform: matrix(1, 0, 0, 1, -490, 410); } // Seat A1

    // Row B
    &[data-tn-seat-id="558671"] { transform: matrix(1, 0, 0, 1, -320, -60); } // Seat B10
    &[data-tn-seat-id="558672"] { transform: matrix(1, 0, 0, 1, -330, -10); }
    &[data-tn-seat-id="558673"] { transform: matrix(1, 0, 0, 1, -350, 40); }
    &[data-tn-seat-id="558674"] { transform: matrix(1, 0, 0, 1, -370, 90); }
    &[data-tn-seat-id="558675"] { transform: matrix(1, 0, 0, 1, -380, 140); }
    &[data-tn-seat-id="558676"] { transform: matrix(1, 0, 0, 1, -400, 190); }
    &[data-tn-seat-id="558677"] { transform: matrix(1, 0, 0, 1, -420, 240); }
    &[data-tn-seat-id="558678"] { transform: matrix(1, 0, 0, 1, -440, 290); }
    &[data-tn-seat-id="558679"] { transform: matrix(1, 0, 0, 1, -460, 340); }
    &[data-tn-seat-id="558680"] { transform: matrix(1, 0, 0, 1, -480, 390); } // Seat B1

    // Row C
    &[data-tn-seat-id="558592"] { transform: matrix(1, 0, 0, 1, -300, -100); } // Seat C10
    &[data-tn-seat-id="558593"] { transform: matrix(1, 0, 0, 1, -320, -50); }
    &[data-tn-seat-id="558594"] { transform: matrix(1, 0, 0, 1, -340, 0); }
    &[data-tn-seat-id="558595"] { transform: matrix(1, 0, 0, 1, -360, 50); }
    &[data-tn-seat-id="558596"] { transform: matrix(1, 0, 0, 1, -380, 100); }
    &[data-tn-seat-id="558597"] { transform: matrix(1, 0, 0, 1, -400, 150); }
    &[data-tn-seat-id="558598"] { transform: matrix(1, 0, 0, 1, -420, 200); }
    &[data-tn-seat-id="558599"] { transform: matrix(1, 0, 0, 1, -440, 250); }
    &[data-tn-seat-id="558600"] { transform: matrix(1, 0, 0, 1, -460, 300); }
    &[data-tn-seat-id="558601"] { transform: matrix(1, 0, 0, 1, -480, 350); } // Seat C1

    // Row D
    &[data-tn-seat-id="558513"] { transform: matrix(1, 0, 0, 1, -300, -150); } // Seat D9
    &[data-tn-seat-id="558514"] { transform: matrix(1, 0, 0, 1, -320, -100); }
    &[data-tn-seat-id="558515"] { transform: matrix(1, 0, 0, 1, -340, -50); }
    &[data-tn-seat-id="558516"] { transform: matrix(1, 0, 0, 1, -360, 0); }
    &[data-tn-seat-id="558517"] { transform: matrix(1, 0, 0, 1, -380, 50); }
    &[data-tn-seat-id="558518"] { transform: matrix(1, 0, 0, 1, -400, 100); }
    &[data-tn-seat-id="558519"] { transform: matrix(1, 0, 0, 1, -420, 150); }
    &[data-tn-seat-id="558520"] { transform: matrix(1, 0, 0, 1, -440, 200); }
    &[data-tn-seat-id="558521"] { transform: matrix(1, 0, 0, 1, -460, 250); } // Seat D1
    
    // Row E
    &[data-tn-seat-id="558434"] { transform: matrix(1, 0, 0, 1, -350, -225); } // Seat E8
    &[data-tn-seat-id="558435"] { transform: matrix(1, 0, 0, 1, -370, -180); }
    &[data-tn-seat-id="558436"] { transform: matrix(1, 0, 0, 1, -390, -130); }
    &[data-tn-seat-id="558437"] { transform: matrix(1, 0, 0, 1, -410, -80); }
    &[data-tn-seat-id="558438"] { transform: matrix(1, 0, 0, 1, -430, -30); }
    &[data-tn-seat-id="558439"] { transform: matrix(1, 0, 0, 1, -450, 20); }
    &[data-tn-seat-id="558440"] { transform: matrix(1, 0, 0, 1, -470, 70); }
    &[data-tn-seat-id="558441"] { transform: matrix(1, 0, 0, 1, -490, 120); } // Seat E1

    // Row F
    &[data-tn-seat-id="558354"] { transform: matrix(1, 0, 0, 1, -330, -300); } // Seat F7
    &[data-tn-seat-id="558355"] { transform: matrix(1, 0, 0, 1, -340, -250); }
    &[data-tn-seat-id="558356"] { transform: matrix(1, 0, 0, 1, -360, -200); }
    &[data-tn-seat-id="558357"] { transform: matrix(1, 0, 0, 1, -380, -150); }
    &[data-tn-seat-id="558358"] { transform: matrix(1, 0, 0, 1, -400, -100); }
    &[data-tn-seat-id="558359"] { transform: matrix(1, 0, 0, 1, -420, -50); }
    &[data-tn-seat-id="558360"] { transform: matrix(1, 0, 0, 1, -440, 0); } // Seat F1

    // Row G
    &[data-tn-seat-id="558275"] { transform: matrix(1, 0, 0, 1, -360, -360); } // Seat G3
    &[data-tn-seat-id="558276"] { transform: matrix(1, 0, 0, 1, -380, -310); }
    &[data-tn-seat-id="558277"] { transform: matrix(1, 0, 0, 1, -400, -260); } // Seat G1
 }
 
}
