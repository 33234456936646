.tn-detail-page-header {
    padding: 15px;
    border-bottom: 1px solid rgba(0,0,0,0.3);
    .tn-detail-title {
        font-size: 250%;
        padding: 0;
    }
    .tn-detail-image {
        display: none;
    }
}
.tn-detail {
    order: 1;
    .tn-detail-selected-date {
        font-size: 160%;
        display: block;
        float: none;
    }
    .tn-detail-performance-title { // repeated, hide
        display: none;
    }
}
.tn-detail-date-list {
    order: 2;
}


.tn-syos--viewing-seats .tn-syos {
    z-index: 800 !important;
}

.tn-events-detail-page {
    .tn-ticketing-mode-change__content {
        font-style: normal;
        border: 0;
        background-color: $orange;
        color: $black;
    }

    .tn-syos-screen-button {
        background-color: $orange;
        border: 0;
        color: $black;
    }

    .tn-syos__btn-add-to-cart {
        background-color: $brand-primary;
        text-transform: none;
        color: $black;
        @include font-bold;
    }
}

.tn-event-detail {
    position: relative;
}

.tn-additional-events__continue-shopping-link {

}

.tn-additional-events .tn-additional-events__instructions {
    display: none;
}

.tn-event-detail .tn-event-detail__additional-events-container {
    padding: 15px;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    width: 100%;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    text-align: center;

    @include mq($from: tablet) {
        width: 25%;
        min-width: 430px;
        background-color: transparent;
        text-align: right;
        margin-right: 0;

        .tn-additional-events__controls-container {
            margin-bottom: 0;
            border: 0;
            padding-bottom: 0;
        }
    }

    .tn-additional-events__btn-submit {
        margin-left: 10px;
        color: $black;
    }
}

.tn-syos-active .tn-event-detail .tn-event-detail__additional-events-container {
    @include mq($from: tablet)  {
        position: absolute;
        top: 95px;
        right: 0;
        min-width: 430px;
        margin: 0;
        width: 75%;
    }
}

.tn-event-detail .tn-additional-events {
    width: 100%;
    margin: 0;

    @include mq($from: tablet) {
        display: inline-block;
        vertical-align: middle;
        width: 55%;
    }
}

.tn-additional-events__continue-shopping-link {
    &::before {
        content: 'or';
        border-bottom: 0;
        position: absolute;
        left: -20px;
        top: -3px;
    }

    border-bottom: 1px solid $orange;
    margin-left: 40px;
    position: relative;
    display: inline-block;
    white-space: nowrap;
}
