.tn-cart-buttons {
    margin-top: 40px;
}


.tn-cart-buttons__secondary-action {
    border-bottom: 2px solid $orange;
    @include font-bold;
}


.tn-account-login-page .tn-account-login-forgot-login-link {
    color: $black;
}

.tn-heading-info[name="tn-alert-message"] {
    background-color: $info;
    border: 2px $info--dark solid;
}
