///* ========================================================================
//   #FONT FILES
//   ======================================================================== */

// Add your font-face links to fonts here

@font-face {
  // font-family: 'Custom Font';
  // src: url('../fonts/custom.eot');
  // src: url('../fonts/custom.eot?#iefix') format('embedded-opentype'),
  //      url('../fonts/custom.woff2') format('woff2'),
  //      url('../fonts/custom.woff') format('woff');
  // font-weight:  400;
  // font-style:   italic;
  // font-stretch: normal;
}


$font-path: "https://s3-eu-west-1.amazonaws.com/trp-tnew-template/qa/static/fonts";

@font-face {
    font-family: 'Eesti TRP';
    src: url(#{$font-path}/gt-eesti-trp-regular-webfont.woff2) format('woff2'),
         url(#{$font-path}/gt-eesti-trp-regular-webfont.woff) format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Eesti TRP';
    src: url(#{$font-path}/gt-eesti-trp-italic-webfont.woff2) format('woff2'),
         url(#{$font-path}/gt-eesti-trp-italic-webfont.woff) format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Eesti TRP';
    src: url(#{$font-path}/gt-eesti-trp-bold-webfont.woff2) format('woff2'),
         url(#{$font-path}/gt-eesti-trp-bold-webfont.woff) format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Eesti TRP';
    src: url(#{$font-path}/gt-eesti-trp-bold-italic-webfont.woff2) format('woff2'),
         url(#{$font-path}/gt-eesti-trp-bold-italic-webfont.woff) format('woff');
    font-weight: 700;
    font-style: italic;
}

/* ==========================================================================
   #Fonts
   ========================================================================== */


// Font Face
@mixin fonts {
    font-family: 'Eesti TRP', helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased !important;
    text-rendering: optimizeLegibility !important;
}

@mixin font-thin {
    font-weight: 100;
}
@mixin font-light {
    font-weight: 300;
}
@mixin font-reg {
    font-weight: 400;
}
@mixin font-med {
    font-weight: 500;
}
@mixin font-semi-bold {
    font-weight: 600;
}
@mixin font-bold {
    font-weight: 700;
}
@mixin font-italic {
    font-style: italic;
}
