@supports not (-ms-high-contrast: none) {

    .tn-syos-seat-map__seat {

        // Left Balcony
        // Row L
        &[data-tn-seat-id="561018"] { transform: matrix(1, 0, 0, 1, 670, 500); } // Seat L10
        &[data-tn-seat-id="561019"] { transform: matrix(1, 0, 0, 1, 650, 450); }
        &[data-tn-seat-id="561020"] { transform: matrix(1, 0, 0, 1, 630, 400); }
        &[data-tn-seat-id="561021"] { transform: matrix(1, 0, 0, 1, 610, 350); }
        &[data-tn-seat-id="561022"] { transform: matrix(1, 0, 0, 1, 590, 300); } // Seat L6

        // Row M
        &[data-tn-seat-id="560938"] { transform: matrix(1, 0, 0, 1, 590, 480); } // Seat M6
        &[data-tn-seat-id="560939"] { transform: matrix(1, 0, 0, 1, 570, 430); }
        &[data-tn-seat-id="560940"] { transform: matrix(1, 0, 0, 1, 550, 380); } // Seat M4

        // Right Balcony
        // Row L
        &[data-tn-seat-id="561080"] { transform: matrix(1, 0, 0, 1, -710, 310); } // Seat L5
        &[data-tn-seat-id="561081"] { transform: matrix(1, 0, 0, 1, -730, 360); }
        &[data-tn-seat-id="561082"] { transform: matrix(1, 0, 0, 1, -750, 400); }
        &[data-tn-seat-id="561083"] { transform: matrix(1, 0, 0, 1, -770, 450); }
        &[data-tn-seat-id="561084"] { transform: matrix(1, 0, 0, 1, -790, 500); } // Seat L1

        // Row M
        &[data-tn-seat-id="561002"] { transform: matrix(1, 0, 0, 1, -670, 380); } // Seat M3
        &[data-tn-seat-id="561003"] { transform: matrix(1, 0, 0, 1, -690, 430); }
        &[data-tn-seat-id="561004"] { transform: matrix(1, 0, 0, 1, -710, 480); } // Seat M1 
    

        // Middle Section
        // Row A
        &[data-tn-seat-id="560946"] { transform: matrix(1, 0, 0, 1, 310, 50); } // Seat A52
        &[data-tn-seat-id="560947"] { transform: matrix(1, 0, 0, 1, 280, 0); }
        &[data-tn-seat-id="560948"] { transform: matrix(1, 0, 0, 1, 260, -50); }
        &[data-tn-seat-id="560949"] { transform: matrix(1, 0, 0, 1, 240, -100); }
        &[data-tn-seat-id="560950"] { transform: matrix(1, 0, 0, 1, 220, -150); }
        &[data-tn-seat-id="560951"] { transform: matrix(1, 0, 0, 1, 200, -200); }
        &[data-tn-seat-id="560952"] { transform: matrix(1, 0, 0, 1, 180, -250); }
        &[data-tn-seat-id="560953"] { transform: matrix(1, 0, 0, 1, 160, -300); }
        &[data-tn-seat-id="560954"] { transform: matrix(1, 0, 0, 1, 130, -350); }
        &[data-tn-seat-id="560955"] { transform: matrix(1, 0, 0, 1, 110, -400); }
        &[data-tn-seat-id="560956"] { transform: matrix(1, 0, 0, 1, 80, -450); }
        &[data-tn-seat-id="560957"] { transform: matrix(1, 0, 0, 1, 60, -500); }
        &[data-tn-seat-id="560958"] { transform: matrix(1, 0, 0, 1, 30, -550); }
        &[data-tn-seat-id="560959"] { transform: matrix(1, 0, 0, 1, 10, -590); }
        &[data-tn-seat-id="560960"] { transform: matrix(1, 0, 0, 1, -10, -650); }
        &[data-tn-seat-id="560961"] { transform: matrix(1, 0, 0, 1, -40, -700); }
        &[data-tn-seat-id="560962"] { transform: matrix(1, 0, 0, 1, -60, -740); } // Seat A36

        &[data-tn-seat-id="560963"] { transform: matrix(1, 0, 0, 1, -70, -770); } // Seat A37
        &[data-tn-seat-id="560964"] { transform: matrix(1, 0, 0, 1, -70, -770); }
        &[data-tn-seat-id="560965"] { transform: matrix(1, 0, 0, 1, -70, -770); }
        &[data-tn-seat-id="560966"] { transform: matrix(1, 0, 0, 1, -80, -770); }
        &[data-tn-seat-id="560967"] { transform: matrix(1, 0, 0, 1, -80, -770); }
        &[data-tn-seat-id="560968"] { transform: matrix(1, 0, 0, 1, -80, -770); }
        &[data-tn-seat-id="560969"] { transform: matrix(1, 0, 0, 1, -90, -770); }
        &[data-tn-seat-id="560970"] { transform: matrix(1, 0, 0, 1, -90, -770); }
        &[data-tn-seat-id="560971"] { transform: matrix(1, 0, 0, 1, -100, -770); }
        &[data-tn-seat-id="560972"] { transform: matrix(1, 0, 0, 1, -100, -770); }
        &[data-tn-seat-id="560973"] { transform: matrix(1, 0, 0, 1, -100, -770); }
        &[data-tn-seat-id="560974"] { transform: matrix(1, 0, 0, 1, -110, -770); }
        &[data-tn-seat-id="560975"] { transform: matrix(1, 0, 0, 1, -110, -770); }
        &[data-tn-seat-id="560976"] { transform: matrix(1, 0, 0, 1, -110, -770); }
        &[data-tn-seat-id="560977"] { transform: matrix(1, 0, 0, 1, -120, -770); }
        &[data-tn-seat-id="560978"] { transform: matrix(1, 0, 0, 1, -120, -770); }
        &[data-tn-seat-id="560979"] { transform: matrix(1, 0, 0, 1, -120, -770); }
        &[data-tn-seat-id="560980"] { transform: matrix(1, 0, 0, 1, -130, -760); } // Seat A18

        &[data-tn-seat-id="560981"] { transform: matrix(1, 0, 0, 1, -140, -730); } // Seat A17
        &[data-tn-seat-id="560982"] { transform: matrix(1, 0, 0, 1, -160, -690); }
        &[data-tn-seat-id="560983"] { transform: matrix(1, 0, 0, 1, -180, -640); }
        &[data-tn-seat-id="560984"] { transform: matrix(1, 0, 0, 1, -200, -590); }
        &[data-tn-seat-id="560985"] { transform: matrix(1, 0, 0, 1, -220, -540); }
        &[data-tn-seat-id="560986"] { transform: matrix(1, 0, 0, 1, -250, -490); }
        &[data-tn-seat-id="560987"] { transform: matrix(1, 0, 0, 1, -270, -440); }
        &[data-tn-seat-id="560988"] { transform: matrix(1, 0, 0, 1, -290, -390); }
        &[data-tn-seat-id="560989"] { transform: matrix(1, 0, 0, 1, -320, -340); }
        &[data-tn-seat-id="560990"] { transform: matrix(1, 0, 0, 1, -340, -290); }
        &[data-tn-seat-id="560991"] { transform: matrix(1, 0, 0, 1, -360, -240); }
        &[data-tn-seat-id="560992"] { transform: matrix(1, 0, 0, 1, -380, -190); }
        &[data-tn-seat-id="560993"] { transform: matrix(1, 0, 0, 1, -410, -140); }
        &[data-tn-seat-id="560994"] { transform: matrix(1, 0, 0, 1, -430, -90); }
        &[data-tn-seat-id="560995"] { transform: matrix(1, 0, 0, 1, -450, -40); }
        &[data-tn-seat-id="560996"] { transform: matrix(1, 0, 0, 1, -480, 0); }
        &[data-tn-seat-id="560997"] { transform: matrix(1, 0, 0, 1, -500, 50); } // Seat A1

        // Row B
        &[data-tn-seat-id="560866"] { transform: matrix(1, 0, 0, 1, 250, 10); } // Seat B51
        &[data-tn-seat-id="560867"] { transform: matrix(1, 0, 0, 1, 230, -40); }
        &[data-tn-seat-id="560868"] { transform: matrix(1, 0, 0, 1, 210, -90); }
        &[data-tn-seat-id="560869"] { transform: matrix(1, 0, 0, 1, 190, -140); }
        &[data-tn-seat-id="560870"] { transform: matrix(1, 0, 0, 1, 170, -190); }
        &[data-tn-seat-id="560871"] { transform: matrix(1, 0, 0, 1, 150, -240); }
        &[data-tn-seat-id="560872"] { transform: matrix(1, 0, 0, 1, 120, -300); }
        &[data-tn-seat-id="560873"] { transform: matrix(1, 0, 0, 1, 100, -350); }
        &[data-tn-seat-id="560874"] { transform: matrix(1, 0, 0, 1, 80, -400); }
        &[data-tn-seat-id="560875"] { transform: matrix(1, 0, 0, 1, 60, -450); }
        &[data-tn-seat-id="560876"] { transform: matrix(1, 0, 0, 1, 40, -500); }
        &[data-tn-seat-id="560877"] { transform: matrix(1, 0, 0, 1, 20, -550); }
        &[data-tn-seat-id="560878"] { transform: matrix(1, 0, 0, 1, 0, -600); }
        &[data-tn-seat-id="560879"] { transform: matrix(1, 0, 0, 1, -20, -650); }
        &[data-tn-seat-id="560880"] { transform: matrix(1, 0, 0, 1, -40, -700); }
        &[data-tn-seat-id="560881"] { transform: matrix(1, 0, 0, 1, -60, -750); }
        &[data-tn-seat-id="560882"] { transform: matrix(1, 0, 0, 1, -70, -790); } // Seat B35

        &[data-tn-seat-id="560883"] { transform: matrix(1, 0, 0, 1, -70, -810); } // Seat B36
        &[data-tn-seat-id="560884"] { transform: matrix(1, 0, 0, 1, -70, -810); }
        &[data-tn-seat-id="560885"] { transform: matrix(1, 0, 0, 1, -70, -810); }
        &[data-tn-seat-id="560886"] { transform: matrix(1, 0, 0, 1, -70, -810); }
        &[data-tn-seat-id="560887"] { transform: matrix(1, 0, 0, 1, -70, -810); }
        &[data-tn-seat-id="560888"] { transform: matrix(1, 0, 0, 1, -70, -810); }
        &[data-tn-seat-id="560889"] { transform: matrix(1, 0, 0, 1, -70, -810); }
        &[data-tn-seat-id="560890"] { transform: matrix(1, 0, 0, 1, -70, -810); }
        &[data-tn-seat-id="560891"] { transform: matrix(1, 0, 0, 1, -70, -810); }
        &[data-tn-seat-id="560892"] { transform: matrix(1, 0, 0, 1, -70, -810); }
        &[data-tn-seat-id="560893"] { transform: matrix(1, 0, 0, 1, -70, -810); }
        &[data-tn-seat-id="560894"] { transform: matrix(1, 0, 0, 1, -70, -810); }
        &[data-tn-seat-id="560895"] { transform: matrix(1, 0, 0, 1, -70, -810); }
        &[data-tn-seat-id="560896"] { transform: matrix(1, 0, 0, 1, -70, -810); }
        &[data-tn-seat-id="560897"] { transform: matrix(1, 0, 0, 1, -70, -810); }
        &[data-tn-seat-id="560898"] { transform: matrix(1, 0, 0, 1, -70, -810); }
        &[data-tn-seat-id="560899"] { transform: matrix(1, 0, 0, 1, -70, -810); }
        &[data-tn-seat-id="560900"] { transform: matrix(1, 0, 0, 1, -60, -800); } // Seat B17

        &[data-tn-seat-id="560901"] { transform: matrix(1, 0, 0, 1, -70, -760); } // Seat B16
        &[data-tn-seat-id="560902"] { transform: matrix(1, 0, 0, 1, -90, -710); }
        &[data-tn-seat-id="560903"] { transform: matrix(1, 0, 0, 1, -110, -660); }
        &[data-tn-seat-id="560904"] { transform: matrix(1, 0, 0, 1, -130, -610); }
        &[data-tn-seat-id="560905"] { transform: matrix(1, 0, 0, 1, -150, -560); }
        &[data-tn-seat-id="560906"] { transform: matrix(1, 0, 0, 1, -170, -510); }
        &[data-tn-seat-id="560907"] { transform: matrix(1, 0, 0, 1, -190, -460); }
        &[data-tn-seat-id="560908"] { transform: matrix(1, 0, 0, 1, -210, -410); }
        &[data-tn-seat-id="560909"] { transform: matrix(1, 0, 0, 1, -230, -360); }
        &[data-tn-seat-id="560910"] { transform: matrix(1, 0, 0, 1, -250, -310); }
        &[data-tn-seat-id="560911"] { transform: matrix(1, 0, 0, 1, -270, -260); }
        &[data-tn-seat-id="560912"] { transform: matrix(1, 0, 0, 1, -290, -210); }
        &[data-tn-seat-id="560913"] { transform: matrix(1, 0, 0, 1, -310, -160); }
        &[data-tn-seat-id="560914"] { transform: matrix(1, 0, 0, 1, -330, -110); }
        &[data-tn-seat-id="560915"] { transform: matrix(1, 0, 0, 1, -350, -60); }
        &[data-tn-seat-id="560916"] { transform: matrix(1, 0, 0, 1, -370, -10); } // Seat B1
    
        // Row C
        &[data-tn-seat-id="560786"] { transform: matrix(1, 0, 0, 1, 240, -90); } // Seat C51
        &[data-tn-seat-id="560787"] { transform: matrix(1, 0, 0, 1, 220, -140); }
        &[data-tn-seat-id="560788"] { transform: matrix(1, 0, 0, 1, 200, -190); }
        &[data-tn-seat-id="560789"] { transform: matrix(1, 0, 0, 1, 180, -240); }
        &[data-tn-seat-id="560790"] { transform: matrix(1, 0, 0, 1, 160, -290); }
        &[data-tn-seat-id="560791"] { transform: matrix(1, 0, 0, 1, 140, -340); }
        &[data-tn-seat-id="560792"] { transform: matrix(1, 0, 0, 1, 120, -390); }
        &[data-tn-seat-id="560793"] { transform: matrix(1, 0, 0, 1, 100, -450); }
        &[data-tn-seat-id="560794"] { transform: matrix(1, 0, 0, 1, 80, -500); }
        &[data-tn-seat-id="560795"] { transform: matrix(1, 0, 0, 1, 60, -560); }
        &[data-tn-seat-id="560796"] { transform: matrix(1, 0, 0, 1, 40, -610); }
        &[data-tn-seat-id="560797"] { transform: matrix(1, 0, 0, 1, 20, -660); }
        &[data-tn-seat-id="560798"] { transform: matrix(1, 0, 0, 1, 0, -710); }
        &[data-tn-seat-id="560799"] { transform: matrix(1, 0, 0, 1, -20, -760); }
        &[data-tn-seat-id="560800"] { transform: matrix(1, 0, 0, 1, -40, -810); }
        &[data-tn-seat-id="560801"] { transform: matrix(1, 0, 0, 1, -60, -850); } // Seat C36

        &[data-tn-seat-id="560802"] { transform: matrix(1, 0, 0, 1, -70, -870); } // Seat D35
        &[data-tn-seat-id="560803"] { transform: matrix(1, 0, 0, 1, -70, -870); }
        &[data-tn-seat-id="560804"] { transform: matrix(1, 0, 0, 1, -70, -870); }
        &[data-tn-seat-id="560805"] { transform: matrix(1, 0, 0, 1, -70, -870); }
        &[data-tn-seat-id="560806"] { transform: matrix(1, 0, 0, 1, -70, -870); }
        &[data-tn-seat-id="560807"] { transform: matrix(1, 0, 0, 1, -70, -870); }
        &[data-tn-seat-id="560808"] { transform: matrix(1, 0, 0, 1, -70, -870); }
        &[data-tn-seat-id="560809"] { transform: matrix(1, 0, 0, 1, -70, -870); }
        &[data-tn-seat-id="560810"] { transform: matrix(1, 0, 0, 1, -70, -870); }
        &[data-tn-seat-id="560811"] { transform: matrix(1, 0, 0, 1, -70, -870); }
        &[data-tn-seat-id="560812"] { transform: matrix(1, 0, 0, 1, -70, -870); }
        &[data-tn-seat-id="560813"] { transform: matrix(1, 0, 0, 1, -70, -870); }
        &[data-tn-seat-id="560814"] { transform: matrix(1, 0, 0, 1, -70, -870); }
        &[data-tn-seat-id="560815"] { transform: matrix(1, 0, 0, 1, -70, -870); }
        &[data-tn-seat-id="560816"] { transform: matrix(1, 0, 0, 1, -70, -870); }
        &[data-tn-seat-id="560817"] { transform: matrix(1, 0, 0, 1, -70, -870); }
        &[data-tn-seat-id="560818"] { transform: matrix(1, 0, 0, 1, -70, -870); }
        &[data-tn-seat-id="560819"] { transform: matrix(1, 0, 0, 1, -70, -870); }
        &[data-tn-seat-id="560820"] { transform: matrix(1, 0, 0, 1, -70, -870); }
        &[data-tn-seat-id="560821"] { transform: matrix(1, 0, 0, 1, -70, -850); } // Seat C16

        &[data-tn-seat-id="560822"] { transform: matrix(1, 0, 0, 1, -80, -810); } // Seat C15
        &[data-tn-seat-id="560823"] { transform: matrix(1, 0, 0, 1, -100, -760); }
        &[data-tn-seat-id="560824"] { transform: matrix(1, 0, 0, 1, -120, -710); }
        &[data-tn-seat-id="560825"] { transform: matrix(1, 0, 0, 1, -140, -660); }
        &[data-tn-seat-id="560826"] { transform: matrix(1, 0, 0, 1, -160, -610); }
        &[data-tn-seat-id="560827"] { transform: matrix(1, 0, 0, 1, -180, -560); }
        &[data-tn-seat-id="560828"] { transform: matrix(1, 0, 0, 1, -200, -510); }
        &[data-tn-seat-id="560829"] { transform: matrix(1, 0, 0, 1, -220, -460); }
        &[data-tn-seat-id="560830"] { transform: matrix(1, 0, 0, 1, -240, -410); }
        &[data-tn-seat-id="560831"] { transform: matrix(1, 0, 0, 1, -260, -360); }
        &[data-tn-seat-id="560832"] { transform: matrix(1, 0, 0, 1, -280, -310); }
        &[data-tn-seat-id="560833"] { transform: matrix(1, 0, 0, 1, -300, -260); }
        &[data-tn-seat-id="560834"] { transform: matrix(1, 0, 0, 1, -320, -210); }
        &[data-tn-seat-id="560835"] { transform: matrix(1, 0, 0, 1, -340, -160); }
        &[data-tn-seat-id="560836"] { transform: matrix(1, 0, 0, 1, -360, -110); } // Seat C1
    
        // Row D
        &[data-tn-seat-id="560706"] { transform: matrix(1, 0, 0, 1, 230, -180); } // Seat D51
        &[data-tn-seat-id="560707"] { transform: matrix(1, 0, 0, 1, 210, -230); }
        &[data-tn-seat-id="560708"] { transform: matrix(1, 0, 0, 1, 190, -280); }
        &[data-tn-seat-id="560709"] { transform: matrix(1, 0, 0, 1, 170, -330); }
        &[data-tn-seat-id="560710"] { transform: matrix(1, 0, 0, 1, 150, -380); }
        &[data-tn-seat-id="560711"] { transform: matrix(1, 0, 0, 1, 130, -440); }
        &[data-tn-seat-id="560712"] { transform: matrix(1, 0, 0, 1, 110, -500); }
        &[data-tn-seat-id="560713"] { transform: matrix(1, 0, 0, 1, 90, -550); }
        &[data-tn-seat-id="560714"] { transform: matrix(1, 0, 0, 1, 70, -600); }
        &[data-tn-seat-id="560715"] { transform: matrix(1, 0, 0, 1, 50, -650); }
        &[data-tn-seat-id="560716"] { transform: matrix(1, 0, 0, 1, 30, -700); }
        &[data-tn-seat-id="560717"] { transform: matrix(1, 0, 0, 1, 10, -750); }
        &[data-tn-seat-id="560718"] { transform: matrix(1, 0, 0, 1, -10, -800); }
        &[data-tn-seat-id="560719"] { transform: matrix(1, 0, 0, 1, -30, -850); }
        &[data-tn-seat-id="560720"] { transform: matrix(1, 0, 0, 1, -50, -890); } // Seat D37
    
        &[data-tn-seat-id="560721"] { transform: matrix(1, 0, 0, 1, -60, -920); } // Seat D36
        &[data-tn-seat-id="560722"] { transform: matrix(1, 0, 0, 1, -60, -920); }
        &[data-tn-seat-id="560723"] { transform: matrix(1, 0, 0, 1, -60, -920); }
        &[data-tn-seat-id="560724"] { transform: matrix(1, 0, 0, 1, -60, -920); }
        &[data-tn-seat-id="560725"] { transform: matrix(1, 0, 0, 1, -60, -920); }
        &[data-tn-seat-id="560726"] { transform: matrix(1, 0, 0, 1, -60, -920); }
        &[data-tn-seat-id="560727"] { transform: matrix(1, 0, 0, 1, -60, -920); }
        &[data-tn-seat-id="560728"] { transform: matrix(1, 0, 0, 1, -60, -920); }
        &[data-tn-seat-id="560729"] { transform: matrix(1, 0, 0, 1, -60, -920); }
        &[data-tn-seat-id="560730"] { transform: matrix(1, 0, 0, 1, -60, -920); }
        &[data-tn-seat-id="560731"] { transform: matrix(1, 0, 0, 1, -60, -920); }
        &[data-tn-seat-id="560732"] { transform: matrix(1, 0, 0, 1, -60, -920); }
        &[data-tn-seat-id="560733"] { transform: matrix(1, 0, 0, 1, -60, -920); }
        &[data-tn-seat-id="560734"] { transform: matrix(1, 0, 0, 1, -60, -920); }
        &[data-tn-seat-id="560735"] { transform: matrix(1, 0, 0, 1, -60, -920); }
        &[data-tn-seat-id="560736"] { transform: matrix(1, 0, 0, 1, -60, -920); }
        &[data-tn-seat-id="560737"] { transform: matrix(1, 0, 0, 1, -60, -920); }
        &[data-tn-seat-id="560738"] { transform: matrix(1, 0, 0, 1, -60, -920); }
        &[data-tn-seat-id="560739"] { transform: matrix(1, 0, 0, 1, -60, -920); }
        &[data-tn-seat-id="560740"] { transform: matrix(1, 0, 0, 1, -60, -920); }
        &[data-tn-seat-id="560741"] { transform: matrix(1, 0, 0, 1, -60, -910); } // Seat D16
    
        &[data-tn-seat-id="560742"] { transform: matrix(1, 0, 0, 1, -70, -880); } // Seat D15
        &[data-tn-seat-id="560743"] { transform: matrix(1, 0, 0, 1, -70, -840); }
        &[data-tn-seat-id="560744"] { transform: matrix(1, 0, 0, 1, -80, -790); }
        &[data-tn-seat-id="560745"] { transform: matrix(1, 0, 0, 1, -100, -740); }
        &[data-tn-seat-id="560746"] { transform: matrix(1, 0, 0, 1, -120, -690); }
        &[data-tn-seat-id="560747"] { transform: matrix(1, 0, 0, 1, -140, -640); }
        &[data-tn-seat-id="560748"] { transform: matrix(1, 0, 0, 1, -160, -590); }
        &[data-tn-seat-id="560749"] { transform: matrix(1, 0, 0, 1, -180, -540); }
        &[data-tn-seat-id="560750"] { transform: matrix(1, 0, 0, 1, -200, -490); }
        &[data-tn-seat-id="560751"] { transform: matrix(1, 0, 0, 1, -220, -440); }
        &[data-tn-seat-id="560752"] { transform: matrix(1, 0, 0, 1, -240, -390); }
        &[data-tn-seat-id="560753"] { transform: matrix(1, 0, 0, 1, -260, -340); }
        &[data-tn-seat-id="560754"] { transform: matrix(1, 0, 0, 1, -280, -290); }
        &[data-tn-seat-id="560755"] { transform: matrix(1, 0, 0, 1, -300, -240); }
        &[data-tn-seat-id="560756"] { transform: matrix(1, 0, 0, 1, -320, -190); } // Seat D1
    
        // Row E
        &[data-tn-seat-id="560626"] { transform: matrix(1, 0, 0, 1, 220, -290); } // Seat E50 
        &[data-tn-seat-id="560627"] { transform: matrix(1, 0, 0, 1, 200, -340); }
        &[data-tn-seat-id="560628"] { transform: matrix(1, 0, 0, 1, 180, -390); }
        &[data-tn-seat-id="560629"] { transform: matrix(1, 0, 0, 1, 160, -440); }
        &[data-tn-seat-id="560630"] { transform: matrix(1, 0, 0, 1, 140, -490); }
        &[data-tn-seat-id="560631"] { transform: matrix(1, 0, 0, 1, 120, -550); }
        &[data-tn-seat-id="560632"] { transform: matrix(1, 0, 0, 1, 100, -600); }
        &[data-tn-seat-id="560633"] { transform: matrix(1, 0, 0, 1, 80, -660); }
        &[data-tn-seat-id="560634"] { transform: matrix(1, 0, 0, 1, 60, -710); }
        &[data-tn-seat-id="560635"] { transform: matrix(1, 0, 0, 1, 40, -760); }
        &[data-tn-seat-id="560636"] { transform: matrix(1, 0, 0, 1, 20, -810); }
        &[data-tn-seat-id="560637"] { transform: matrix(1, 0, 0, 1, 0, -860); }
        &[data-tn-seat-id="560638"] { transform: matrix(1, 0, 0, 1, -20, -910); }
        &[data-tn-seat-id="560639"] { transform: matrix(1, 0, 0, 1, -30, -960); } // Seat E37
    
        &[data-tn-seat-id="560640"] { transform: matrix(1, 0, 0, 1, -30, -980); } // Seat E36
        &[data-tn-seat-id="560641"] { transform: matrix(1, 0, 0, 1, -30, -980); }
        &[data-tn-seat-id="560642"] { transform: matrix(1, 0, 0, 1, -30, -980); }
        &[data-tn-seat-id="560643"] { transform: matrix(1, 0, 0, 1, -30, -980); }
        &[data-tn-seat-id="560644"] { transform: matrix(1, 0, 0, 1, -30, -980); }
        &[data-tn-seat-id="560645"] { transform: matrix(1, 0, 0, 1, -30, -980); }
        &[data-tn-seat-id="560646"] { transform: matrix(1, 0, 0, 1, -30, -980); }
        &[data-tn-seat-id="560647"] { transform: matrix(1, 0, 0, 1, -30, -980); }
        &[data-tn-seat-id="560648"] { transform: matrix(1, 0, 0, 1, -30, -980); }
        &[data-tn-seat-id="560649"] { transform: matrix(1, 0, 0, 1, -30, -980); }
        &[data-tn-seat-id="560650"] { transform: matrix(1, 0, 0, 1, -30, -980); }
        &[data-tn-seat-id="560651"] { transform: matrix(1, 0, 0, 1, -30, -980); }
        &[data-tn-seat-id="560652"] { transform: matrix(1, 0, 0, 1, -30, -980); }
        &[data-tn-seat-id="560653"] { transform: matrix(1, 0, 0, 1, -30, -980); }
        &[data-tn-seat-id="560654"] { transform: matrix(1, 0, 0, 1, -30, -980); }
        &[data-tn-seat-id="560655"] { transform: matrix(1, 0, 0, 1, -30, -980); }
        &[data-tn-seat-id="560656"] { transform: matrix(1, 0, 0, 1, -30, -980); }
        &[data-tn-seat-id="560657"] { transform: matrix(1, 0, 0, 1, -30, -980); }
        &[data-tn-seat-id="560658"] { transform: matrix(1, 0, 0, 1, -30, -980); }
        &[data-tn-seat-id="560659"] { transform: matrix(1, 0, 0, 1, -30, -980); }
        &[data-tn-seat-id="560660"] { transform: matrix(1, 0, 0, 1, -30, -980); }
        &[data-tn-seat-id="560661"] { transform: matrix(1, 0, 0, 1, -30, -970); }
        &[data-tn-seat-id="560662"] { transform: matrix(1, 0, 0, 1, -40, -960); } // Seat E14

        &[data-tn-seat-id="560663"] { transform: matrix(1, 0, 0, 1, -50, -930); } // Seat E13
        &[data-tn-seat-id="560664"] { transform: matrix(1, 0, 0, 1, -70, -880); }
        &[data-tn-seat-id="560665"] { transform: matrix(1, 0, 0, 1, -90, -830); }
        &[data-tn-seat-id="560666"] { transform: matrix(1, 0, 0, 1, -110, -780); }
        &[data-tn-seat-id="560667"] { transform: matrix(1, 0, 0, 1, -130, -730); }
        &[data-tn-seat-id="560668"] { transform: matrix(1, 0, 0, 1, -150, -680); }
        &[data-tn-seat-id="560669"] { transform: matrix(1, 0, 0, 1, -170, -630); }
        &[data-tn-seat-id="560670"] { transform: matrix(1, 0, 0, 1, -190, -580); }
        &[data-tn-seat-id="560671"] { transform: matrix(1, 0, 0, 1, -210, -530); }
        &[data-tn-seat-id="560672"] { transform: matrix(1, 0, 0, 1, -230, -480); }
        &[data-tn-seat-id="560673"] { transform: matrix(1, 0, 0, 1, -250, -430); }
        &[data-tn-seat-id="560674"] { transform: matrix(1, 0, 0, 1, -270, -380); }
        &[data-tn-seat-id="560675"] { transform: matrix(1, 0, 0, 1, -290, -320); } // Seat E1
    
        // Row F
        &[data-tn-seat-id="560546"] { transform: matrix(1, 0, 0, 1, 250, -430); } // Seat F49
        &[data-tn-seat-id="560547"] { transform: matrix(1, 0, 0, 1, 230, -480); }
        &[data-tn-seat-id="560548"] { transform: matrix(1, 0, 0, 1, 210, -530); }
        &[data-tn-seat-id="560549"] { transform: matrix(1, 0, 0, 1, 190, -580); }
        &[data-tn-seat-id="560550"] { transform: matrix(1, 0, 0, 1, 170, -630); }
        &[data-tn-seat-id="560551"] { transform: matrix(1, 0, 0, 1, 150, -680); }
        &[data-tn-seat-id="560552"] { transform: matrix(1, 0, 0, 1, 130, -740); }
        &[data-tn-seat-id="560553"] { transform: matrix(1, 0, 0, 1, 110, -790); }
        &[data-tn-seat-id="560554"] { transform: matrix(1, 0, 0, 1, 90, -850); }
        &[data-tn-seat-id="560555"] { transform: matrix(1, 0, 0, 1, 70, -900); }
        &[data-tn-seat-id="560556"] { transform: matrix(1, 0, 0, 1, 50, -950); }
        &[data-tn-seat-id="560557"] { transform: matrix(1, 0, 0, 1, 30, -1000); }
        &[data-tn-seat-id="560558"] { transform: matrix(1, 0, 0, 1, 20, -1030); } // Seat F37
    
        &[data-tn-seat-id="560559"] { transform: matrix(1, 0, 0, 1, 10, -1040); } // Seat F36
        &[data-tn-seat-id="560560"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
        &[data-tn-seat-id="560561"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
        &[data-tn-seat-id="560562"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
        &[data-tn-seat-id="560563"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
        &[data-tn-seat-id="560564"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
        &[data-tn-seat-id="560565"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
        &[data-tn-seat-id="560566"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
        &[data-tn-seat-id="560567"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
        &[data-tn-seat-id="560568"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
        &[data-tn-seat-id="560569"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
        &[data-tn-seat-id="560570"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
        &[data-tn-seat-id="560571"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
        &[data-tn-seat-id="560572"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
        &[data-tn-seat-id="560573"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
        &[data-tn-seat-id="560574"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
        &[data-tn-seat-id="560575"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
        &[data-tn-seat-id="560576"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
        &[data-tn-seat-id="560577"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
        &[data-tn-seat-id="560578"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
        &[data-tn-seat-id="560579"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
        &[data-tn-seat-id="560580"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
        &[data-tn-seat-id="560581"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
        &[data-tn-seat-id="560582"] { transform: matrix(1, 0, 0, 1, 10, -1020); } // Seat F13

        &[data-tn-seat-id="560583"] { transform: matrix(1, 0, 0, 1, 10, -990); } // Seat F12
        &[data-tn-seat-id="560584"] { transform: matrix(1, 0, 0, 1, -10, -940); }
        &[data-tn-seat-id="560585"] { transform: matrix(1, 0, 0, 1, -30, -890); }
        &[data-tn-seat-id="560586"] { transform: matrix(1, 0, 0, 1, -50, -840); }
        &[data-tn-seat-id="560587"] { transform: matrix(1, 0, 0, 1, -70, -790); }
        &[data-tn-seat-id="560588"] { transform: matrix(1, 0, 0, 1, -90, -740); }
        &[data-tn-seat-id="560589"] { transform: matrix(1, 0, 0, 1, -110, -690); }
        &[data-tn-seat-id="560590"] { transform: matrix(1, 0, 0, 1, -130, -640); }
        &[data-tn-seat-id="560591"] { transform: matrix(1, 0, 0, 1, -150, -590); }
        &[data-tn-seat-id="560592"] { transform: matrix(1, 0, 0, 1, -170, -540); }
        &[data-tn-seat-id="560593"] { transform: matrix(1, 0, 0, 1, -190, -490); }
        &[data-tn-seat-id="560594"] { transform: matrix(1, 0, 0, 1, -210, -440); } // Seat F1
    
        // Row G
        &[data-tn-seat-id="560466"] { transform: matrix(1, 0, 0, 1, 240, -530); } // Seat G49
        &[data-tn-seat-id="560467"] { transform: matrix(1, 0, 0, 1, 220, -580); }
        &[data-tn-seat-id="560468"] { transform: matrix(1, 0, 0, 1, 200, -630); }
        &[data-tn-seat-id="560469"] { transform: matrix(1, 0, 0, 1, 180, -680); }
        &[data-tn-seat-id="560470"] { transform: matrix(1, 0, 0, 1, 160, -730); }
        &[data-tn-seat-id="560471"] { transform: matrix(1, 0, 0, 1, 140, -780); }
        &[data-tn-seat-id="560472"] { transform: matrix(1, 0, 0, 1, 120, -830); }
        &[data-tn-seat-id="560473"] { transform: matrix(1, 0, 0, 1, 100, -880); }
        &[data-tn-seat-id="560474"] { transform: matrix(1, 0, 0, 1, 80, -930); }
        &[data-tn-seat-id="560475"] { transform: matrix(1, 0, 0, 1, 60, -980); }
        &[data-tn-seat-id="560476"] { transform: matrix(1, 0, 0, 1, 40, -1030); }
        &[data-tn-seat-id="560477"] { transform: matrix(1, 0, 0, 1, 20, -1070); } // Seat G38
    
        &[data-tn-seat-id="560478"] { transform: matrix(1, 0, 0, 1, 10, -1090); } // Seat G37
        &[data-tn-seat-id="560479"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
        &[data-tn-seat-id="560480"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
        &[data-tn-seat-id="560481"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
        &[data-tn-seat-id="560482"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
        &[data-tn-seat-id="560483"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
        &[data-tn-seat-id="560484"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
        &[data-tn-seat-id="560485"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
        &[data-tn-seat-id="560486"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
        &[data-tn-seat-id="560487"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
        &[data-tn-seat-id="560488"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
        &[data-tn-seat-id="560489"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
        &[data-tn-seat-id="560490"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
        &[data-tn-seat-id="560491"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
        &[data-tn-seat-id="560492"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
        &[data-tn-seat-id="560493"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
        &[data-tn-seat-id="560494"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
        &[data-tn-seat-id="560495"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
        &[data-tn-seat-id="560496"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
        &[data-tn-seat-id="560497"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
        &[data-tn-seat-id="560498"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
        &[data-tn-seat-id="560499"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
        &[data-tn-seat-id="560500"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
        &[data-tn-seat-id="560501"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
        &[data-tn-seat-id="560502"] { transform: matrix(1, 0, 0, 1, 10, -1090); } // Seat G13
    
        &[data-tn-seat-id="560503"] { transform: matrix(1, 0, 0, 1, 10, -1070); } // Seat G12
        &[data-tn-seat-id="560504"] { transform: matrix(1, 0, 0, 1, -10, -1020); }
        &[data-tn-seat-id="560505"] { transform: matrix(1, 0, 0, 1, -30, -970); }
        &[data-tn-seat-id="560506"] { transform: matrix(1, 0, 0, 1, -50, -930); }
        &[data-tn-seat-id="560507"] { transform: matrix(1, 0, 0, 1, -70, -870); }
        &[data-tn-seat-id="560508"] { transform: matrix(1, 0, 0, 1, -90, -820); }
        &[data-tn-seat-id="560509"] { transform: matrix(1, 0, 0, 1, -110, -770); }
        &[data-tn-seat-id="560510"] { transform: matrix(1, 0, 0, 1, -130, -720); }
        &[data-tn-seat-id="560511"] { transform: matrix(1, 0, 0, 1, -150, -670); }
        &[data-tn-seat-id="560512"] { transform: matrix(1, 0, 0, 1, -170, -620); }
        &[data-tn-seat-id="560513"] { transform: matrix(1, 0, 0, 1, -190, -570); }
        &[data-tn-seat-id="560514"] { transform: matrix(1, 0, 0, 1, -210, -520); } // Seat G1
    
        // Row H
        &[data-tn-seat-id="560386"] { transform: matrix(1, 0, 0, 1, 240, -640); } // Seat H45
        &[data-tn-seat-id="560387"] { transform: matrix(1, 0, 0, 1, 220, -690); }
        &[data-tn-seat-id="560388"] { transform: matrix(1, 0, 0, 1, 200, -740); }
        &[data-tn-seat-id="560389"] { transform: matrix(1, 0, 0, 1, 180, -790); }
        &[data-tn-seat-id="560390"] { transform: matrix(1, 0, 0, 1, 160, -840); }
        &[data-tn-seat-id="560391"] { transform: matrix(1, 0, 0, 1, 140, -890); }
        &[data-tn-seat-id="560392"] { transform: matrix(1, 0, 0, 1, 120, -940); }
        &[data-tn-seat-id="560393"] { transform: matrix(1, 0, 0, 1, 100, -990); }
        &[data-tn-seat-id="560394"] { transform: matrix(1, 0, 0, 1, 80, -1040); }
        &[data-tn-seat-id="560395"] { transform: matrix(1, 0, 0, 1, 60, -1090); }
        &[data-tn-seat-id="560396"] { transform: matrix(1, 0, 0, 1, 60, -1130); } // Seat H38
    
        &[data-tn-seat-id="560397"] { transform: matrix(1, 0, 0, 1, 60, -1140); } // Seat H37
        &[data-tn-seat-id="560398"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
        &[data-tn-seat-id="560399"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
        &[data-tn-seat-id="560400"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
        &[data-tn-seat-id="560401"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
        &[data-tn-seat-id="560402"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
        &[data-tn-seat-id="560403"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
        &[data-tn-seat-id="560404"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
        &[data-tn-seat-id="560405"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
        &[data-tn-seat-id="560406"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
        &[data-tn-seat-id="560407"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
        &[data-tn-seat-id="560408"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
        &[data-tn-seat-id="560409"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
        &[data-tn-seat-id="560410"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
        &[data-tn-seat-id="560411"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
        &[data-tn-seat-id="560412"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
        &[data-tn-seat-id="560413"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
        &[data-tn-seat-id="560414"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
        &[data-tn-seat-id="560415"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
        &[data-tn-seat-id="560416"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
        &[data-tn-seat-id="560417"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
        &[data-tn-seat-id="560418"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
        &[data-tn-seat-id="560419"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
        &[data-tn-seat-id="560420"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
        &[data-tn-seat-id="560421"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
        &[data-tn-seat-id="560422"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
        &[data-tn-seat-id="560423"] { transform: matrix(1, 0, 0, 1, 60, -1120); } // Seat H11
    
        &[data-tn-seat-id="560424"] { transform: matrix(1, 0, 0, 1, 40, -1070); } // Seat H10
        &[data-tn-seat-id="560425"] { transform: matrix(1, 0, 0, 1, 20, -1020); }
        &[data-tn-seat-id="560426"] { transform: matrix(1, 0, 0, 1, 0, -970); }
        &[data-tn-seat-id="560427"] { transform: matrix(1, 0, 0, 1, -20, -920); }
        &[data-tn-seat-id="560428"] { transform: matrix(1, 0, 0, 1, -40, -870); }
        &[data-tn-seat-id="560429"] { transform: matrix(1, 0, 0, 1, -60, -820); }
        &[data-tn-seat-id="560430"] { transform: matrix(1, 0, 0, 1, -80, -770); }
        &[data-tn-seat-id="560431"] { transform: matrix(1, 0, 0, 1, -100, -720); }
        &[data-tn-seat-id="560432"] { transform: matrix(1, 0, 0, 1, -120, -670); } 
        &[data-tn-seat-id="560433"] { transform: matrix(1, 0, 0, 1, -140, -620); } // Seat H1
    
        // Row J
        &[data-tn-seat-id="560308"] { transform: matrix(1, 0, 0, 1, 180, -840); } // Seat J44
        &[data-tn-seat-id="560309"] { transform: matrix(1, 0, 0, 1, 160, -890); }
        &[data-tn-seat-id="560310"] { transform: matrix(1, 0, 0, 1, 140, -940); }
        &[data-tn-seat-id="560311"] { transform: matrix(1, 0, 0, 1, 120, -990); }
        &[data-tn-seat-id="560312"] { transform: matrix(1, 0, 0, 1, 100, -1040); }
        &[data-tn-seat-id="560313"] { transform: matrix(1, 0, 0, 1, 80, -1090); }
        &[data-tn-seat-id="560314"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
        &[data-tn-seat-id="560315"] { transform: matrix(1, 0, 0, 1, 60, -1180); }
        &[data-tn-seat-id="560316"] { transform: matrix(1, 0, 0, 1, 60, -1190); } // Seat J36
    
        &[data-tn-seat-id="560317"] { transform: matrix(1, 0, 0, 1, 60, -1190); } // Seat J35
        &[data-tn-seat-id="560318"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
        &[data-tn-seat-id="560319"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
        &[data-tn-seat-id="560320"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
        &[data-tn-seat-id="560321"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
        &[data-tn-seat-id="560322"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
        &[data-tn-seat-id="560323"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
        &[data-tn-seat-id="560324"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
        &[data-tn-seat-id="560325"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
        &[data-tn-seat-id="560326"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
        &[data-tn-seat-id="560327"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
        &[data-tn-seat-id="560328"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
        &[data-tn-seat-id="560329"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
        &[data-tn-seat-id="560330"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
        &[data-tn-seat-id="560331"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
        &[data-tn-seat-id="560332"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
        &[data-tn-seat-id="560333"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
        &[data-tn-seat-id="560334"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
        &[data-tn-seat-id="560335"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
        &[data-tn-seat-id="560336"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
        &[data-tn-seat-id="560337"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
        &[data-tn-seat-id="560338"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
        &[data-tn-seat-id="560339"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
        &[data-tn-seat-id="560340"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
        &[data-tn-seat-id="560341"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
        &[data-tn-seat-id="560342"] { transform: matrix(1, 0, 0, 1, 60, -1190); } // Seat J10
    
        &[data-tn-seat-id="560343"] { transform: matrix(1, 0, 0, 1, 60, -1180); } // Seat J9
        &[data-tn-seat-id="560344"] { transform: matrix(1, 0, 0, 1, 50, -1160); }
        &[data-tn-seat-id="560345"] { transform: matrix(1, 0, 0, 1, 30, -1120); }
        &[data-tn-seat-id="560346"] { transform: matrix(1, 0, 0, 1, 10, -1070); }
        &[data-tn-seat-id="560347"] { transform: matrix(1, 0, 0, 1, -10, -1020); }
        &[data-tn-seat-id="560348"] { transform: matrix(1, 0, 0, 1, -30, -970); }
        &[data-tn-seat-id="560349"] { transform: matrix(1, 0, 0, 1, -50, -920); }
        &[data-tn-seat-id="560350"] { transform: matrix(1, 0, 0, 1, -70, -870); }
        &[data-tn-seat-id="560351"] { transform: matrix(1, 0, 0, 1, -90, -820); } // Seat J1
    
        // Row K
        &[data-tn-seat-id="560228"] { transform: matrix(1, 0, 0, 1, 200, -960); } // Seat K43
        &[data-tn-seat-id="560229"] { transform: matrix(1, 0, 0, 1, 180, -1010); }
        &[data-tn-seat-id="560230"] { transform: matrix(1, 0, 0, 1, 160, -1060); }
        &[data-tn-seat-id="560231"] { transform: matrix(1, 0, 0, 1, 140, -1110); }
        &[data-tn-seat-id="560232"] { transform: matrix(1, 0, 0, 1, 120, -1160); }
        &[data-tn-seat-id="560233"] { transform: matrix(1, 0, 0, 1, 100, -1210); } // Seat K38
    
        &[data-tn-seat-id="560234"] { transform: matrix(1, 0, 0, 1, 90, -1240); } // Seat K37
        &[data-tn-seat-id="560235"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560236"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560237"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560238"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560239"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560240"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560241"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560242"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560243"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560244"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560245"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560246"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560247"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560248"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560249"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560250"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560251"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560252"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560253"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560254"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560255"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560256"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560257"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560258"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560259"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560260"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560261"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560262"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560263"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
        &[data-tn-seat-id="560264"] { transform: matrix(1, 0, 0, 1, 90, -1240); } // Seat K7
    
        &[data-tn-seat-id="560265"] { transform: matrix(1, 0, 0, 1, 70, -1200); } // Seat K6
        &[data-tn-seat-id="560266"] { transform: matrix(1, 0, 0, 1, 50, -1150); }
        &[data-tn-seat-id="560267"] { transform: matrix(1, 0, 0, 1, 30, -1100); }
        &[data-tn-seat-id="560268"] { transform: matrix(1, 0, 0, 1, 10, -1050); }
        &[data-tn-seat-id="560269"] { transform: matrix(1, 0, 0, 1, -10, -1000); }
        &[data-tn-seat-id="560270"] { transform: matrix(1, 0, 0, 1, -30, -950); } // Seat K1
    
        // Row X
        &[data-tn-seat-id="560079"] { transform: matrix(1, 0, 0, 1, -150, -1220); } // Seat X11
        &[data-tn-seat-id="560080"] { transform: matrix(1, 0, 0, 1, -160, -1220); }
        &[data-tn-seat-id="560081"] { transform: matrix(1, 0, 0, 1, -170, -1220); }
        &[data-tn-seat-id="560082"] { transform: matrix(1, 0, 0, 1, -180, -1220); }
        &[data-tn-seat-id="560083"] { transform: matrix(1, 0, 0, 1, -190, -1220); }
        &[data-tn-seat-id="560084"] { transform: matrix(1, 0, 0, 1, -200, -1220); } // Seat X6
    
        &[data-tn-seat-id="560097"] { transform: matrix(1, 0, 0, 1, 210, -1220); } // Seat X5
        &[data-tn-seat-id="560098"] { transform: matrix(1, 0, 0, 1, 200, -1220); }
        &[data-tn-seat-id="560099"] { transform: matrix(1, 0, 0, 1, 190, -1220); }
        &[data-tn-seat-id="560100"] { transform: matrix(1, 0, 0, 1, 180, -1220); }
        &[data-tn-seat-id="560101"] { transform: matrix(1, 0, 0, 1, 170, -1220); } // Seat X1

        // Standing Left
        &[data-tn-seat-id="560069"] { transform: matrix(1, 0, 0, 1, -60, -810); } // Seat S14
        &[data-tn-seat-id="560070"] { transform: matrix(1, 0, 0, 1, -80, -860); }
        &[data-tn-seat-id="560071"] { transform: matrix(1, 0, 0, 1, -100, -910); }
        &[data-tn-seat-id="560072"] { transform: matrix(1, 0, 0, 1, -120, -960); }
        &[data-tn-seat-id="560073"] { transform: matrix(1, 0, 0, 1, -140, -1010); }
        &[data-tn-seat-id="560074"] { transform: matrix(1, 0, 0, 1, -160, -1060); }
        &[data-tn-seat-id="560075"] { transform: matrix(1, 0, 0, 1, -180, -1100); } // Seat S8

        //Standing Right
        &[data-tn-seat-id="560105"] { transform: matrix(1, 0, 0, 1, 190, -1120); } // Seat S7
        &[data-tn-seat-id="560106"] { transform: matrix(1, 0, 0, 1, 170, -1070); }
        &[data-tn-seat-id="560107"] { transform: matrix(1, 0, 0, 1, 150, -1020); }
        &[data-tn-seat-id="560108"] { transform: matrix(1, 0, 0, 1, 130, -960); }
        &[data-tn-seat-id="560109"] { transform: matrix(1, 0, 0, 1, 110, -910); }
        &[data-tn-seat-id="560110"] { transform: matrix(1, 0, 0, 1, 90, -860); }
        &[data-tn-seat-id="560111"] { transform: matrix(1, 0, 0, 1, 60, -810); } // Seat S1
        
    }
}