@supports not (-ms-high-contrast: none) {

.tn-syos-seat-map__seat {
    //Balcony
    &[data-tn-seat-id="234097"] { transform: matrix(1, 0, 0, 1, 670, 500); }
    &[data-tn-seat-id="234098"] { transform: matrix(1, 0, 0, 1, 650, 450); }
    &[data-tn-seat-id="234099"] { transform: matrix(1, 0, 0, 1, 630, 400); }
    &[data-tn-seat-id="234100"] { transform: matrix(1, 0, 0, 1, 610, 350); }
    &[data-tn-seat-id="234101"] { transform: matrix(1, 0, 0, 1, 590, 300); }
    &[data-tn-seat-id="234017"] { transform: matrix(1, 0, 0, 1, 590, 480); }
    &[data-tn-seat-id="234018"] { transform: matrix(1, 0, 0, 1, 570, 430); }
    &[data-tn-seat-id="234019"] { transform: matrix(1, 0, 0, 1, 550, 380); }
    &[data-tn-seat-id="234159"] { transform: matrix(1, 0, 0, 1, -710, 310); }
    &[data-tn-seat-id="234160"] { transform: matrix(1, 0, 0, 1, -730, 360); }
    &[data-tn-seat-id="234161"] { transform: matrix(1, 0, 0, 1, -750, 400); }
    &[data-tn-seat-id="234162"] { transform: matrix(1, 0, 0, 1, -770, 450); }
    &[data-tn-seat-id="234163"] { transform: matrix(1, 0, 0, 1, -790, 500); }
    &[data-tn-seat-id="234081"] { transform: matrix(1, 0, 0, 1, -670, 380); }
    &[data-tn-seat-id="234082"] { transform: matrix(1, 0, 0, 1, -690, 430); }
    &[data-tn-seat-id="234083"] { transform: matrix(1, 0, 0, 1, -710, 480); }

    //row 1
    &[data-tn-seat-id="234027"] { transform: matrix(1, 0, 0, 1, 260, -50); }
    &[data-tn-seat-id="234028"] { transform: matrix(1, 0, 0, 1, 240, -100); }
    &[data-tn-seat-id="234029"] { transform: matrix(1, 0, 0, 1, 220, -150); }
    &[data-tn-seat-id="234030"] { transform: matrix(1, 0, 0, 1, 200, -200); }
    &[data-tn-seat-id="234031"] { transform: matrix(1, 0, 0, 1, 180, -250); }
    &[data-tn-seat-id="234025"] { transform: matrix(1, 0, 0, 1, 310, 50); }
    &[data-tn-seat-id="234026"] { transform: matrix(1, 0, 0, 1, 280, 0); }
    &[data-tn-seat-id="234032"] { transform: matrix(1, 0, 0, 1, 160, -300); }
    &[data-tn-seat-id="234033"] { transform: matrix(1, 0, 0, 1, 130, -350); }
    &[data-tn-seat-id="234034"] { transform: matrix(1, 0, 0, 1, 110, -400); }
    &[data-tn-seat-id="234035"] { transform: matrix(1, 0, 0, 1, 80, -450); }
    &[data-tn-seat-id="234036"] { transform: matrix(1, 0, 0, 1, 60, -500); }
    &[data-tn-seat-id="234037"] { transform: matrix(1, 0, 0, 1, 30, -550); }
    &[data-tn-seat-id="234038"] { transform: matrix(1, 0, 0, 1, 10, -590); }
    &[data-tn-seat-id="234039"] { transform: matrix(1, 0, 0, 1, -10, -650); }
    &[data-tn-seat-id="234040"] { transform: matrix(1, 0, 0, 1, -40, -700); }
    &[data-tn-seat-id="234041"] { transform: matrix(1, 0, 0, 1, -60, -740); }
    &[data-tn-seat-id="234042"] { transform: matrix(1, 0, 0, 1, -70, -770); }
    &[data-tn-seat-id="234043"] { transform: matrix(1, 0, 0, 1, -70, -770); }
    &[data-tn-seat-id="234044"] { transform: matrix(1, 0, 0, 1, -70, -770); }
    &[data-tn-seat-id="234045"] { transform: matrix(1, 0, 0, 1, -80, -770); }
    &[data-tn-seat-id="234046"] { transform: matrix(1, 0, 0, 1, -80, -770); }
    &[data-tn-seat-id="234047"] { transform: matrix(1, 0, 0, 1, -80, -770); }
    &[data-tn-seat-id="234048"] { transform: matrix(1, 0, 0, 1, -90, -770); }
    &[data-tn-seat-id="234049"] { transform: matrix(1, 0, 0, 1, -90, -770); }
    &[data-tn-seat-id="234050"] { transform: matrix(1, 0, 0, 1, -100, -770); }
    &[data-tn-seat-id="234051"] { transform: matrix(1, 0, 0, 1, -100, -770); }
    &[data-tn-seat-id="234052"] { transform: matrix(1, 0, 0, 1, -100, -770); }
    &[data-tn-seat-id="234053"] { transform: matrix(1, 0, 0, 1, -110, -770); }
    &[data-tn-seat-id="234054"] { transform: matrix(1, 0, 0, 1, -110, -770); }
    &[data-tn-seat-id="234055"] { transform: matrix(1, 0, 0, 1, -110, -770); }
    &[data-tn-seat-id="234056"] { transform: matrix(1, 0, 0, 1, -120, -770); }
    &[data-tn-seat-id="234057"] { transform: matrix(1, 0, 0, 1, -120, -770); }
    &[data-tn-seat-id="234058"] { transform: matrix(1, 0, 0, 1, -120, -770); }
    &[data-tn-seat-id="234059"] { transform: matrix(1, 0, 0, 1, -130, -760); }
    &[data-tn-seat-id="234060"] { transform: matrix(1, 0, 0, 1, -140, -730); }
    &[data-tn-seat-id="234061"] { transform: matrix(1, 0, 0, 1, -160, -690); }
    &[data-tn-seat-id="234062"] { transform: matrix(1, 0, 0, 1, -180, -640); }
    &[data-tn-seat-id="234063"] { transform: matrix(1, 0, 0, 1, -200, -590); }
    &[data-tn-seat-id="234064"] { transform: matrix(1, 0, 0, 1, -220, -540); }
    &[data-tn-seat-id="234065"] { transform: matrix(1, 0, 0, 1, -250, -490); }
    &[data-tn-seat-id="234066"] { transform: matrix(1, 0, 0, 1, -270, -440); }
    &[data-tn-seat-id="234067"] { transform: matrix(1, 0, 0, 1, -290, -390); }
    &[data-tn-seat-id="234068"] { transform: matrix(1, 0, 0, 1, -320, -340); }
    &[data-tn-seat-id="234069"] { transform: matrix(1, 0, 0, 1, -340, -290); }
    &[data-tn-seat-id="234070"] { transform: matrix(1, 0, 0, 1, -360, -240); }
    &[data-tn-seat-id="234071"] { transform: matrix(1, 0, 0, 1, -380, -190); }
    &[data-tn-seat-id="234072"] { transform: matrix(1, 0, 0, 1, -410, -140); }
    &[data-tn-seat-id="234071"] { transform: matrix(1, 0, 0, 1, -380, -190); }
    &[data-tn-seat-id="234072"] { transform: matrix(1, 0, 0, 1, -400, -140); }
    &[data-tn-seat-id="234073"] { transform: matrix(1, 0, 0, 1, -430, -90); }
    &[data-tn-seat-id="234074"] { transform: matrix(1, 0, 0, 1, -450, -40); }
    &[data-tn-seat-id="234075"] { transform: matrix(1, 0, 0, 1, -480, 0); }
    &[data-tn-seat-id="234076"] { transform: matrix(1, 0, 0, 1, -500, 50); }

    //row 2
    &[data-tn-seat-id="233947"] { transform: matrix(1, 0, 0, 1, 200, -90); }
    &[data-tn-seat-id="233948"] { transform: matrix(1, 0, 0, 1, 180, -140); }
    &[data-tn-seat-id="233949"] { transform: matrix(1, 0, 0, 1, 160, -190); }
    &[data-tn-seat-id="233950"] { transform: matrix(1, 0, 0, 1, 140, -240); }
    &[data-tn-seat-id="233946"] { transform: matrix(1, 0, 0, 1, 230, -40); }
    &[data-tn-seat-id="233947"] { transform: matrix(1, 0, 0, 1, 200, -80); }
    &[data-tn-seat-id="233948"] { transform: matrix(1, 0, 0, 1, 180, -140); }
    &[data-tn-seat-id="233948"] { transform: matrix(1, 0, 0, 1, 180, -140); }
    &[data-tn-seat-id="233949"] { transform: matrix(1, 0, 0, 1, 160, -200); }
    &[data-tn-seat-id="233950"] { transform: matrix(1, 0, 0, 1, 140, -250); }
    &[data-tn-seat-id="233946"] { transform: matrix(1, 0, 0, 1, 220, -20); }
    &[data-tn-seat-id="233945"] { transform: matrix(1, 0, 0, 1, 250, 10); }
    &[data-tn-seat-id="233946"] { transform: matrix(1, 0, 0, 1, 230, -40); }
    &[data-tn-seat-id="233947"] { transform: matrix(1, 0, 0, 1, 210, -90); }
    &[data-tn-seat-id="233948"] { transform: matrix(1, 0, 0, 1, 190, -140); }
    &[data-tn-seat-id="233949"] { transform: matrix(1, 0, 0, 1, 170, -190); }
    &[data-tn-seat-id="233950"] { transform: matrix(1, 0, 0, 1, 150, -240); }
    &[data-tn-seat-id="233951"] { transform: matrix(1, 0, 0, 1, 130, -290); }
    &[data-tn-seat-id="233952"] { transform: matrix(1, 0, 0, 1, 100, -350); }
    &[data-tn-seat-id="233951"] { transform: matrix(1, 0, 0, 1, 120, -300); }
    &[data-tn-seat-id="233953"] { transform: matrix(1, 0, 0, 1, 80, -400); }
    &[data-tn-seat-id="233954"] { transform: matrix(1, 0, 0, 1, 60, -450); }
    &[data-tn-seat-id="233955"] { transform: matrix(1, 0, 0, 1, 40, -500); }
    &[data-tn-seat-id="233956"] { transform: matrix(1, 0, 0, 1, 20, -550); }
    &[data-tn-seat-id="233957"] { transform: matrix(1, 0, 0, 1, 0, -600); }
    &[data-tn-seat-id="233958"] { transform: matrix(1, 0, 0, 1, -20, -660); }
    &[data-tn-seat-id="233959"] { transform: matrix(1, 0, 0, 1, -50, -700); }
    &[data-tn-seat-id="233958"] { transform: matrix(1, 0, 0, 1, -20, -650); }
    &[data-tn-seat-id="233959"] { transform: matrix(1, 0, 0, 1, -40, -700); }
    &[data-tn-seat-id="233960"] { transform: matrix(1, 0, 0, 1, -60, -750); }
    &[data-tn-seat-id="233961"] { transform: matrix(1, 0, 0, 1, -80, -790); }
    &[data-tn-seat-id="233962"] { transform: matrix(1, 0, 0, 1, -90, -810); }
    &[data-tn-seat-id="233963"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="233962"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="233964"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="233965"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="233966"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="233967"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="233968"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="233969"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="233970"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="233971"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="233972"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="233973"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="233974"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="233975"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="233976"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="233977"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="233978"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="233979"] { transform: matrix(1, 0, 0, 1, -60, -800); }
    &[data-tn-seat-id="233980"] { transform: matrix(1, 0, 0, 1, -70, -760); }
    &[data-tn-seat-id="233981"] { transform: matrix(1, 0, 0, 1, -90, -710); }
    &[data-tn-seat-id="233982"] { transform: matrix(1, 0, 0, 1, -110, -660); }
    &[data-tn-seat-id="233983"] { transform: matrix(1, 0, 0, 1, -130, -610); }
    &[data-tn-seat-id="233984"] { transform: matrix(1, 0, 0, 1, -150, -560); }
    &[data-tn-seat-id="233985"] { transform: matrix(1, 0, 0, 1, -170, -510); }
    &[data-tn-seat-id="233986"] { transform: matrix(1, 0, 0, 1, -190, -460); }
    &[data-tn-seat-id="233987"] { transform: matrix(1, 0, 0, 1, -210, -410); }
    &[data-tn-seat-id="233988"] { transform: matrix(1, 0, 0, 1, -230, -360); }
    &[data-tn-seat-id="233989"] { transform: matrix(1, 0, 0, 1, -250, -310); }
    &[data-tn-seat-id="233990"] { transform: matrix(1, 0, 0, 1, -270, -260); }
    &[data-tn-seat-id="233991"] { transform: matrix(1, 0, 0, 1, -290, -210); }
    &[data-tn-seat-id="233992"] { transform: matrix(1, 0, 0, 1, -310, -160); }
    &[data-tn-seat-id="233993"] { transform: matrix(1, 0, 0, 1, -330, -110); }
    &[data-tn-seat-id="233994"] { transform: matrix(1, 0, 0, 1, -350, -60); }
    &[data-tn-seat-id="233995"] { transform: matrix(1, 0, 0, 1, -370, -10); }

    //row 3
    &[data-tn-seat-id="233865"] { transform: matrix(1, 0, 0, 1, 240, -90); }
    &[data-tn-seat-id="233866"] { transform: matrix(1, 0, 0, 1, 220, -140); }
    &[data-tn-seat-id="233867"] { transform: matrix(1, 0, 0, 1, 200, -190); }
    &[data-tn-seat-id="233868"] { transform: matrix(1, 0, 0, 1, 180, -240); }
    &[data-tn-seat-id="233869"] { transform: matrix(1, 0, 0, 1, 160, -290); }
    &[data-tn-seat-id="233870"] { transform: matrix(1, 0, 0, 1, 140, -340); }
    &[data-tn-seat-id="233871"] { transform: matrix(1, 0, 0, 1, 120, -390); }
    &[data-tn-seat-id="233872"] { transform: matrix(1, 0, 0, 1, 100, -450); }
    &[data-tn-seat-id="233873"] { transform: matrix(1, 0, 0, 1, 80, -510); }
    &[data-tn-seat-id="233874"] { transform: matrix(1, 0, 0, 1, 60, -570); }
    &[data-tn-seat-id="233875"] { transform: matrix(1, 0, 0, 1, 50, -620); }
    &[data-tn-seat-id="233873"] { transform: matrix(1, 0, 0, 1, 80, -500); }
    &[data-tn-seat-id="233874"] { transform: matrix(1, 0, 0, 1, 60, -560); }
    &[data-tn-seat-id="233875"] { transform: matrix(1, 0, 0, 1, 40, -610); }
    &[data-tn-seat-id="233876"] { transform: matrix(1, 0, 0, 1, 20, -660); }
    &[data-tn-seat-id="233877"] { transform: matrix(1, 0, 0, 1, 0, -710); }
    &[data-tn-seat-id="233878"] { transform: matrix(1, 0, 0, 1, -20, -760); }
    &[data-tn-seat-id="233879"] { transform: matrix(1, 0, 0, 1, -40, -810); }
    &[data-tn-seat-id="233880"] { transform: matrix(1, 0, 0, 1, -60, -850); }
    &[data-tn-seat-id="233881"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="233882"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="233883"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="233884"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="233885"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="233886"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="233887"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="233888"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="233889"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="233890"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="233891"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="233892"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="233893"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="233894"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="233895"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="233896"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="233897"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="233898"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="233899"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="233900"] { transform: matrix(1, 0, 0, 1, -70, -850); }
    &[data-tn-seat-id="233901"] { transform: matrix(1, 0, 0, 1, -80, -810); }
    &[data-tn-seat-id="233902"] { transform: matrix(1, 0, 0, 1, -100, -760); }
    &[data-tn-seat-id="233903"] { transform: matrix(1, 0, 0, 1, -120, -710); }
    &[data-tn-seat-id="233904"] { transform: matrix(1, 0, 0, 1, -140, -660); }
    &[data-tn-seat-id="233905"] { transform: matrix(1, 0, 0, 1, -160, -610); }
    &[data-tn-seat-id="233906"] { transform: matrix(1, 0, 0, 1, -180, -560); }
    &[data-tn-seat-id="233907"] { transform: matrix(1, 0, 0, 1, -200, -510); }
    &[data-tn-seat-id="233908"] { transform: matrix(1, 0, 0, 1, -220, -460); }
    &[data-tn-seat-id="233909"] { transform: matrix(1, 0, 0, 1, -240, -410); }
    &[data-tn-seat-id="233910"] { transform: matrix(1, 0, 0, 1, -260, -360); }
    &[data-tn-seat-id="233911"] { transform: matrix(1, 0, 0, 1, -280, -310); }
    &[data-tn-seat-id="233912"] { transform: matrix(1, 0, 0, 1, -300, -260); }
    &[data-tn-seat-id="233913"] { transform: matrix(1, 0, 0, 1, -320, -210); }
    &[data-tn-seat-id="233914"] { transform: matrix(1, 0, 0, 1, -340, -160); }
    &[data-tn-seat-id="233915"] { transform: matrix(1, 0, 0, 1, -360, -110); }

    //row 4
    &[data-tn-seat-id="233785"] { transform: matrix(1, 0, 0, 1, 230, -180); }
    &[data-tn-seat-id="233786"] { transform: matrix(1, 0, 0, 1, 210, -230); }
    &[data-tn-seat-id="233787"] { transform: matrix(1, 0, 0, 1, 190, -280); }
    &[data-tn-seat-id="233788"] { transform: matrix(1, 0, 0, 1, 170, -330); }
    &[data-tn-seat-id="233789"] { transform: matrix(1, 0, 0, 1, 150, -380); }
    &[data-tn-seat-id="233790"] { transform: matrix(1, 0, 0, 1, 130, -440); }
    &[data-tn-seat-id="233791"] { transform: matrix(1, 0, 0, 1, 110, -500); }
    &[data-tn-seat-id="233792"] { transform: matrix(1, 0, 0, 1, 90, -550); }
    &[data-tn-seat-id="233793"] { transform: matrix(1, 0, 0, 1, 70, -600); }
    &[data-tn-seat-id="233794"] { transform: matrix(1, 0, 0, 1, 50, -650); }
    &[data-tn-seat-id="233795"] { transform: matrix(1, 0, 0, 1, 30, -700); }
    &[data-tn-seat-id="233796"] { transform: matrix(1, 0, 0, 1, 10, -750); }
    &[data-tn-seat-id="233797"] { transform: matrix(1, 0, 0, 1, -10, -800); }
    &[data-tn-seat-id="233798"] { transform: matrix(1, 0, 0, 1, -30, -850); }
    &[data-tn-seat-id="233799"] { transform: matrix(1, 0, 0, 1, -50, -890); }

    &[data-tn-seat-id="233800"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="233801"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="233802"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="233803"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="233804"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="233805"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="233806"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="233807"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="233808"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="233809"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="233810"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="233811"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="233812"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="233813"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="233814"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="233815"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="233816"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="233817"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="233818"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="233819"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="233820"] { transform: matrix(1, 0, 0, 1, -60, -910); }


    &[data-tn-seat-id="233820"] { transform: matrix(1, 0, 0, 1, -70, -910); }
    &[data-tn-seat-id="233821"] { transform: matrix(1, 0, 0, 1, -70, -880); }
    &[data-tn-seat-id="233822"] { transform: matrix(1, 0, 0, 1, -80, -840); }
    &[data-tn-seat-id="233823"] { transform: matrix(1, 0, 0, 1, -100, -790); }
    &[data-tn-seat-id="233824"] { transform: matrix(1, 0, 0, 1, -120, -740); }
    &[data-tn-seat-id="233825"] { transform: matrix(1, 0, 0, 1, -140, -690); }
    &[data-tn-seat-id="233826"] { transform: matrix(1, 0, 0, 1, -160, -640); }
    &[data-tn-seat-id="233827"] { transform: matrix(1, 0, 0, 1, -180, -590); }
    &[data-tn-seat-id="233828"] { transform: matrix(1, 0, 0, 1, -200, -540); }
    &[data-tn-seat-id="233829"] { transform: matrix(1, 0, 0, 1, -220, -490); }
    &[data-tn-seat-id="233830"] { transform: matrix(1, 0, 0, 1, -240, -440); }
    &[data-tn-seat-id="233831"] { transform: matrix(1, 0, 0, 1, -260, -390); }
    &[data-tn-seat-id="233832"] { transform: matrix(1, 0, 0, 1, -280, -340); }
    &[data-tn-seat-id="233833"] { transform: matrix(1, 0, 0, 1, -300, -290); }
    &[data-tn-seat-id="233834"] { transform: matrix(1, 0, 0, 1, -320, -240); }
    &[data-tn-seat-id="233835"] { transform: matrix(1, 0, 0, 1, -340, -190); }

    //row 5
    &[data-tn-seat-id="233705"] { transform: matrix(1, 0, 0, 1, 220, -290); }
    &[data-tn-seat-id="233706"] { transform: matrix(1, 0, 0, 1, 200, -340); }
    &[data-tn-seat-id="233707"] { transform: matrix(1, 0, 0, 1, 180, -390); }
    &[data-tn-seat-id="233708"] { transform: matrix(1, 0, 0, 1, 160, -440); }
    &[data-tn-seat-id="233709"] { transform: matrix(1, 0, 0, 1, 140, -490); }
    &[data-tn-seat-id="233710"] { transform: matrix(1, 0, 0, 1, 120, -550); }
    &[data-tn-seat-id="233711"] { transform: matrix(1, 0, 0, 1, 100, -600); }
    &[data-tn-seat-id="233712"] { transform: matrix(1, 0, 0, 1, 80, -660); }
    &[data-tn-seat-id="233713"] { transform: matrix(1, 0, 0, 1, 60, -710); }
    &[data-tn-seat-id="233714"] { transform: matrix(1, 0, 0, 1, 40, -760); }
    &[data-tn-seat-id="233715"] { transform: matrix(1, 0, 0, 1, 20, -810); }
    &[data-tn-seat-id="233716"] { transform: matrix(1, 0, 0, 1, 0, -860); }
    &[data-tn-seat-id="233717"] { transform: matrix(1, 0, 0, 1, -20, -910); }
    &[data-tn-seat-id="233718"] { transform: matrix(1, 0, 0, 1, -30, -960); }

    &[data-tn-seat-id="233719"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="233720"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="233721"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="233722"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="233723"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="233724"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="233725"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="233726"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="233727"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="233728"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="233729"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="233730"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="233731"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="233732"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="233733"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="233734"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="233735"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="233736"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="233737"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="233738"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="233739"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="233740"] { transform: matrix(1, 0, 0, 1, -30, -970); }
    &[data-tn-seat-id="233741"] { transform: matrix(1, 0, 0, 1, -40, -960); }
    &[data-tn-seat-id="233742"] { transform: matrix(1, 0, 0, 1, -50, -930); }
    &[data-tn-seat-id="233743"] { transform: matrix(1, 0, 0, 1, -70, -880); }
    &[data-tn-seat-id="233744"] { transform: matrix(1, 0, 0, 1, -90, -830); }
    &[data-tn-seat-id="233745"] { transform: matrix(1, 0, 0, 1, -110, -780); }
    &[data-tn-seat-id="233746"] { transform: matrix(1, 0, 0, 1, -130, -730); }
    &[data-tn-seat-id="233747"] { transform: matrix(1, 0, 0, 1, -150, -680); }
    &[data-tn-seat-id="233748"] { transform: matrix(1, 0, 0, 1, -170, -630); }
    &[data-tn-seat-id="233749"] { transform: matrix(1, 0, 0, 1, -190, -580); }
    &[data-tn-seat-id="233750"] { transform: matrix(1, 0, 0, 1, -210, -530); }
    &[data-tn-seat-id="233751"] { transform: matrix(1, 0, 0, 1, -230, -480); }
    &[data-tn-seat-id="233752"] { transform: matrix(1, 0, 0, 1, -250, -430); }
    &[data-tn-seat-id="233753"] { transform: matrix(1, 0, 0, 1, -270, -380); }
    &[data-tn-seat-id="233754"] { transform: matrix(1, 0, 0, 1, -290, -320); }

    //row 6
    &[data-tn-seat-id="233625"] { transform: matrix(1, 0, 0, 1, 250, -430); }
    &[data-tn-seat-id="233626"] { transform: matrix(1, 0, 0, 1, 230, -480); }
    &[data-tn-seat-id="233627"] { transform: matrix(1, 0, 0, 1, 210, -530); }
    &[data-tn-seat-id="233628"] { transform: matrix(1, 0, 0, 1, 190, -580); }
    &[data-tn-seat-id="233629"] { transform: matrix(1, 0, 0, 1, 170, -630); }
    &[data-tn-seat-id="233630"] { transform: matrix(1, 0, 0, 1, 150, -680); }
    &[data-tn-seat-id="233631"] { transform: matrix(1, 0, 0, 1, 130, -740); }
    &[data-tn-seat-id="233632"] { transform: matrix(1, 0, 0, 1, 110, -790); }
    &[data-tn-seat-id="233633"] { transform: matrix(1, 0, 0, 1, 90, -850); }
    &[data-tn-seat-id="233634"] { transform: matrix(1, 0, 0, 1, 70, -900); }
    &[data-tn-seat-id="233635"] { transform: matrix(1, 0, 0, 1, 50, -950); }
    &[data-tn-seat-id="233636"] { transform: matrix(1, 0, 0, 1, 30, -1000); }
    &[data-tn-seat-id="233637"] { transform: matrix(1, 0, 0, 1, 20, -1030); }

    &[data-tn-seat-id="233638"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="233639"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="233640"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="233641"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="233642"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="233643"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="233644"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="233645"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="233646"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="233647"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="233648"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="233649"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="233650"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="233651"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="233652"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="233653"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="233654"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="233655"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="233656"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="233657"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="233658"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="233659"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="233660"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="233661"] { transform: matrix(1, 0, 0, 1, 10, -1020); }
    &[data-tn-seat-id="233662"] { transform: matrix(1, 0, 0, 1, 10, -990); }
    &[data-tn-seat-id="233663"] { transform: matrix(1, 0, 0, 1, -10, -940); }
    &[data-tn-seat-id="233664"] { transform: matrix(1, 0, 0, 1, -30, -890); }
    &[data-tn-seat-id="233665"] { transform: matrix(1, 0, 0, 1, -50, -840); }
    &[data-tn-seat-id="233666"] { transform: matrix(1, 0, 0, 1, -70, -790); }
    &[data-tn-seat-id="233667"] { transform: matrix(1, 0, 0, 1, -90, -740); }
    &[data-tn-seat-id="233668"] { transform: matrix(1, 0, 0, 1, -110, -690); }
    &[data-tn-seat-id="233669"] { transform: matrix(1, 0, 0, 1, -130, -640); }
    &[data-tn-seat-id="233670"] { transform: matrix(1, 0, 0, 1, -150, -590); }
    &[data-tn-seat-id="233671"] { transform: matrix(1, 0, 0, 1, -170, -540); }
    &[data-tn-seat-id="233672"] { transform: matrix(1, 0, 0, 1, -190, -490); }
    &[data-tn-seat-id="233673"] { transform: matrix(1, 0, 0, 1, -210, -440); }

    //row 7
    &[data-tn-seat-id="233545"] { transform: matrix(1, 0, 0, 1, 240, -530); }
    &[data-tn-seat-id="233546"] { transform: matrix(1, 0, 0, 1, 220, -580); }
    &[data-tn-seat-id="233547"] { transform: matrix(1, 0, 0, 1, 200, -630); }
    &[data-tn-seat-id="233548"] { transform: matrix(1, 0, 0, 1, 180, -680); }
    &[data-tn-seat-id="233549"] { transform: matrix(1, 0, 0, 1, 160, -730); }
    &[data-tn-seat-id="233550"] { transform: matrix(1, 0, 0, 1, 140, -780); }
    &[data-tn-seat-id="233551"] { transform: matrix(1, 0, 0, 1, 120, -830); }
    &[data-tn-seat-id="233552"] { transform: matrix(1, 0, 0, 1, 100, -880); }
    &[data-tn-seat-id="233553"] { transform: matrix(1, 0, 0, 1, 80, -930); }
    &[data-tn-seat-id="233554"] { transform: matrix(1, 0, 0, 1, 60, -980); }
    &[data-tn-seat-id="233555"] { transform: matrix(1, 0, 0, 1, 40, -1030); }
    &[data-tn-seat-id="233556"] { transform: matrix(1, 0, 0, 1, 20, -1070); }

    &[data-tn-seat-id="233557"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="233558"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="233559"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="233560"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="233561"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="233562"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="233563"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="233564"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="233565"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="233566"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="233567"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="233568"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="233569"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="233570"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="233571"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="233572"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="233573"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="233574"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="233575"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="233576"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="233577"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="233578"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="233579"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="233580"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="233581"] { transform: matrix(1, 0, 0, 1, 10, -1090); }

    &[data-tn-seat-id="233582"] { transform: matrix(1, 0, 0, 1, 10, -1070); }
    &[data-tn-seat-id="233583"] { transform: matrix(1, 0, 0, 1, -10, -1020); }
    &[data-tn-seat-id="233584"] { transform: matrix(1, 0, 0, 1, -30, -970); }
    &[data-tn-seat-id="233585"] { transform: matrix(1, 0, 0, 1, -50, -930); }
    &[data-tn-seat-id="233586"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="233587"] { transform: matrix(1, 0, 0, 1, -90, -820); }
    &[data-tn-seat-id="233588"] { transform: matrix(1, 0, 0, 1, -110, -770); }
    &[data-tn-seat-id="233589"] { transform: matrix(1, 0, 0, 1, -130, -720); }
    &[data-tn-seat-id="233590"] { transform: matrix(1, 0, 0, 1, -150, -670); }
    &[data-tn-seat-id="233591"] { transform: matrix(1, 0, 0, 1, -170, -620); }
    &[data-tn-seat-id="233592"] { transform: matrix(1, 0, 0, 1, -190, -570); }
    &[data-tn-seat-id="233593"] { transform: matrix(1, 0, 0, 1, -210, -520); }


    //row 8
    &[data-tn-seat-id="233465"] { transform: matrix(1, 0, 0, 1, 240, -640); }
    &[data-tn-seat-id="233466"] { transform: matrix(1, 0, 0, 1, 220, -690); }
    &[data-tn-seat-id="233467"] { transform: matrix(1, 0, 0, 1, 200, -740); }
    &[data-tn-seat-id="233468"] { transform: matrix(1, 0, 0, 1, 180, -790); }
    &[data-tn-seat-id="233469"] { transform: matrix(1, 0, 0, 1, 160, -840); }
    &[data-tn-seat-id="233470"] { transform: matrix(1, 0, 0, 1, 140, -890); }
    &[data-tn-seat-id="233471"] { transform: matrix(1, 0, 0, 1, 120, -940); }
    &[data-tn-seat-id="233472"] { transform: matrix(1, 0, 0, 1, 100, -990); }
    &[data-tn-seat-id="233473"] { transform: matrix(1, 0, 0, 1, 80, -1040); }
    &[data-tn-seat-id="233474"] { transform: matrix(1, 0, 0, 1, 60, -1090); }
    &[data-tn-seat-id="233475"] { transform: matrix(1, 0, 0, 1, 60, -1130); }

    &[data-tn-seat-id="233476"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="233477"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="233478"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="233479"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="233480"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="233481"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="233482"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="233483"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="233484"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="233485"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="233486"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="233487"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="233488"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="233489"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="233490"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="233491"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="233492"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="233493"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="233494"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="233495"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="233496"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="233497"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="233498"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="233499"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="233500"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="233501"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="233502"] { transform: matrix(1, 0, 0, 1, 60, -1120); }

    &[data-tn-seat-id="233503"] { transform: matrix(1, 0, 0, 1, 40, -1070); }
    &[data-tn-seat-id="233512"] { transform: matrix(1, 0, 0, 1, -140, -620); }
    &[data-tn-seat-id="233504"] { transform: matrix(1, 0, 0, 1, 20, -1020); }
    &[data-tn-seat-id="233505"] { transform: matrix(1, 0, 0, 1, 0, -970); }
    &[data-tn-seat-id="233506"] { transform: matrix(1, 0, 0, 1, -20, -920); }
    &[data-tn-seat-id="233507"] { transform: matrix(1, 0, 0, 1, -40, -870); }
    &[data-tn-seat-id="233508"] { transform: matrix(1, 0, 0, 1, -60, -820); }
    &[data-tn-seat-id="233509"] { transform: matrix(1, 0, 0, 1, -80, -770); }
    &[data-tn-seat-id="233510"] { transform: matrix(1, 0, 0, 1, -100, -720); }
    &[data-tn-seat-id="233511"] { transform: matrix(1, 0, 0, 1, -120, -670); }

    //row 9
    &[data-tn-seat-id="233387"] { transform: matrix(1, 0, 0, 1, 180, -840); }
    &[data-tn-seat-id="233388"] { transform: matrix(1, 0, 0, 1, 160, -890); }
    &[data-tn-seat-id="233389"] { transform: matrix(1, 0, 0, 1, 140, -940); }
    &[data-tn-seat-id="233390"] { transform: matrix(1, 0, 0, 1, 120, -990); }
    &[data-tn-seat-id="233391"] { transform: matrix(1, 0, 0, 1, 100, -1040); }
    &[data-tn-seat-id="233392"] { transform: matrix(1, 0, 0, 1, 80, -1090); }
    &[data-tn-seat-id="233393"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="233394"] { transform: matrix(1, 0, 0, 1, 60, -1180); }
    &[data-tn-seat-id="233395"] { transform: matrix(1, 0, 0, 1, 60, -1190); }

    &[data-tn-seat-id="233396"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="233397"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="233398"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="233399"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="233400"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="233401"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="233402"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="233403"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="233404"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="233405"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="233406"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="233407"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="233408"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="233409"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="233410"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="233411"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="233412"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="233413"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="233414"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="233415"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="233416"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="233417"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="233418"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="233419"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="233420"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="233421"] { transform: matrix(1, 0, 0, 1, 60, -1190); }

    &[data-tn-seat-id="233422"] { transform: matrix(1, 0, 0, 1, 60, -1180); }
    &[data-tn-seat-id="233423"] { transform: matrix(1, 0, 0, 1, 50, -1160); }
    &[data-tn-seat-id="233424"] { transform: matrix(1, 0, 0, 1, 30, -1120); }
    &[data-tn-seat-id="233425"] { transform: matrix(1, 0, 0, 1, 10, -1070); }
    &[data-tn-seat-id="233426"] { transform: matrix(1, 0, 0, 1, -10, -1020); }
    &[data-tn-seat-id="233427"] { transform: matrix(1, 0, 0, 1, -30, -970); }
    &[data-tn-seat-id="233428"] { transform: matrix(1, 0, 0, 1, -50, -920); }
    &[data-tn-seat-id="233429"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="233430"] { transform: matrix(1, 0, 0, 1, -90, -820); }

    //row 10
    &[data-tn-seat-id="233307"] { transform: matrix(1, 0, 0, 1, 200, -960); }
    &[data-tn-seat-id="233308"] { transform: matrix(1, 0, 0, 1, 180, -1010); }
    &[data-tn-seat-id="233309"] { transform: matrix(1, 0, 0, 1, 160, -1060); }
    &[data-tn-seat-id="233310"] { transform: matrix(1, 0, 0, 1, 140, -1110); }
    &[data-tn-seat-id="233311"] { transform: matrix(1, 0, 0, 1, 120, -1160); }
    &[data-tn-seat-id="233312"] { transform: matrix(1, 0, 0, 1, 100, -1210); }

    &[data-tn-seat-id="233313"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233314"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233315"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233316"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233317"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233318"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233319"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233320"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233321"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233322"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233323"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233324"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233325"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233326"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233327"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233328"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233329"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233330"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233331"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233332"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233333"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233334"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233335"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233336"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233337"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233338"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233339"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233340"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233341"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233342"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="233343"] { transform: matrix(1, 0, 0, 1, 90, -1240); }

    &[data-tn-seat-id="233344"] { transform: matrix(1, 0, 0, 1, 70, -1200); }
    &[data-tn-seat-id="233345"] { transform: matrix(1, 0, 0, 1, 50, -1150); }
    &[data-tn-seat-id="233346"] { transform: matrix(1, 0, 0, 1, 30, -1100); }
    &[data-tn-seat-id="233347"] { transform: matrix(1, 0, 0, 1, 10, -1050); }
    &[data-tn-seat-id="233348"] { transform: matrix(1, 0, 0, 1, -10, -1000); }
    &[data-tn-seat-id="233349"] { transform: matrix(1, 0, 0, 1, -30, -950); }

    //row 11

    &[data-tn-seat-id="233148"] { transform: matrix(1, 0, 0, 1, -60, -810); }
    &[data-tn-seat-id="233149"] { transform: matrix(1, 0, 0, 1, -80, -860); }
    &[data-tn-seat-id="233150"] { transform: matrix(1, 0, 0, 1, -100, -910); }
    &[data-tn-seat-id="233151"] { transform: matrix(1, 0, 0, 1, -120, -960); }
    &[data-tn-seat-id="233152"] { transform: matrix(1, 0, 0, 1, -140, -1010); }
    &[data-tn-seat-id="233153"] { transform: matrix(1, 0, 0, 1, -160, -1060); }
    &[data-tn-seat-id="233154"] { transform: matrix(1, 0, 0, 1, -180, -1100); }

    &[data-tn-seat-id="233158"] { transform: matrix(1, 0, 0, 1, -150, -1220); }
    &[data-tn-seat-id="233159"] { transform: matrix(1, 0, 0, 1, -160, -1220); }
    &[data-tn-seat-id="233160"] { transform: matrix(1, 0, 0, 1, -170, -1220); }
    &[data-tn-seat-id="233161"] { transform: matrix(1, 0, 0, 1, -180, -1220); }
    &[data-tn-seat-id="233162"] { transform: matrix(1, 0, 0, 1, -190, -1220); }
    &[data-tn-seat-id="233163"] { transform: matrix(1, 0, 0, 1, -200, -1220); }

    &[data-tn-seat-id="233176"] { transform: matrix(1, 0, 0, 1, 210, -1220); }
    &[data-tn-seat-id="233177"] { transform: matrix(1, 0, 0, 1, 200, -1220); }
    &[data-tn-seat-id="233178"] { transform: matrix(1, 0, 0, 1, 190, -1220); }
    &[data-tn-seat-id="233179"] { transform: matrix(1, 0, 0, 1, 180, -1220); }
    &[data-tn-seat-id="233180"] { transform: matrix(1, 0, 0, 1, 170, -1220); }

    &[data-tn-seat-id="233184"] { transform: matrix(1, 0, 0, 1, 190, -1120); }
    &[data-tn-seat-id="233185"] { transform: matrix(1, 0, 0, 1, 170, -1070); }
    &[data-tn-seat-id="233186"] { transform: matrix(1, 0, 0, 1, 150, -1020); }
    &[data-tn-seat-id="233187"] { transform: matrix(1, 0, 0, 1, 130, -960); }
    &[data-tn-seat-id="233188"] { transform: matrix(1, 0, 0, 1, 110, -910); }
    &[data-tn-seat-id="233189"] { transform: matrix(1, 0, 0, 1, 90, -860); }
    &[data-tn-seat-id="233190"] { transform: matrix(1, 0, 0, 1, 60, -810); }
}

.tn-syos-seat-map__seat {
    //SMAP ID 252 The Lyric Opera Map
    //balcony
    &[data-tn-seat-id="272917"] { transform: matrix(1, 0, 0, 1, 670, 500); }
    &[data-tn-seat-id="272918"] { transform: matrix(1, 0, 0, 1, 650, 450); }
    &[data-tn-seat-id="272919"] { transform: matrix(1, 0, 0, 1, 630, 400); }
    &[data-tn-seat-id="272920"] { transform: matrix(1, 0, 0, 1, 610, 350); }
    &[data-tn-seat-id="272921"] { transform: matrix(1, 0, 0, 1, 590, 300); }

    &[data-tn-seat-id="272837"] { transform: matrix(1, 0, 0, 1, 590, 480); }
    &[data-tn-seat-id="272838"] { transform: matrix(1, 0, 0, 1, 570, 430); }
    &[data-tn-seat-id="272839"] { transform: matrix(1, 0, 0, 1, 550, 380); }

    &[data-tn-seat-id="272979"] { transform: matrix(1, 0, 0, 1, -710, 310); }
    &[data-tn-seat-id="272980"] { transform: matrix(1, 0, 0, 1, -730, 360); }
    &[data-tn-seat-id="272981"] { transform: matrix(1, 0, 0, 1, -750, 400); }
    &[data-tn-seat-id="272982"] { transform: matrix(1, 0, 0, 1, -770, 450); }
    &[data-tn-seat-id="272983"] { transform: matrix(1, 0, 0, 1, -790, 500); }

    &[data-tn-seat-id="272901"] { transform: matrix(1, 0, 0, 1, -670, 380); }
    &[data-tn-seat-id="272902"] { transform: matrix(1, 0, 0, 1, -690, 430); }
    &[data-tn-seat-id="272903"] { transform: matrix(1, 0, 0, 1, -710, 480); }
    
    //Row 1
    &[data-tn-seat-id="272845"] { transform: matrix(1, 0, 0, 1, 310, 50); }
    &[data-tn-seat-id="272846"] { transform: matrix(1, 0, 0, 1, 280, 0); }
    &[data-tn-seat-id="272847"] { transform: matrix(1, 0, 0, 1, 260, -50); }
    &[data-tn-seat-id="272848"] { transform: matrix(1, 0, 0, 1, 240, -100); }
    &[data-tn-seat-id="272849"] { transform: matrix(1, 0, 0, 1, 220, -150); }
    &[data-tn-seat-id="272850"] { transform: matrix(1, 0, 0, 1, 200, -200); }
    &[data-tn-seat-id="272851"] { transform: matrix(1, 0, 0, 1, 180, -250); } 
    &[data-tn-seat-id="272852"] { transform: matrix(1, 0, 0, 1, 160, -300); }
    &[data-tn-seat-id="272853"] { transform: matrix(1, 0, 0, 1, 130, -350); }
    &[data-tn-seat-id="272854"] { transform: matrix(1, 0, 0, 1, 110, -400); }
    &[data-tn-seat-id="272855"] { transform: matrix(1, 0, 0, 1, 80, -450); }
    &[data-tn-seat-id="272856"] { transform: matrix(1, 0, 0, 1, 60, -500); }
    &[data-tn-seat-id="272857"] { transform: matrix(1, 0, 0, 1, 30, -550); }
    &[data-tn-seat-id="272858"] { transform: matrix(1, 0, 0, 1, 10, -590); }
    &[data-tn-seat-id="272859"] { transform: matrix(1, 0, 0, 1, -10, -650); }
    &[data-tn-seat-id="272860"] { transform: matrix(1, 0, 0, 1, -40, -700); }
    &[data-tn-seat-id="272861"] { transform: matrix(1, 0, 0, 1, -60, -740); }
    &[data-tn-seat-id="272862"] { transform: matrix(1, 0, 0, 1, -70, -770); }
    &[data-tn-seat-id="272863"] { transform: matrix(1, 0, 0, 1, -70, -770); }
    &[data-tn-seat-id="272864"] { transform: matrix(1, 0, 0, 1, -70, -770); }
    &[data-tn-seat-id="272865"] { transform: matrix(1, 0, 0, 1, -80, -770); }
    &[data-tn-seat-id="272866"] { transform: matrix(1, 0, 0, 1, -80, -770); }
    &[data-tn-seat-id="272867"] { transform: matrix(1, 0, 0, 1, -80, -770); }
    &[data-tn-seat-id="272868"] { transform: matrix(1, 0, 0, 1, -90, -770); }
    &[data-tn-seat-id="272869"] { transform: matrix(1, 0, 0, 1, -90, -770); }
    &[data-tn-seat-id="272870"] { transform: matrix(1, 0, 0, 1, -100, -770); }
    &[data-tn-seat-id="272871"] { transform: matrix(1, 0, 0, 1, -100, -770); }
    &[data-tn-seat-id="272872"] { transform: matrix(1, 0, 0, 1, -100, -770); }
    &[data-tn-seat-id="272873"] { transform: matrix(1, 0, 0, 1, -110, -770); }
    &[data-tn-seat-id="272874"] { transform: matrix(1, 0, 0, 1, -110, -770); }
    &[data-tn-seat-id="272875"] { transform: matrix(1, 0, 0, 1, -110, -770); }
    &[data-tn-seat-id="272876"] { transform: matrix(1, 0, 0, 1, -120, -770); }
    &[data-tn-seat-id="272877"] { transform: matrix(1, 0, 0, 1, -120, -770); }
    &[data-tn-seat-id="272878"] { transform: matrix(1, 0, 0, 1, -120, -770); }
    &[data-tn-seat-id="272879"] { transform: matrix(1, 0, 0, 1, -130, -760); }
    &[data-tn-seat-id="272880"] { transform: matrix(1, 0, 0, 1, -140, -730); }
    &[data-tn-seat-id="272881"] { transform: matrix(1, 0, 0, 1, -160, -690); }
    &[data-tn-seat-id="272882"] { transform: matrix(1, 0, 0, 1, -180, -640); }
    &[data-tn-seat-id="272883"] { transform: matrix(1, 0, 0, 1, -200, -590); }
    &[data-tn-seat-id="272884"] { transform: matrix(1, 0, 0, 1, -220, -540); }
    &[data-tn-seat-id="272885"] { transform: matrix(1, 0, 0, 1, -250, -490); }
    &[data-tn-seat-id="272886"] { transform: matrix(1, 0, 0, 1, -270, -440); }
    &[data-tn-seat-id="272887"] { transform: matrix(1, 0, 0, 1, -290, -390); }
    &[data-tn-seat-id="272888"] { transform: matrix(1, 0, 0, 1, -320, -340); }
    &[data-tn-seat-id="272889"] { transform: matrix(1, 0, 0, 1, -340, -290); }
    &[data-tn-seat-id="272890"] { transform: matrix(1, 0, 0, 1, -360, -240); }
    &[data-tn-seat-id="272891"] { transform: matrix(1, 0, 0, 1, -380, -190); }
    &[data-tn-seat-id="272892"] { transform: matrix(1, 0, 0, 1, -410, -140); }
    &[data-tn-seat-id="272891"] { transform: matrix(1, 0, 0, 1, -380, -190); }
    &[data-tn-seat-id="272892"] { transform: matrix(1, 0, 0, 1, -400, -140); }
    &[data-tn-seat-id="272893"] { transform: matrix(1, 0, 0, 1, -430, -90); }
    &[data-tn-seat-id="272894"] { transform: matrix(1, 0, 0, 1, -450, -40); }
    &[data-tn-seat-id="272895"] { transform: matrix(1, 0, 0, 1, -480, 0); }
    &[data-tn-seat-id="272896"] { transform: matrix(1, 0, 0, 1, -500, 50); }

    //Row 2
    // &[data-tn-seat-id="233947"] { transform: matrix(1, 0, 0, 1, 200, -90); }
    // &[data-tn-seat-id="233948"] { transform: matrix(1, 0, 0, 1, 180, -140); }
    // &[data-tn-seat-id="233949"] { transform: matrix(1, 0, 0, 1, 160, -190); }
    // &[data-tn-seat-id="233950"] { transform: matrix(1, 0, 0, 1, 140, -240); }
    // &[data-tn-seat-id="233946"] { transform: matrix(1, 0, 0, 1, 230, -40); }
    // &[data-tn-seat-id="233947"] { transform: matrix(1, 0, 0, 1, 200, -80); }
    // &[data-tn-seat-id="233948"] { transform: matrix(1, 0, 0, 1, 180, -140); }
    // &[data-tn-seat-id="233948"] { transform: matrix(1, 0, 0, 1, 180, -140); }
    // &[data-tn-seat-id="233949"] { transform: matrix(1, 0, 0, 1, 160, -200); }
    // &[data-tn-seat-id="233950"] { transform: matrix(1, 0, 0, 1, 140, -250); }
    // &[data-tn-seat-id="233946"] { transform: matrix(1, 0, 0, 1, 220, -20); }

    &[data-tn-seat-id="272765"] { transform: matrix(1, 0, 0, 1, 250, 10); }
    &[data-tn-seat-id="272766"] { transform: matrix(1, 0, 0, 1, 230, -40); }
    &[data-tn-seat-id="272767"] { transform: matrix(1, 0, 0, 1, 210, -90); }
    &[data-tn-seat-id="272768"] { transform: matrix(1, 0, 0, 1, 190, -140); }
    &[data-tn-seat-id="272769"] { transform: matrix(1, 0, 0, 1, 170, -190); }
    &[data-tn-seat-id="272770"] { transform: matrix(1, 0, 0, 1, 150, -240); }
    &[data-tn-seat-id="272771"] { transform: matrix(1, 0, 0, 1, 130, -290); }
    &[data-tn-seat-id="272772"] { transform: matrix(1, 0, 0, 1, 100, -350); }
    // &[data-tn-seat-id="233951"] { transform: matrix(1, 0, 0, 1, 120, -300); }
    &[data-tn-seat-id="272773"] { transform: matrix(1, 0, 0, 1, 80, -400); }
    &[data-tn-seat-id="272774"] { transform: matrix(1, 0, 0, 1, 60, -450); }
    &[data-tn-seat-id="272775"] { transform: matrix(1, 0, 0, 1, 40, -500); }
    &[data-tn-seat-id="272776"] { transform: matrix(1, 0, 0, 1, 20, -550); }
    &[data-tn-seat-id="272777"] { transform: matrix(1, 0, 0, 1, 0, -600); }
    &[data-tn-seat-id="272778"] { transform: matrix(1, 0, 0, 1, -20, -660); }
    &[data-tn-seat-id="272779"] { transform: matrix(1, 0, 0, 1, -50, -700); }
    // &[data-tn-seat-id="233958"] { transform: matrix(1, 0, 0, 1, -20, -650); }
    // &[data-tn-seat-id="233959"] { transform: matrix(1, 0, 0, 1, -40, -700); }
    &[data-tn-seat-id="272780"] { transform: matrix(1, 0, 0, 1, -60, -750); }
    &[data-tn-seat-id="272781"] { transform: matrix(1, 0, 0, 1, -80, -790); }
    &[data-tn-seat-id="272782"] { transform: matrix(1, 0, 0, 1, -90, -810); }
    &[data-tn-seat-id="272783"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    // &[data-tn-seat-id="233962"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="272784"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="272785"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="272786"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="272787"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="272788"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="272789"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="272790"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="272791"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="272792"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="272793"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="272794"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="272795"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="272796"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="272797"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="272798"] { transform: matrix(1, 0, 0, 1, -70, -810); }
    &[data-tn-seat-id="272799"] { transform: matrix(1, 0, 0, 1, -60, -800); }
    &[data-tn-seat-id="272800"] { transform: matrix(1, 0, 0, 1, -70, -760); }
    &[data-tn-seat-id="272801"] { transform: matrix(1, 0, 0, 1, -90, -710); }
    &[data-tn-seat-id="272802"] { transform: matrix(1, 0, 0, 1, -110, -660); }
    &[data-tn-seat-id="272803"] { transform: matrix(1, 0, 0, 1, -130, -610); }
    &[data-tn-seat-id="272804"] { transform: matrix(1, 0, 0, 1, -150, -560); }
    &[data-tn-seat-id="272805"] { transform: matrix(1, 0, 0, 1, -170, -510); }
    &[data-tn-seat-id="272806"] { transform: matrix(1, 0, 0, 1, -190, -460); }
    &[data-tn-seat-id="272807"] { transform: matrix(1, 0, 0, 1, -210, -410); }
    &[data-tn-seat-id="272808"] { transform: matrix(1, 0, 0, 1, -230, -360); }
    &[data-tn-seat-id="272809"] { transform: matrix(1, 0, 0, 1, -250, -310); }
    &[data-tn-seat-id="272810"] { transform: matrix(1, 0, 0, 1, -270, -260); }
    &[data-tn-seat-id="272811"] { transform: matrix(1, 0, 0, 1, -290, -210); }
    &[data-tn-seat-id="272812"] { transform: matrix(1, 0, 0, 1, -310, -160); }
    &[data-tn-seat-id="272813"] { transform: matrix(1, 0, 0, 1, -330, -110); }
    &[data-tn-seat-id="272814"] { transform: matrix(1, 0, 0, 1, -350, -60); }
    &[data-tn-seat-id="272815"] { transform: matrix(1, 0, 0, 1, -370, -10); }

    //Row 3
    &[data-tn-seat-id="272685"] { transform: matrix(1, 0, 0, 1, 240, -90); }
    &[data-tn-seat-id="272686"] { transform: matrix(1, 0, 0, 1, 220, -140); }
    &[data-tn-seat-id="272687"] { transform: matrix(1, 0, 0, 1, 200, -190); }
    &[data-tn-seat-id="272688"] { transform: matrix(1, 0, 0, 1, 180, -240); }
    &[data-tn-seat-id="272689"] { transform: matrix(1, 0, 0, 1, 160, -290); }
    &[data-tn-seat-id="272690"] { transform: matrix(1, 0, 0, 1, 140, -340); }
    &[data-tn-seat-id="272691"] { transform: matrix(1, 0, 0, 1, 120, -390); }
    &[data-tn-seat-id="272692"] { transform: matrix(1, 0, 0, 1, 100, -450); }
    // &[data-tn-seat-id="272693"] { transform: matrix(1, 0, 0, 1, 80, -510); }
    // &[data-tn-seat-id="272694"] { transform: matrix(1, 0, 0, 1, 60, -570); }
    // &[data-tn-seat-id="272695"] { transform: matrix(1, 0, 0, 1, 50, -620); }
    &[data-tn-seat-id="272693"] { transform: matrix(1, 0, 0, 1, 80, -500); }
    &[data-tn-seat-id="272694"] { transform: matrix(1, 0, 0, 1, 60, -560); }
    &[data-tn-seat-id="272695"] { transform: matrix(1, 0, 0, 1, 40, -610); }
    &[data-tn-seat-id="272696"] { transform: matrix(1, 0, 0, 1, 20, -660); }
    &[data-tn-seat-id="272697"] { transform: matrix(1, 0, 0, 1, 0, -710); }
    &[data-tn-seat-id="272698"] { transform: matrix(1, 0, 0, 1, -20, -760); }
    &[data-tn-seat-id="272699"] { transform: matrix(1, 0, 0, 1, -40, -810); }
    &[data-tn-seat-id="272700"] { transform: matrix(1, 0, 0, 1, -60, -850); }
    &[data-tn-seat-id="272701"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="272702"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="272703"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="272704"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="272705"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="272706"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="272707"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="272708"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="272709"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="272710"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="272711"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="272712"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="272713"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="272714"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="272715"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="272716"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="272717"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="272718"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="272719"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="272720"] { transform: matrix(1, 0, 0, 1, -70, -850); }
    &[data-tn-seat-id="272721"] { transform: matrix(1, 0, 0, 1, -80, -810); }
    &[data-tn-seat-id="272722"] { transform: matrix(1, 0, 0, 1, -100, -760); }
    &[data-tn-seat-id="272723"] { transform: matrix(1, 0, 0, 1, -120, -710); }
    &[data-tn-seat-id="272724"] { transform: matrix(1, 0, 0, 1, -140, -660); }
    &[data-tn-seat-id="272725"] { transform: matrix(1, 0, 0, 1, -160, -610); }
    &[data-tn-seat-id="272726"] { transform: matrix(1, 0, 0, 1, -180, -560); }
    &[data-tn-seat-id="272727"] { transform: matrix(1, 0, 0, 1, -200, -510); }
    &[data-tn-seat-id="272728"] { transform: matrix(1, 0, 0, 1, -220, -460); }
    &[data-tn-seat-id="272729"] { transform: matrix(1, 0, 0, 1, -240, -410); }
    &[data-tn-seat-id="272730"] { transform: matrix(1, 0, 0, 1, -260, -360); }
    &[data-tn-seat-id="272731"] { transform: matrix(1, 0, 0, 1, -280, -310); }
    &[data-tn-seat-id="272732"] { transform: matrix(1, 0, 0, 1, -300, -260); }
    &[data-tn-seat-id="272733"] { transform: matrix(1, 0, 0, 1, -320, -210); }
    &[data-tn-seat-id="272734"] { transform: matrix(1, 0, 0, 1, -340, -160); }
    &[data-tn-seat-id="272735"] { transform: matrix(1, 0, 0, 1, -360, -110); }

    //Row 4
    &[data-tn-seat-id="272605"] { transform: matrix(1, 0, 0, 1, 230, -180); }
    &[data-tn-seat-id="272606"] { transform: matrix(1, 0, 0, 1, 210, -230); }
    &[data-tn-seat-id="272607"] { transform: matrix(1, 0, 0, 1, 190, -280); }
    &[data-tn-seat-id="272608"] { transform: matrix(1, 0, 0, 1, 170, -330); }
    &[data-tn-seat-id="272609"] { transform: matrix(1, 0, 0, 1, 150, -380); }
    &[data-tn-seat-id="272610"] { transform: matrix(1, 0, 0, 1, 130, -440); }
    &[data-tn-seat-id="272611"] { transform: matrix(1, 0, 0, 1, 110, -500); }
    &[data-tn-seat-id="272612"] { transform: matrix(1, 0, 0, 1, 90, -550); }
    &[data-tn-seat-id="272613"] { transform: matrix(1, 0, 0, 1, 70, -600); }
    &[data-tn-seat-id="272614"] { transform: matrix(1, 0, 0, 1, 50, -650); }
    &[data-tn-seat-id="272615"] { transform: matrix(1, 0, 0, 1, 30, -700); }
    &[data-tn-seat-id="272616"] { transform: matrix(1, 0, 0, 1, 10, -750); }
    &[data-tn-seat-id="272617"] { transform: matrix(1, 0, 0, 1, -10, -800); }
    &[data-tn-seat-id="272618"] { transform: matrix(1, 0, 0, 1, -30, -850); }
    &[data-tn-seat-id="272619"] { transform: matrix(1, 0, 0, 1, -50, -890); }

    &[data-tn-seat-id="272620"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="272621"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="272622"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="272623"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="272624"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="272625"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="272626"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="272627"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="272628"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="272629"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="272630"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="272631"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="272632"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="272633"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="272634"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="272635"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="272636"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="272637"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="272638"] { transform: matrix(1, 0, 0, 1, -60, -920); }
    &[data-tn-seat-id="272639"] { transform: matrix(1, 0, 0, 1, -60, -920); }

    &[data-tn-seat-id="272640"] { transform: matrix(1, 0, 0, 1, -70, -910); }
    &[data-tn-seat-id="272641"] { transform: matrix(1, 0, 0, 1, -70, -880); }
    &[data-tn-seat-id="272642"] { transform: matrix(1, 0, 0, 1, -80, -840); }
    &[data-tn-seat-id="272643"] { transform: matrix(1, 0, 0, 1, -100, -790); }
    &[data-tn-seat-id="272644"] { transform: matrix(1, 0, 0, 1, -120, -740); }
    &[data-tn-seat-id="272645"] { transform: matrix(1, 0, 0, 1, -140, -690); }
    &[data-tn-seat-id="272646"] { transform: matrix(1, 0, 0, 1, -160, -640); }
    &[data-tn-seat-id="272647"] { transform: matrix(1, 0, 0, 1, -180, -590); }
    &[data-tn-seat-id="272648"] { transform: matrix(1, 0, 0, 1, -200, -540); }
    &[data-tn-seat-id="272649"] { transform: matrix(1, 0, 0, 1, -220, -490); }
    &[data-tn-seat-id="272650"] { transform: matrix(1, 0, 0, 1, -240, -440); }
    &[data-tn-seat-id="272651"] { transform: matrix(1, 0, 0, 1, -260, -390); }
    &[data-tn-seat-id="272652"] { transform: matrix(1, 0, 0, 1, -280, -340); }
    &[data-tn-seat-id="272653"] { transform: matrix(1, 0, 0, 1, -300, -290); }
    &[data-tn-seat-id="272654"] { transform: matrix(1, 0, 0, 1, -320, -240); }
    &[data-tn-seat-id="272655"] { transform: matrix(1, 0, 0, 1, -340, -190); }

    //Row 5
    &[data-tn-seat-id="272525"] { transform: matrix(1, 0, 0, 1, 220, -290); }
    &[data-tn-seat-id="272526"] { transform: matrix(1, 0, 0, 1, 200, -340); }
    &[data-tn-seat-id="272527"] { transform: matrix(1, 0, 0, 1, 180, -390); }
    &[data-tn-seat-id="272528"] { transform: matrix(1, 0, 0, 1, 160, -440); }
    &[data-tn-seat-id="272529"] { transform: matrix(1, 0, 0, 1, 140, -490); }
    &[data-tn-seat-id="272530"] { transform: matrix(1, 0, 0, 1, 120, -550); }
    &[data-tn-seat-id="272531"] { transform: matrix(1, 0, 0, 1, 100, -600); }
    &[data-tn-seat-id="272532"] { transform: matrix(1, 0, 0, 1, 80, -660); }
    &[data-tn-seat-id="272533"] { transform: matrix(1, 0, 0, 1, 60, -710); }
    &[data-tn-seat-id="272534"] { transform: matrix(1, 0, 0, 1, 40, -760); }
    &[data-tn-seat-id="272535"] { transform: matrix(1, 0, 0, 1, 20, -810); }
    &[data-tn-seat-id="272536"] { transform: matrix(1, 0, 0, 1, 0, -860); }
    &[data-tn-seat-id="272537"] { transform: matrix(1, 0, 0, 1, -20, -910); }
    &[data-tn-seat-id="272538"] { transform: matrix(1, 0, 0, 1, -30, -960); }
    &[data-tn-seat-id="272539"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="272540"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="272541"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="272542"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="272543"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="272544"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="272545"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="272546"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="272547"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="272548"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="272549"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="272550"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="272551"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="272552"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="272553"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="272554"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="272555"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="272556"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="272557"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="272558"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="272559"] { transform: matrix(1, 0, 0, 1, -30, -980); }
    &[data-tn-seat-id="272560"] { transform: matrix(1, 0, 0, 1, -30, -970); }
    &[data-tn-seat-id="272561"] { transform: matrix(1, 0, 0, 1, -40, -960); }
    &[data-tn-seat-id="272562"] { transform: matrix(1, 0, 0, 1, -50, -930); }
    &[data-tn-seat-id="272563"] { transform: matrix(1, 0, 0, 1, -70, -880); }
    &[data-tn-seat-id="272564"] { transform: matrix(1, 0, 0, 1, -90, -830); }
    &[data-tn-seat-id="272565"] { transform: matrix(1, 0, 0, 1, -110, -780); }
    &[data-tn-seat-id="272566"] { transform: matrix(1, 0, 0, 1, -130, -730); }
    &[data-tn-seat-id="272567"] { transform: matrix(1, 0, 0, 1, -150, -680); }
    &[data-tn-seat-id="272568"] { transform: matrix(1, 0, 0, 1, -170, -630); }
    &[data-tn-seat-id="272569"] { transform: matrix(1, 0, 0, 1, -190, -580); }
    &[data-tn-seat-id="272570"] { transform: matrix(1, 0, 0, 1, -210, -530); }
    &[data-tn-seat-id="272571"] { transform: matrix(1, 0, 0, 1, -230, -480); }
    &[data-tn-seat-id="272572"] { transform: matrix(1, 0, 0, 1, -250, -430); }
    &[data-tn-seat-id="272573"] { transform: matrix(1, 0, 0, 1, -270, -380); }
    &[data-tn-seat-id="272574"] { transform: matrix(1, 0, 0, 1, -290, -320); }

    //Row 6
    &[data-tn-seat-id="272445"] { transform: matrix(1, 0, 0, 1, 250, -430); }
    &[data-tn-seat-id="272446"] { transform: matrix(1, 0, 0, 1, 230, -480); }
    &[data-tn-seat-id="272447"] { transform: matrix(1, 0, 0, 1, 210, -530); }
    &[data-tn-seat-id="272448"] { transform: matrix(1, 0, 0, 1, 190, -580); }
    &[data-tn-seat-id="272449"] { transform: matrix(1, 0, 0, 1, 170, -630); }
    &[data-tn-seat-id="272450"] { transform: matrix(1, 0, 0, 1, 150, -680); }
    &[data-tn-seat-id="272451"] { transform: matrix(1, 0, 0, 1, 130, -740); }
    &[data-tn-seat-id="272452"] { transform: matrix(1, 0, 0, 1, 110, -790); }
    &[data-tn-seat-id="272453"] { transform: matrix(1, 0, 0, 1, 90, -850); }
    &[data-tn-seat-id="272454"] { transform: matrix(1, 0, 0, 1, 70, -900); }
    &[data-tn-seat-id="272455"] { transform: matrix(1, 0, 0, 1, 50, -950); }
    &[data-tn-seat-id="272456"] { transform: matrix(1, 0, 0, 1, 30, -1000); }
    &[data-tn-seat-id="272457"] { transform: matrix(1, 0, 0, 1, 20, -1030); }
    &[data-tn-seat-id="272458"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="272459"] { transform: matrix(1, 0, 0, 1, 10, -1040); }

    &[data-tn-seat-id="272460"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="272461"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="272462"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="272463"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="272464"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="272465"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="272466"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="272467"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="272468"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="272469"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="272470"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="272471"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="272472"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="272473"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="272474"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="272475"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="272476"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="272477"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="272478"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="272479"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="272480"] { transform: matrix(1, 0, 0, 1, 10, -1040); }
    &[data-tn-seat-id="272481"] { transform: matrix(1, 0, 0, 1, 10, -1020); }
    &[data-tn-seat-id="272482"] { transform: matrix(1, 0, 0, 1, 10, -990); }
    &[data-tn-seat-id="272483"] { transform: matrix(1, 0, 0, 1, -10, -940); }
    &[data-tn-seat-id="272484"] { transform: matrix(1, 0, 0, 1, -30, -890); }
    &[data-tn-seat-id="272485"] { transform: matrix(1, 0, 0, 1, -50, -840); }
    &[data-tn-seat-id="272486"] { transform: matrix(1, 0, 0, 1, -70, -790); }
    &[data-tn-seat-id="272487"] { transform: matrix(1, 0, 0, 1, -90, -740); }
    &[data-tn-seat-id="272488"] { transform: matrix(1, 0, 0, 1, -110, -690); }
    &[data-tn-seat-id="272489"] { transform: matrix(1, 0, 0, 1, -130, -640); }
    &[data-tn-seat-id="272490"] { transform: matrix(1, 0, 0, 1, -150, -590); }
    &[data-tn-seat-id="272491"] { transform: matrix(1, 0, 0, 1, -170, -540); }
    &[data-tn-seat-id="272492"] { transform: matrix(1, 0, 0, 1, -190, -490); }
    &[data-tn-seat-id="272493"] { transform: matrix(1, 0, 0, 1, -210, -440); }

    //Row 7
    &[data-tn-seat-id="272365"] { transform: matrix(1, 0, 0, 1, 240, -530); }
    &[data-tn-seat-id="272366"] { transform: matrix(1, 0, 0, 1, 220, -580); }
    &[data-tn-seat-id="272367"] { transform: matrix(1, 0, 0, 1, 200, -630); }
    &[data-tn-seat-id="272368"] { transform: matrix(1, 0, 0, 1, 180, -680); }
    &[data-tn-seat-id="272369"] { transform: matrix(1, 0, 0, 1, 160, -730); }
    &[data-tn-seat-id="272370"] { transform: matrix(1, 0, 0, 1, 140, -780); }
    &[data-tn-seat-id="272371"] { transform: matrix(1, 0, 0, 1, 120, -830); }
    &[data-tn-seat-id="272372"] { transform: matrix(1, 0, 0, 1, 100, -880); }
    &[data-tn-seat-id="272373"] { transform: matrix(1, 0, 0, 1, 80, -930); }
    &[data-tn-seat-id="272374"] { transform: matrix(1, 0, 0, 1, 60, -980); }
    &[data-tn-seat-id="272375"] { transform: matrix(1, 0, 0, 1, 40, -1030); }
    &[data-tn-seat-id="272376"] { transform: matrix(1, 0, 0, 1, 20, -1070); }
    &[data-tn-seat-id="272377"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="272378"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="272379"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="272380"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="272381"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="272382"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="272383"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="272384"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="272385"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="272386"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="272387"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="272388"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="272389"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="272390"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="272391"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="272392"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="272393"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="272394"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="272395"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="272396"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="272397"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="272398"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="272399"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="272400"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="272401"] { transform: matrix(1, 0, 0, 1, 10, -1090); }
    &[data-tn-seat-id="272402"] { transform: matrix(1, 0, 0, 1, 10, -1070); }
    &[data-tn-seat-id="272403"] { transform: matrix(1, 0, 0, 1, -10, -1020); }
    &[data-tn-seat-id="272404"] { transform: matrix(1, 0, 0, 1, -30, -970); }
    &[data-tn-seat-id="272405"] { transform: matrix(1, 0, 0, 1, -50, -930); }
    &[data-tn-seat-id="272406"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="272407"] { transform: matrix(1, 0, 0, 1, -90, -820); }
    &[data-tn-seat-id="272408"] { transform: matrix(1, 0, 0, 1, -110, -770); }
    &[data-tn-seat-id="272409"] { transform: matrix(1, 0, 0, 1, -130, -720); }
    &[data-tn-seat-id="272410"] { transform: matrix(1, 0, 0, 1, -150, -670); }
    &[data-tn-seat-id="272411"] { transform: matrix(1, 0, 0, 1, -170, -620); }
    &[data-tn-seat-id="272412"] { transform: matrix(1, 0, 0, 1, -190, -570); }
    &[data-tn-seat-id="272413"] { transform: matrix(1, 0, 0, 1, -210, -520); }

    //Row 8
    &[data-tn-seat-id="272285"] { transform: matrix(1, 0, 0, 1, 240, -640); }
    &[data-tn-seat-id="272286"] { transform: matrix(1, 0, 0, 1, 220, -690); }
    &[data-tn-seat-id="272287"] { transform: matrix(1, 0, 0, 1, 200, -740); }
    &[data-tn-seat-id="272288"] { transform: matrix(1, 0, 0, 1, 180, -790); }
    &[data-tn-seat-id="272289"] { transform: matrix(1, 0, 0, 1, 160, -840); }
    &[data-tn-seat-id="272290"] { transform: matrix(1, 0, 0, 1, 140, -890); }
    &[data-tn-seat-id="272291"] { transform: matrix(1, 0, 0, 1, 120, -940); }
    &[data-tn-seat-id="272292"] { transform: matrix(1, 0, 0, 1, 100, -990); }
    &[data-tn-seat-id="272293"] { transform: matrix(1, 0, 0, 1, 80, -1040); }
    &[data-tn-seat-id="272294"] { transform: matrix(1, 0, 0, 1, 60, -1090); }
    &[data-tn-seat-id="272295"] { transform: matrix(1, 0, 0, 1, 60, -1130); }
    &[data-tn-seat-id="272296"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="272297"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="272298"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="272299"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="272300"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="272301"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="272302"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="272303"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="272304"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="272305"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="272306"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="272307"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="272308"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="272309"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="272310"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="272311"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="272312"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="272313"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="272314"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="272315"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="272316"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="272317"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="272318"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="272319"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="272320"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="272321"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="272322"] { transform: matrix(1, 0, 0, 1, 60, -1120); }
    &[data-tn-seat-id="272323"] { transform: matrix(1, 0, 0, 1, 40, -1070); }
    &[data-tn-seat-id="272324"] { transform: matrix(1, 0, 0, 1, 20, -1020); }
    &[data-tn-seat-id="272325"] { transform: matrix(1, 0, 0, 1, 0, -970); }
    &[data-tn-seat-id="272326"] { transform: matrix(1, 0, 0, 1, -20, -920); }
    &[data-tn-seat-id="272327"] { transform: matrix(1, 0, 0, 1, -40, -870); }
    &[data-tn-seat-id="272328"] { transform: matrix(1, 0, 0, 1, -60, -820); }
    &[data-tn-seat-id="272329"] { transform: matrix(1, 0, 0, 1, -80, -770); }
    &[data-tn-seat-id="272330"] { transform: matrix(1, 0, 0, 1, -100, -720); }
    &[data-tn-seat-id="272331"] { transform: matrix(1, 0, 0, 1, -120, -670); }
    &[data-tn-seat-id="272332"] { transform: matrix(1, 0, 0, 1, -140, -620); }

    //row 9
    &[data-tn-seat-id="272207"] { transform: matrix(1, 0, 0, 1, 180, -840); }
    &[data-tn-seat-id="272208"] { transform: matrix(1, 0, 0, 1, 160, -890); }
    &[data-tn-seat-id="272209"] { transform: matrix(1, 0, 0, 1, 140, -940); }
    &[data-tn-seat-id="272210"] { transform: matrix(1, 0, 0, 1, 120, -990); }
    &[data-tn-seat-id="272211"] { transform: matrix(1, 0, 0, 1, 100, -1040); }
    &[data-tn-seat-id="272212"] { transform: matrix(1, 0, 0, 1, 80, -1090); }
    &[data-tn-seat-id="272213"] { transform: matrix(1, 0, 0, 1, 60, -1140); }
    &[data-tn-seat-id="272214"] { transform: matrix(1, 0, 0, 1, 60, -1180); }
    &[data-tn-seat-id="272215"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="272216"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="272217"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="272218"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="272219"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="272220"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="272221"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="272222"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="272223"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="272224"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="272225"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="272226"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="272227"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="272228"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="272229"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="272230"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="272231"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="272232"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="272233"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="272234"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="272235"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="272236"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="272237"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="272238"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="272239"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="272240"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="272241"] { transform: matrix(1, 0, 0, 1, 60, -1190); }
    &[data-tn-seat-id="272242"] { transform: matrix(1, 0, 0, 1, 60, -1180); }
    &[data-tn-seat-id="272243"] { transform: matrix(1, 0, 0, 1, 50, -1160); }
    &[data-tn-seat-id="272244"] { transform: matrix(1, 0, 0, 1, 30, -1120); }
    &[data-tn-seat-id="272245"] { transform: matrix(1, 0, 0, 1, 10, -1070); }
    &[data-tn-seat-id="272246"] { transform: matrix(1, 0, 0, 1, -10, -1020); }
    &[data-tn-seat-id="272247"] { transform: matrix(1, 0, 0, 1, -30, -970); }
    &[data-tn-seat-id="272248"] { transform: matrix(1, 0, 0, 1, -50, -920); }
    &[data-tn-seat-id="272249"] { transform: matrix(1, 0, 0, 1, -70, -870); }
    &[data-tn-seat-id="272250"] { transform: matrix(1, 0, 0, 1, -90, -820); }

    //Row 10
    &[data-tn-seat-id="272127"] { transform: matrix(1, 0, 0, 1, 200, -960); }
    &[data-tn-seat-id="272128"] { transform: matrix(1, 0, 0, 1, 180, -1010); }
    &[data-tn-seat-id="272129"] { transform: matrix(1, 0, 0, 1, 160, -1060); }
    &[data-tn-seat-id="272130"] { transform: matrix(1, 0, 0, 1, 140, -1110); }
    &[data-tn-seat-id="272131"] { transform: matrix(1, 0, 0, 1, 120, -1160); }
    &[data-tn-seat-id="272132"] { transform: matrix(1, 0, 0, 1, 100, -1210); }
    &[data-tn-seat-id="272133"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272134"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272135"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272136"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272137"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272138"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272139"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272140"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272141"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272142"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272143"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272144"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272145"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272146"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272147"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272148"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272149"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272150"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272151"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272152"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272153"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272154"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272155"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272156"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272157"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272158"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272159"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272160"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272161"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272162"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272163"] { transform: matrix(1, 0, 0, 1, 90, -1240); }
    &[data-tn-seat-id="272164"] { transform: matrix(1, 0, 0, 1, 70, -1200); }
    &[data-tn-seat-id="272165"] { transform: matrix(1, 0, 0, 1, 50, -1150); }
    &[data-tn-seat-id="272166"] { transform: matrix(1, 0, 0, 1, 30, -1100); }
    &[data-tn-seat-id="272167"] { transform: matrix(1, 0, 0, 1, 10, -1050); }
    &[data-tn-seat-id="272168"] { transform: matrix(1, 0, 0, 1, -10, -1000); }
    &[data-tn-seat-id="272169"] { transform: matrix(1, 0, 0, 1, -30, -950); }

    //Row 11
    &[data-tn-seat-id="271968"] { transform: matrix(1, 0, 0, 1, -60, -810); }
    &[data-tn-seat-id="271969"] { transform: matrix(1, 0, 0, 1, -80, -860); }
    &[data-tn-seat-id="271970"] { transform: matrix(1, 0, 0, 1, -100, -910); }
    &[data-tn-seat-id="271971"] { transform: matrix(1, 0, 0, 1, -120, -960); }
    &[data-tn-seat-id="271972"] { transform: matrix(1, 0, 0, 1, -140, -1010); }
    &[data-tn-seat-id="271973"] { transform: matrix(1, 0, 0, 1, -160, -1060); }
    &[data-tn-seat-id="271974"] { transform: matrix(1, 0, 0, 1, -180, -1100); }
    &[data-tn-seat-id="271978"] { transform: matrix(1, 0, 0, 1, -150, -1220); }
    &[data-tn-seat-id="271979"] { transform: matrix(1, 0, 0, 1, -160, -1220); }
    &[data-tn-seat-id="271980"] { transform: matrix(1, 0, 0, 1, -170, -1220); }
    &[data-tn-seat-id="271981"] { transform: matrix(1, 0, 0, 1, -180, -1220); }
    &[data-tn-seat-id="271982"] { transform: matrix(1, 0, 0, 1, -190, -1220); }
    &[data-tn-seat-id="271983"] { transform: matrix(1, 0, 0, 1, -200, -1220); }

    &[data-tn-seat-id="271996"] { transform: matrix(1, 0, 0, 1, 210, -1220); }
    &[data-tn-seat-id="271997"] { transform: matrix(1, 0, 0, 1, 200, -1220); }
    &[data-tn-seat-id="271998"] { transform: matrix(1, 0, 0, 1, 190, -1220); }
    &[data-tn-seat-id="271999"] { transform: matrix(1, 0, 0, 1, 180, -1220); }
    &[data-tn-seat-id="272000"] { transform: matrix(1, 0, 0, 1, 170, -1220); }

    &[data-tn-seat-id="272004"] { transform: matrix(1, 0, 0, 1, 190, -1120); }
    &[data-tn-seat-id="272005"] { transform: matrix(1, 0, 0, 1, 170, -1070); }
    &[data-tn-seat-id="272006"] { transform: matrix(1, 0, 0, 1, 150, -1020); }
    &[data-tn-seat-id="272007"] { transform: matrix(1, 0, 0, 1, 130, -960); }
    &[data-tn-seat-id="272008"] { transform: matrix(1, 0, 0, 1, 110, -910); }
    &[data-tn-seat-id="272009"] { transform: matrix(1, 0, 0, 1, 90, -860); }
    &[data-tn-seat-id="272010"] { transform: matrix(1, 0, 0, 1, 60, -810); }
}

}
