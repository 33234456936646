@supports not (-ms-high-contrast: none) {
   /* Non-IE styles here */

.tn-syos-seat-map__seat {
    // Row 1
    &[data-tn-seat-id="230516"] { transform: matrix(1, 0, 0, 1, -90, 1020); }
    &[data-tn-seat-id="230517"] { transform: matrix(1, 0, 0, 1, -110, 970); }
    &[data-tn-seat-id="230518"] { transform: matrix(1, 0, 0, 1, -130, 920); }
    &[data-tn-seat-id="230519"] { transform: matrix(1, 0, 0, 1, -160, 870); }
    &[data-tn-seat-id="230520"] { transform: matrix(1, 0, 0, 1, -180, 820); }

    &[data-tn-seat-id="230521"] { transform: matrix(1, 0, 0, 1, -180, 790); }
    &[data-tn-seat-id="230522"] { transform: matrix(1, 0, 0, 1, -190, 790); }
    &[data-tn-seat-id="230523"] { transform: matrix(1, 0, 0, 1, -200, 790); }
    &[data-tn-seat-id="230524"] { transform: matrix(1, 0, 0, 1, -210, 790); }
    &[data-tn-seat-id="230525"] { transform: matrix(1, 0, 0, 1, -220, 790); }
    &[data-tn-seat-id="230526"] { transform: matrix(1, 0, 0, 1, -230, 790); }
    &[data-tn-seat-id="230527"] { transform: matrix(1, 0, 0, 1, -240, 790); }
    &[data-tn-seat-id="230528"] { transform: matrix(1, 0, 0, 1, -250, 790); }
    &[data-tn-seat-id="230529"] { transform: matrix(1, 0, 0, 1, -260, 790); }
    &[data-tn-seat-id="230530"] { transform: matrix(1, 0, 0, 1, -270, 790); }
    &[data-tn-seat-id="230531"] { transform: matrix(1, 0, 0, 1, -280, 790); }
    &[data-tn-seat-id="230532"] { transform: matrix(1, 0, 0, 1, -290, 790); }
    &[data-tn-seat-id="230533"] { transform: matrix(1, 0, 0, 1, -300, 790); }
    &[data-tn-seat-id="230534"] { transform: matrix(1, 0, 0, 1, -310, 790); }
    &[data-tn-seat-id="230535"] { transform: matrix(1, 0, 0, 1, -320, 790); }
    &[data-tn-seat-id="230536"] { transform: matrix(1, 0, 0, 1, -330, 790); }
    &[data-tn-seat-id="230537"] { transform: matrix(1, 0, 0, 1, -340, 790); }

    &[data-tn-seat-id="230538"] { transform: matrix(1, 0, 0, 1, -330, 820); }
    &[data-tn-seat-id="230539"] { transform: matrix(1, 0, 0, 1, -360, 870); }
    &[data-tn-seat-id="230540"] { transform: matrix(1, 0, 0, 1, -380, 920); }

    // Row 2
    &[data-tn-seat-id="230436"] { transform: matrix(1, 0, 0, 1, -140, 990); }
    &[data-tn-seat-id="230437"] { transform: matrix(1, 0, 0, 1, -160, 940); }
    &[data-tn-seat-id="230438"] { transform: matrix(1, 0, 0, 1, -180, 880); }
    &[data-tn-seat-id="230439"] { transform: matrix(1, 0, 0, 1, -200, 830); }
    &[data-tn-seat-id="230440"] { transform: matrix(1, 0, 0, 1, -220, 780); }

    &[data-tn-seat-id="230441"] { transform: matrix(1, 0, 0, 1, -220, 750); }
    &[data-tn-seat-id="230442"] { transform: matrix(1, 0, 0, 1, -230, 750); }
    &[data-tn-seat-id="230443"] { transform: matrix(1, 0, 0, 1, -240, 750); }
    &[data-tn-seat-id="230444"] { transform: matrix(1, 0, 0, 1, -250, 750); }
    &[data-tn-seat-id="230445"] { transform: matrix(1, 0, 0, 1, -260, 750); }
    &[data-tn-seat-id="230446"] { transform: matrix(1, 0, 0, 1, -270, 750); }
    &[data-tn-seat-id="230447"] { transform: matrix(1, 0, 0, 1, -280, 750); }
    &[data-tn-seat-id="230448"] { transform: matrix(1, 0, 0, 1, -290, 750); }
    &[data-tn-seat-id="230449"] { transform: matrix(1, 0, 0, 1, -300, 750); }
    &[data-tn-seat-id="230450"] { transform: matrix(1, 0, 0, 1, -310, 750); }
    &[data-tn-seat-id="230451"] { transform: matrix(1, 0, 0, 1, -320, 750); }
    &[data-tn-seat-id="230452"] { transform: matrix(1, 0, 0, 1, -330, 750); }
    &[data-tn-seat-id="230453"] { transform: matrix(1, 0, 0, 1, -340, 750); }
    &[data-tn-seat-id="230454"] { transform: matrix(1, 0, 0, 1, -350, 750); }
    &[data-tn-seat-id="230455"] { transform: matrix(1, 0, 0, 1, -360, 750); }
    &[data-tn-seat-id="230456"] { transform: matrix(1, 0, 0, 1, -370, 750); }
    &[data-tn-seat-id="230457"] { transform: matrix(1, 0, 0, 1, -380, 750); }
    &[data-tn-seat-id="230458"] { transform: matrix(1, 0, 0, 1, -390, 750); }

    &[data-tn-seat-id="230459"] { transform: matrix(1, 0, 0, 1, -390, 790); }

    //row 3
    &[data-tn-seat-id="230354"] { transform: matrix(1, 0, 0, 1, -90, 980); }
    &[data-tn-seat-id="230355"] { transform: matrix(1, 0, 0, 1, -110, 930); }
    &[data-tn-seat-id="230356"] { transform: matrix(1, 0, 0, 1, -130, 880); }
    &[data-tn-seat-id="230357"] { transform: matrix(1, 0, 0, 1, -150, 830); }
    &[data-tn-seat-id="230358"] { transform: matrix(1, 0, 0, 1, -170, 780); }
    &[data-tn-seat-id="230359"] { transform: matrix(1, 0, 0, 1, -190, 730); }

    &[data-tn-seat-id="230360"] { transform: matrix(1, 0, 0, 1, -190, 710); }
    &[data-tn-seat-id="230361"] { transform: matrix(1, 0, 0, 1, -200, 710); }
    &[data-tn-seat-id="230362"] { transform: matrix(1, 0, 0, 1, -210, 710); }
    &[data-tn-seat-id="230363"] { transform: matrix(1, 0, 0, 1, -220, 710); }
    &[data-tn-seat-id="230364"] { transform: matrix(1, 0, 0, 1, -230, 710); }
    &[data-tn-seat-id="230365"] { transform: matrix(1, 0, 0, 1, -240, 710); }
    &[data-tn-seat-id="230366"] { transform: matrix(1, 0, 0, 1, -250, 710); }
    &[data-tn-seat-id="230367"] { transform: matrix(1, 0, 0, 1, -260, 710); }
    &[data-tn-seat-id="230368"] { transform: matrix(1, 0, 0, 1, -270, 710); }
    &[data-tn-seat-id="230369"] { transform: matrix(1, 0, 0, 1, -280, 710); }
    &[data-tn-seat-id="230370"] { transform: matrix(1, 0, 0, 1, -290, 710); }
    &[data-tn-seat-id="230371"] { transform: matrix(1, 0, 0, 1, -300, 710); }
    &[data-tn-seat-id="230372"] { transform: matrix(1, 0, 0, 1, -310, 710); }
    &[data-tn-seat-id="230373"] { transform: matrix(1, 0, 0, 1, -320, 710); }
    &[data-tn-seat-id="230374"] { transform: matrix(1, 0, 0, 1, -330, 710); }
    &[data-tn-seat-id="230375"] { transform: matrix(1, 0, 0, 1, -340, 710); }
    &[data-tn-seat-id="230376"] { transform: matrix(1, 0, 0, 1, -350, 710); }
    &[data-tn-seat-id="230377"] { transform: matrix(1, 0, 0, 1, -360, 710); }
    &[data-tn-seat-id="230378"] { transform: matrix(1, 0, 0, 1, -370, 710); }
    &[data-tn-seat-id="230379"] { transform: matrix(1, 0, 0, 1, -370, 750); }
    &[data-tn-seat-id="230380"] { transform: matrix(1, 0, 0, 1, -390, 800); }
    &[data-tn-seat-id="230381"] { transform: matrix(1, 0, 0, 1, -410, 850); }

    //Row 4
    &[data-tn-seat-id="230277"] { transform: matrix(1, 0, 0, 1, -240, 830); }
    &[data-tn-seat-id="230278"] { transform: matrix(1, 0, 0, 1, -260, 780); }
    &[data-tn-seat-id="230279"] { transform: matrix(1, 0, 0, 1, -280, 730); }
    &[data-tn-seat-id="230280"] { transform: matrix(1, 0, 0, 1, -300, 680); }
    &[data-tn-seat-id="230281"] { transform: matrix(1, 0, 0, 1, -300, 660); }
    &[data-tn-seat-id="230282"] { transform: matrix(1, 0, 0, 1, -310, 660); }
    &[data-tn-seat-id="230283"] { transform: matrix(1, 0, 0, 1, -320, 660); }
    &[data-tn-seat-id="230284"] { transform: matrix(1, 0, 0, 1, -330, 660); }
    &[data-tn-seat-id="230285"] { transform: matrix(1, 0, 0, 1, -340, 660); }
    &[data-tn-seat-id="230286"] { transform: matrix(1, 0, 0, 1, -350, 660); }
    &[data-tn-seat-id="230287"] { transform: matrix(1, 0, 0, 1, -360, 660); }
    &[data-tn-seat-id="230288"] { transform: matrix(1, 0, 0, 1, -370, 660); }
    &[data-tn-seat-id="230289"] { transform: matrix(1, 0, 0, 1, -380, 660); }
    &[data-tn-seat-id="230290"] { transform: matrix(1, 0, 0, 1, -390, 660); }
    &[data-tn-seat-id="230291"] { transform: matrix(1, 0, 0, 1, -400, 660); }
    &[data-tn-seat-id="230292"] { transform: matrix(1, 0, 0, 1, -410, 660); }
    &[data-tn-seat-id="230293"] { transform: matrix(1, 0, 0, 1, -420, 660); }
    &[data-tn-seat-id="230294"] { transform: matrix(1, 0, 0, 1, -430, 660); }
    &[data-tn-seat-id="230295"] { transform: matrix(1, 0, 0, 1, -440, 660); }
    &[data-tn-seat-id="230296"] { transform: matrix(1, 0, 0, 1, -450, 660); }
    &[data-tn-seat-id="230297"] { transform: matrix(1, 0, 0, 1, -460, 660); }

    //Row 5
    &[data-tn-seat-id="230195"] { transform: matrix(1, 0, 0, 1, -170, 830); }
    &[data-tn-seat-id="230196"] { transform: matrix(1, 0, 0, 1, -190, 780); }
    &[data-tn-seat-id="230197"] { transform: matrix(1, 0, 0, 1, -210, 730); }
    &[data-tn-seat-id="230198"] { transform: matrix(1, 0, 0, 1, -240, 680); }
    &[data-tn-seat-id="230197"] { transform: matrix(1, 0, 0, 1, -220, 730); }
    &[data-tn-seat-id="230196"] { transform: matrix(1, 0, 0, 1, -200, 780); }

    &[data-tn-seat-id="230199"] { transform: matrix(1, 0, 0, 1, -250, 630); }
    &[data-tn-seat-id="230200"] { transform: matrix(1, 0, 0, 1, -240, 620); }
    &[data-tn-seat-id="230201"] { transform: matrix(1, 0, 0, 1, -250, 620); }
    &[data-tn-seat-id="230202"] { transform: matrix(1, 0, 0, 1, -260, 620); }
    &[data-tn-seat-id="230203"] { transform: matrix(1, 0, 0, 1, -270, 620); }
    &[data-tn-seat-id="230204"] { transform: matrix(1, 0, 0, 1, -280, 620); }
    &[data-tn-seat-id="230205"] { transform: matrix(1, 0, 0, 1, -290, 620); }
    &[data-tn-seat-id="230206"] { transform: matrix(1, 0, 0, 1, -300, 620); }
    &[data-tn-seat-id="230207"] { transform: matrix(1, 0, 0, 1, -310, 620); }
    &[data-tn-seat-id="230208"] { transform: matrix(1, 0, 0, 1, -320, 620); }
    &[data-tn-seat-id="230209"] { transform: matrix(1, 0, 0, 1, -330, 620); }
    &[data-tn-seat-id="230210"] { transform: matrix(1, 0, 0, 1, -340, 620); }
    &[data-tn-seat-id="230211"] { transform: matrix(1, 0, 0, 1, -350, 620); }
    &[data-tn-seat-id="230212"] { transform: matrix(1, 0, 0, 1, -360, 620); }
    &[data-tn-seat-id="230213"] { transform: matrix(1, 0, 0, 1, -370, 620); }
    &[data-tn-seat-id="230214"] { transform: matrix(1, 0, 0, 1, -380, 620); }
    &[data-tn-seat-id="230215"] { transform: matrix(1, 0, 0, 1, -390, 620); }
    &[data-tn-seat-id="230216"] { transform: matrix(1, 0, 0, 1, -400, 620); }
    &[data-tn-seat-id="230217"] { transform: matrix(1, 0, 0, 1, -410, 620); }
    &[data-tn-seat-id="230218"] { transform: matrix(1, 0, 0, 1, -420, 620); }

    //Row 6
    &[data-tn-seat-id="230114"] { transform: matrix(1, 0, 0, 1, -180, 810); }
    &[data-tn-seat-id="230115"] { transform: matrix(1, 0, 0, 1, -210, 760); }
    &[data-tn-seat-id="230116"] { transform: matrix(1, 0, 0, 1, -230, 710); }
    &[data-tn-seat-id="230117"] { transform: matrix(1, 0, 0, 1, -250, 660); }
    &[data-tn-seat-id="230118"] { transform: matrix(1, 0, 0, 1, -270, 610); }
    &[data-tn-seat-id="230119"] { transform: matrix(1, 0, 0, 1, -270, 580); }

    &[data-tn-seat-id="230120"] { transform: matrix(1, 0, 0, 1, -270, 580); }
    &[data-tn-seat-id="230121"] { transform: matrix(1, 0, 0, 1, -280, 580); }
    &[data-tn-seat-id="230122"] { transform: matrix(1, 0, 0, 1, -290, 580); }
    &[data-tn-seat-id="230123"] { transform: matrix(1, 0, 0, 1, -300, 580); }
    &[data-tn-seat-id="230124"] { transform: matrix(1, 0, 0, 1, -310, 580); }
    &[data-tn-seat-id="230125"] { transform: matrix(1, 0, 0, 1, -320, 580); }
    &[data-tn-seat-id="230126"] { transform: matrix(1, 0, 0, 1, -330, 580); }
    &[data-tn-seat-id="230127"] { transform: matrix(1, 0, 0, 1, -340, 580); }
    &[data-tn-seat-id="230128"] { transform: matrix(1, 0, 0, 1, -350, 580); }
    &[data-tn-seat-id="230129"] { transform: matrix(1, 0, 0, 1, -360, 580); }
    &[data-tn-seat-id="230130"] { transform: matrix(1, 0, 0, 1, -370, 580); }
    &[data-tn-seat-id="230131"] { transform: matrix(1, 0, 0, 1, -380, 580); }
    &[data-tn-seat-id="230132"] { transform: matrix(1, 0, 0, 1, -390, 580); }
    &[data-tn-seat-id="230133"] { transform: matrix(1, 0, 0, 1, -400, 580); }
    &[data-tn-seat-id="230134"] { transform: matrix(1, 0, 0, 1, -410, 580); }
    &[data-tn-seat-id="230135"] { transform: matrix(1, 0, 0, 1, -420, 580); }
    &[data-tn-seat-id="230136"] { transform: matrix(1, 0, 0, 1, -430, 580); }
    &[data-tn-seat-id="230137"] { transform: matrix(1, 0, 0, 1, -440, 580); }
    &[data-tn-seat-id="230138"] { transform: matrix(1, 0, 0, 1, -450, 580); }
    &[data-tn-seat-id="230139"] { transform: matrix(1, 0, 0, 1, -460, 580); }

    //Row 7
    &[data-tn-seat-id="230033"] { transform: matrix(1, 0, 0, 1, -190, 800); }
    &[data-tn-seat-id="230034"] { transform: matrix(1, 0, 0, 1, -220, 750); }
    &[data-tn-seat-id="230035"] { transform: matrix(1, 0, 0, 1, -240, 700); }
    &[data-tn-seat-id="230036"] { transform: matrix(1, 0, 0, 1, -260, 650); }
    &[data-tn-seat-id="230037"] { transform: matrix(1, 0, 0, 1, -280, 600); }
    &[data-tn-seat-id="230038"] { transform: matrix(1, 0, 0, 1, -300, 560); }
    &[data-tn-seat-id="230039"] { transform: matrix(1, 0, 0, 1, -300, 540); }
    &[data-tn-seat-id="230040"] { transform: matrix(1, 0, 0, 1, -290, 540); }
    &[data-tn-seat-id="230041"] { transform: matrix(1, 0, 0, 1, -300, 540); }
    &[data-tn-seat-id="230042"] { transform: matrix(1, 0, 0, 1, -310, 540); }
    &[data-tn-seat-id="230043"] { transform: matrix(1, 0, 0, 1, -320, 540); }
    &[data-tn-seat-id="230044"] { transform: matrix(1, 0, 0, 1, -330, 540); }
    &[data-tn-seat-id="230045"] { transform: matrix(1, 0, 0, 1, -340, 540); }
    &[data-tn-seat-id="230046"] { transform: matrix(1, 0, 0, 1, -350, 540); }
    &[data-tn-seat-id="230047"] { transform: matrix(1, 0, 0, 1, -360, 540); }
    &[data-tn-seat-id="230048"] { transform: matrix(1, 0, 0, 1, -370, 540); }
    &[data-tn-seat-id="230049"] { transform: matrix(1, 0, 0, 1, -380, 540); }
    &[data-tn-seat-id="230050"] { transform: matrix(1, 0, 0, 1, -390, 540); }
    &[data-tn-seat-id="230051"] { transform: matrix(1, 0, 0, 1, -400, 540); }
    &[data-tn-seat-id="230052"] { transform: matrix(1, 0, 0, 1, -410, 540); }
    &[data-tn-seat-id="230053"] { transform: matrix(1, 0, 0, 1, -420, 540); }
    &[data-tn-seat-id="230054"] { transform: matrix(1, 0, 0, 1, -430, 540); }
    &[data-tn-seat-id="230055"] { transform: matrix(1, 0, 0, 1, -440, 540); }
    &[data-tn-seat-id="230056"] { transform: matrix(1, 0, 0, 1, -450, 540); }
    &[data-tn-seat-id="230057"] { transform: matrix(1, 0, 0, 1, -460, 540); }
    &[data-tn-seat-id="230058"] { transform: matrix(1, 0, 0, 1, -470, 540); }
    &[data-tn-seat-id="230059"] { transform: matrix(1, 0, 0, 1, -480, 540); }
    &[data-tn-seat-id="230060"] { transform: matrix(1, 0, 0, 1, -490, 540); }

    //Row 8
    &[data-tn-seat-id="229952"] { transform: matrix(1, 0, 0, 1, -200, 800); }
    &[data-tn-seat-id="229953"] { transform: matrix(1, 0, 0, 1, -230, 750); }
    &[data-tn-seat-id="229954"] { transform: matrix(1, 0, 0, 1, -260, 700); }
    &[data-tn-seat-id="229955"] { transform: matrix(1, 0, 0, 1, -280, 650); }
    &[data-tn-seat-id="229954"] { transform: matrix(1, 0, 0, 1, -250, 710); }
    &[data-tn-seat-id="229953"] { transform: matrix(1, 0, 0, 1, -220, 750); }
    &[data-tn-seat-id="229954"] { transform: matrix(1, 0, 0, 1, -240, 700); }
    &[data-tn-seat-id="229955"] { transform: matrix(1, 0, 0, 1, -260, 650); }
    &[data-tn-seat-id="229956"] { transform: matrix(1, 0, 0, 1, -280, 600); }
    &[data-tn-seat-id="229957"] { transform: matrix(1, 0, 0, 1, -300, 550); }
    &[data-tn-seat-id="229958"] { transform: matrix(1, 0, 0, 1, -300, 510); }
    &[data-tn-seat-id="229959"] { transform: matrix(1, 0, 0, 1, -290, 500); }
    &[data-tn-seat-id="229960"] { transform: matrix(1, 0, 0, 1, -300, 500); }
    &[data-tn-seat-id="229961"] { transform: matrix(1, 0, 0, 1, -310, 500); }
    &[data-tn-seat-id="229962"] { transform: matrix(1, 0, 0, 1, -320, 500); }
    &[data-tn-seat-id="229963"] { transform: matrix(1, 0, 0, 1, -330, 500); }
    &[data-tn-seat-id="229964"] { transform: matrix(1, 0, 0, 1, -340, 500); }
    &[data-tn-seat-id="229965"] { transform: matrix(1, 0, 0, 1, -350, 500); }
    &[data-tn-seat-id="229966"] { transform: matrix(1, 0, 0, 1, -360, 500); }
    &[data-tn-seat-id="229967"] { transform: matrix(1, 0, 0, 1, -370, 500); }
    &[data-tn-seat-id="229968"] { transform: matrix(1, 0, 0, 1, -380, 500); }
    &[data-tn-seat-id="229969"] { transform: matrix(1, 0, 0, 1, -390, 500); }
    &[data-tn-seat-id="229970"] { transform: matrix(1, 0, 0, 1, -400, 500); }
    &[data-tn-seat-id="229971"] { transform: matrix(1, 0, 0, 1, -410, 500); }
    &[data-tn-seat-id="229972"] { transform: matrix(1, 0, 0, 1, -420, 500); }
    &[data-tn-seat-id="229973"] { transform: matrix(1, 0, 0, 1, -430, 500); }
    &[data-tn-seat-id="229974"] { transform: matrix(1, 0, 0, 1, -440, 500); }
    &[data-tn-seat-id="229975"] { transform: matrix(1, 0, 0, 1, -450, 500); }
    &[data-tn-seat-id="229976"] { transform: matrix(1, 0, 0, 1, -460, 500); }
    &[data-tn-seat-id="229977"] { transform: matrix(1, 0, 0, 1, -470, 500); }
    &[data-tn-seat-id="229978"] { transform: matrix(1, 0, 0, 1, -480, 500); }
    &[data-tn-seat-id="229979"] { transform: matrix(1, 0, 0, 1, -490, 500); }
    &[data-tn-seat-id="229980"] { transform: matrix(1, 0, 0, 1, -500, 500); }
    &[data-tn-seat-id="229981"] { transform: matrix(1, 0, 0, 1, -510, 500); }

    //Row 9
    &[data-tn-seat-id="229870"] { transform: matrix(1, 0, 0, 1, -140, 790); }
    &[data-tn-seat-id="229871"] { transform: matrix(1, 0, 0, 1, -160, 740); }
    &[data-tn-seat-id="229872"] { transform: matrix(1, 0, 0, 1, -180, 690); }
    &[data-tn-seat-id="229873"] { transform: matrix(1, 0, 0, 1, -210, 640); }
    &[data-tn-seat-id="229874"] { transform: matrix(1, 0, 0, 1, -230, 590); }
    &[data-tn-seat-id="229875"] { transform: matrix(1, 0, 0, 1, -250, 540); }
    &[data-tn-seat-id="229876"] { transform: matrix(1, 0, 0, 1, -270, 490); }
    &[data-tn-seat-id="229877"] { transform: matrix(1, 0, 0, 1, -280, 460); }
    &[data-tn-seat-id="229878"] { transform: matrix(1, 0, 0, 1, -290, 450); }
    &[data-tn-seat-id="229879"] { transform: matrix(1, 0, 0, 1, -300, 450); }
    &[data-tn-seat-id="229880"] { transform: matrix(1, 0, 0, 1, -310, 450); }
    &[data-tn-seat-id="229881"] { transform: matrix(1, 0, 0, 1, -320, 450); }
    &[data-tn-seat-id="229882"] { transform: matrix(1, 0, 0, 1, -330, 450); }
    &[data-tn-seat-id="229883"] { transform: matrix(1, 0, 0, 1, -340, 450); }
    &[data-tn-seat-id="229884"] { transform: matrix(1, 0, 0, 1, -350, 450); }
    &[data-tn-seat-id="229885"] { transform: matrix(1, 0, 0, 1, -360, 450); }
    &[data-tn-seat-id="229886"] { transform: matrix(1, 0, 0, 1, -370, 450); }
    &[data-tn-seat-id="229887"] { transform: matrix(1, 0, 0, 1, -380, 450); }
    &[data-tn-seat-id="229888"] { transform: matrix(1, 0, 0, 1, -390, 450); }
    &[data-tn-seat-id="229889"] { transform: matrix(1, 0, 0, 1, -400, 450); }
    &[data-tn-seat-id="229890"] { transform: matrix(1, 0, 0, 1, -410, 450); }
    &[data-tn-seat-id="229891"] { transform: matrix(1, 0, 0, 1, -420, 450); }
    &[data-tn-seat-id="229892"] { transform: matrix(1, 0, 0, 1, -430, 450); }
    &[data-tn-seat-id="229893"] { transform: matrix(1, 0, 0, 1, -440, 450); }
    &[data-tn-seat-id="229894"] { transform: matrix(1, 0, 0, 1, -450, 450); }
    &[data-tn-seat-id="229895"] { transform: matrix(1, 0, 0, 1, -460, 450); }
    &[data-tn-seat-id="229896"] { transform: matrix(1, 0, 0, 1, -470, 450); }
    &[data-tn-seat-id="229897"] { transform: matrix(1, 0, 0, 1, -480, 450); }
    &[data-tn-seat-id="229898"] { transform: matrix(1, 0, 0, 1, -490, 450); }
    &[data-tn-seat-id="229899"] { transform: matrix(1, 0, 0, 1, -500, 450); }
    &[data-tn-seat-id="229900"] { transform: matrix(1, 0, 0, 1, -510, 450); }
    &[data-tn-seat-id="229901"] { transform: matrix(1, 0, 0, 1, -520, 450); }
    &[data-tn-seat-id="229902"] { transform: matrix(1, 0, 0, 1, -530, 450); }

    //Path
    &[data-tn-seat-id="230103"] { transform: matrix(1, 0, 0, 1, 50, 710); }
    &[data-tn-seat-id="230104"] { transform: matrix(1, 0, 0, 1, 30, 660); }

    //Row 10
    &[data-tn-seat-id="229789"] { transform: matrix(1, 0, 0, 1, -150, 790); }
    &[data-tn-seat-id="229790"] { transform: matrix(1, 0, 0, 1, -170, 740); }
    &[data-tn-seat-id="229791"] { transform: matrix(1, 0, 0, 1, -190, 690); }
    &[data-tn-seat-id="229792"] { transform: matrix(1, 0, 0, 1, -210, 640); }
    &[data-tn-seat-id="229793"] { transform: matrix(1, 0, 0, 1, -230, 590); }
    &[data-tn-seat-id="229794"] { transform: matrix(1, 0, 0, 1, -250, 540); }
    &[data-tn-seat-id="229795"] { transform: matrix(1, 0, 0, 1, -270, 490); }
    &[data-tn-seat-id="229796"] { transform: matrix(1, 0, 0, 1, -290, 440); }

    &[data-tn-seat-id="229797"] { transform: matrix(1, 0, 0, 1, -300, 410); }
    &[data-tn-seat-id="229798"] { transform: matrix(1, 0, 0, 1, -310, 410); }
    &[data-tn-seat-id="229799"] { transform: matrix(1, 0, 0, 1, -320, 410); }
    &[data-tn-seat-id="229800"] { transform: matrix(1, 0, 0, 1, -330, 410); }
    &[data-tn-seat-id="229801"] { transform: matrix(1, 0, 0, 1, -340, 410); }
    &[data-tn-seat-id="229802"] { transform: matrix(1, 0, 0, 1, -350, 410); }
    &[data-tn-seat-id="229803"] { transform: matrix(1, 0, 0, 1, -360, 410); }
    &[data-tn-seat-id="229804"] { transform: matrix(1, 0, 0, 1, -370, 410); }
    &[data-tn-seat-id="229805"] { transform: matrix(1, 0, 0, 1, -380, 410); }
    &[data-tn-seat-id="229806"] { transform: matrix(1, 0, 0, 1, -390, 410); }
    &[data-tn-seat-id="229807"] { transform: matrix(1, 0, 0, 1, -400, 410); }
    &[data-tn-seat-id="229808"] { transform: matrix(1, 0, 0, 1, -410, 410); }
    &[data-tn-seat-id="229809"] { transform: matrix(1, 0, 0, 1, -420, 410); }
    &[data-tn-seat-id="229810"] { transform: matrix(1, 0, 0, 1, -430, 410); }
    &[data-tn-seat-id="229811"] { transform: matrix(1, 0, 0, 1, -440, 410); }
    &[data-tn-seat-id="229812"] { transform: matrix(1, 0, 0, 1, -450, 410); }
    &[data-tn-seat-id="229813"] { transform: matrix(1, 0, 0, 1, -460, 410); }
    &[data-tn-seat-id="229814"] { transform: matrix(1, 0, 0, 1, -470, 410); }
    &[data-tn-seat-id="229815"] { transform: matrix(1, 0, 0, 1, -480, 410); }
    &[data-tn-seat-id="229816"] { transform: matrix(1, 0, 0, 1, -490, 410); }
    &[data-tn-seat-id="229817"] { transform: matrix(1, 0, 0, 1, -500, 410); }
    &[data-tn-seat-id="229818"] { transform: matrix(1, 0, 0, 1, -510, 410); }
    &[data-tn-seat-id="229819"] { transform: matrix(1, 0, 0, 1, -520, 410); }
    &[data-tn-seat-id="229820"] { transform: matrix(1, 0, 0, 1, -530, 410); }
    &[data-tn-seat-id="229821"] { transform: matrix(1, 0, 0, 1, -540, 410); }
    &[data-tn-seat-id="229822"] { transform: matrix(1, 0, 0, 1, -550, 410); }
    &[data-tn-seat-id="229823"] { transform: matrix(1, 0, 0, 1, -560, 410); }

    //Row 11
    &[data-tn-seat-id="229708"] { transform: matrix(1, 0, 0, 1, -160, 770); }
    &[data-tn-seat-id="229709"] { transform: matrix(1, 0, 0, 1, -180, 720); }
    &[data-tn-seat-id="229710"] { transform: matrix(1, 0, 0, 1, -210, 670); }
    &[data-tn-seat-id="229711"] { transform: matrix(1, 0, 0, 1, -230, 620); }
    &[data-tn-seat-id="229712"] { transform: matrix(1, 0, 0, 1, -260, 570); }
    &[data-tn-seat-id="229713"] { transform: matrix(1, 0, 0, 1, -280, 530); }
    &[data-tn-seat-id="229712"] { transform: matrix(1, 0, 0, 1, -250, 570); }
    &[data-tn-seat-id="229713"] { transform: matrix(1, 0, 0, 1, -270, 520); }
    &[data-tn-seat-id="229714"] { transform: matrix(1, 0, 0, 1, -290, 470); }
    &[data-tn-seat-id="229715"] { transform: matrix(1, 0, 0, 1, -310, 420); }
    &[data-tn-seat-id="229716"] { transform: matrix(1, 0, 0, 1, -330, 370); }
    &[data-tn-seat-id="229717"] { transform: matrix(1, 0, 0, 1, -340, 360); }
    &[data-tn-seat-id="229718"] { transform: matrix(1, 0, 0, 1, -350, 360); }
    &[data-tn-seat-id="229719"] { transform: matrix(1, 0, 0, 1, -360, 360); }
    &[data-tn-seat-id="229720"] { transform: matrix(1, 0, 0, 1, -370, 360); }
    &[data-tn-seat-id="229721"] { transform: matrix(1, 0, 0, 1, -380, 360); }
    &[data-tn-seat-id="229722"] { transform: matrix(1, 0, 0, 1, -390, 360); }
    &[data-tn-seat-id="229723"] { transform: matrix(1, 0, 0, 1, -400, 360); }
    &[data-tn-seat-id="229724"] { transform: matrix(1, 0, 0, 1, -410, 360); }
    &[data-tn-seat-id="229725"] { transform: matrix(1, 0, 0, 1, -420, 360); }
    &[data-tn-seat-id="229726"] { transform: matrix(1, 0, 0, 1, -430, 360); }
    &[data-tn-seat-id="229727"] { transform: matrix(1, 0, 0, 1, -440, 360); }
    &[data-tn-seat-id="229728"] { transform: matrix(1, 0, 0, 1, -450, 360); }
    &[data-tn-seat-id="229729"] { transform: matrix(1, 0, 0, 1, -460, 360); }
    &[data-tn-seat-id="229730"] { transform: matrix(1, 0, 0, 1, -470, 360); }
    &[data-tn-seat-id="229731"] { transform: matrix(1, 0, 0, 1, -480, 360); }
    &[data-tn-seat-id="229732"] { transform: matrix(1, 0, 0, 1, -490, 360); }
    &[data-tn-seat-id="229733"] { transform: matrix(1, 0, 0, 1, -500, 360); }
    &[data-tn-seat-id="229734"] { transform: matrix(1, 0, 0, 1, -510, 360); }
    &[data-tn-seat-id="229735"] { transform: matrix(1, 0, 0, 1, -520, 360); }
    &[data-tn-seat-id="229736"] { transform: matrix(1, 0, 0, 1, -530, 360); }
    &[data-tn-seat-id="229737"] { transform: matrix(1, 0, 0, 1, -540, 360); }
    &[data-tn-seat-id="229738"] { transform: matrix(1, 0, 0, 1, -550, 360); }
    &[data-tn-seat-id="229739"] { transform: matrix(1, 0, 0, 1, -560, 360); }
    &[data-tn-seat-id="229740"] { transform: matrix(1, 0, 0, 1, -570, 360); }
    &[data-tn-seat-id="229741"] { transform: matrix(1, 0, 0, 1, -580, 360); }
    &[data-tn-seat-id="229742"] { transform: matrix(1, 0, 0, 1, -590, 360); }
    &[data-tn-seat-id="229743"] { transform: matrix(1, 0, 0, 1, -600, 360); }
    &[data-tn-seat-id="229744"] { transform: matrix(1, 0, 0, 1, -610, 360); }

    //Row 12
    &[data-tn-seat-id="229627"] { transform: matrix(1, 0, 0, 1, -130, 720); }
    &[data-tn-seat-id="229628"] { transform: matrix(1, 0, 0, 1, -150, 670); }
    &[data-tn-seat-id="229629"] { transform: matrix(1, 0, 0, 1, -170, 620); }
    &[data-tn-seat-id="229630"] { transform: matrix(1, 0, 0, 1, -190, 570); }
    &[data-tn-seat-id="229631"] { transform: matrix(1, 0, 0, 1, -210, 520); }
    &[data-tn-seat-id="229632"] { transform: matrix(1, 0, 0, 1, -230, 470); }
    &[data-tn-seat-id="229633"] { transform: matrix(1, 0, 0, 1, -250, 420); }
    &[data-tn-seat-id="229634"] { transform: matrix(1, 0, 0, 1, -270, 370); }
    &[data-tn-seat-id="229635"] { transform: matrix(1, 0, 0, 1, -290, 330); }
    &[data-tn-seat-id="229636"] { transform: matrix(1, 0, 0, 1, -300, 320); }
    &[data-tn-seat-id="229637"] { transform: matrix(1, 0, 0, 1, -310, 320); }
    &[data-tn-seat-id="229638"] { transform: matrix(1, 0, 0, 1, -320, 320); }
    &[data-tn-seat-id="229639"] { transform: matrix(1, 0, 0, 1, -330, 320); }
    &[data-tn-seat-id="229640"] { transform: matrix(1, 0, 0, 1, -340, 320); }
    &[data-tn-seat-id="229641"] { transform: matrix(1, 0, 0, 1, -350, 320); }
    &[data-tn-seat-id="229642"] { transform: matrix(1, 0, 0, 1, -360, 320); }
    &[data-tn-seat-id="229643"] { transform: matrix(1, 0, 0, 1, -370, 320); }
    &[data-tn-seat-id="229644"] { transform: matrix(1, 0, 0, 1, -380, 320); }
    &[data-tn-seat-id="229645"] { transform: matrix(1, 0, 0, 1, -390, 320); }
    &[data-tn-seat-id="229646"] { transform: matrix(1, 0, 0, 1, -400, 320); }
    &[data-tn-seat-id="229647"] { transform: matrix(1, 0, 0, 1, -410, 320); }
    &[data-tn-seat-id="229648"] { transform: matrix(1, 0, 0, 1, -420, 320); }
    &[data-tn-seat-id="229649"] { transform: matrix(1, 0, 0, 1, -430, 320); }
    &[data-tn-seat-id="229650"] { transform: matrix(1, 0, 0, 1, -440, 320); }
    &[data-tn-seat-id="229651"] { transform: matrix(1, 0, 0, 1, -450, 320); }
    &[data-tn-seat-id="229652"] { transform: matrix(1, 0, 0, 1, -460, 320); }
    &[data-tn-seat-id="229653"] { transform: matrix(1, 0, 0, 1, -470, 320); }
    &[data-tn-seat-id="229654"] { transform: matrix(1, 0, 0, 1, -480, 320); }
    &[data-tn-seat-id="229655"] { transform: matrix(1, 0, 0, 1, -490, 320); }
    &[data-tn-seat-id="229656"] { transform: matrix(1, 0, 0, 1, -500, 320); }
    &[data-tn-seat-id="229657"] { transform: matrix(1, 0, 0, 1, -510, 320); }
    &[data-tn-seat-id="229658"] { transform: matrix(1, 0, 0, 1, -520, 320); }
    &[data-tn-seat-id="229659"] { transform: matrix(1, 0, 0, 1, -530, 320); }
    &[data-tn-seat-id="229660"] { transform: matrix(1, 0, 0, 1, -540, 320); }
    &[data-tn-seat-id="229661"] { transform: matrix(1, 0, 0, 1, -550, 320); }
    &[data-tn-seat-id="229662"] { transform: matrix(1, 0, 0, 1, -560, 320); }

    //Row 13
    &[data-tn-seat-id="229548"] { transform: matrix(1, 0, 0, 1, -220, 660); }
    &[data-tn-seat-id="229549"] { transform: matrix(1, 0, 0, 1, -240, 610); }
    &[data-tn-seat-id="229550"] { transform: matrix(1, 0, 0, 1, -270, 560); }

    &[data-tn-seat-id="229553"] { transform: matrix(1, 0, 0, 1, -320, 420); }
    &[data-tn-seat-id="229554"] { transform: matrix(1, 0, 0, 1, -340, 370); }
    &[data-tn-seat-id="229555"] { transform: matrix(1, 0, 0, 1, -360, 330); }
    &[data-tn-seat-id="229556"] { transform: matrix(1, 0, 0, 1, -380, 290); }

    &[data-tn-seat-id="229557"] { transform: matrix(1, 0, 0, 1, -380, 290); }
    &[data-tn-seat-id="229558"] { transform: matrix(1, 0, 0, 1, -390, 290); }
    &[data-tn-seat-id="229559"] { transform: matrix(1, 0, 0, 1, -400, 290); }
    &[data-tn-seat-id="229560"] { transform: matrix(1, 0, 0, 1, -410, 290); }
    &[data-tn-seat-id="229561"] { transform: matrix(1, 0, 0, 1, -420, 290); }
    &[data-tn-seat-id="229562"] { transform: matrix(1, 0, 0, 1, -430, 290); }
    &[data-tn-seat-id="229563"] { transform: matrix(1, 0, 0, 1, -440, 290); }
    &[data-tn-seat-id="229564"] { transform: matrix(1, 0, 0, 1, -450, 290); }
    &[data-tn-seat-id="229565"] { transform: matrix(1, 0, 0, 1, -460, 290); }
    &[data-tn-seat-id="229566"] { transform: matrix(1, 0, 0, 1, -470, 290); }
    &[data-tn-seat-id="229567"] { transform: matrix(1, 0, 0, 1, -480, 290); }
    &[data-tn-seat-id="229568"] { transform: matrix(1, 0, 0, 1, -490, 290); }
    &[data-tn-seat-id="229569"] { transform: matrix(1, 0, 0, 1, -500, 290); }
    &[data-tn-seat-id="229570"] { transform: matrix(1, 0, 0, 1, -510, 290); }
    &[data-tn-seat-id="229571"] { transform: matrix(1, 0, 0, 1, -520, 290); }
    &[data-tn-seat-id="229572"] { transform: matrix(1, 0, 0, 1, -530, 290); }
    &[data-tn-seat-id="229573"] { transform: matrix(1, 0, 0, 1, -540, 290); }
    &[data-tn-seat-id="229574"] { transform: matrix(1, 0, 0, 1, -550, 290); }
    &[data-tn-seat-id="229575"] { transform: matrix(1, 0, 0, 1, -560, 290); }
    &[data-tn-seat-id="229576"] { transform: matrix(1, 0, 0, 1, -570, 290); }
    &[data-tn-seat-id="229577"] { transform: matrix(1, 0, 0, 1, -575, 290); }
    &[data-tn-seat-id="229578"] { transform: matrix(1, 0, 0, 1, -580, 290); }
    &[data-tn-seat-id="229579"] { transform: matrix(1, 0, 0, 1, -585, 290); }
    &[data-tn-seat-id="229580"] { transform: matrix(1, 0, 0, 1, -590, 290); }
    &[data-tn-seat-id="229581"] { transform: matrix(1, 0, 0, 1, -595, 290); }
    &[data-tn-seat-id="229582"] { transform: matrix(1, 0, 0, 1, -600, 290); }
    &[data-tn-seat-id="229583"] { transform: matrix(1, 0, 0, 1, -605, 290); }
    &[data-tn-seat-id="229584"] { transform: matrix(1, 0, 0, 1, -610, 290); }

//Row 14
    &[data-tn-seat-id="229475"] { transform: matrix(1, 0, 0, 1, -490, 360); }
    &[data-tn-seat-id="229476"] { transform: matrix(1, 0, 0, 1, -510, 310); }
    &[data-tn-seat-id="229477"] { transform: matrix(1, 0, 0, 1, -530, 260); }
    &[data-tn-seat-id="229478"] { transform: matrix(1, 0, 0, 1, -530, 250); }
    &[data-tn-seat-id="229479"] { transform: matrix(1, 0, 0, 1, -540, 250); }
    &[data-tn-seat-id="229480"] { transform: matrix(1, 0, 0, 1, -550, 250); }
    &[data-tn-seat-id="229481"] { transform: matrix(1, 0, 0, 1, -560, 250); }
    &[data-tn-seat-id="229482"] { transform: matrix(1, 0, 0, 1, -570, 250); }
    &[data-tn-seat-id="229483"] { transform: matrix(1, 0, 0, 1, -580, 250); }
    &[data-tn-seat-id="229484"] { transform: matrix(1, 0, 0, 1, -590, 250); }
    &[data-tn-seat-id="229485"] { transform: matrix(1, 0, 0, 1, -600, 250); }
    &[data-tn-seat-id="229486"] { transform: matrix(1, 0, 0, 1, -610, 250); }
    &[data-tn-seat-id="229487"] { transform: matrix(1, 0, 0, 1, -620, 250); }
    &[data-tn-seat-id="229488"] { transform: matrix(1, 0, 0, 1, -630, 250); }
    &[data-tn-seat-id="229489"] { transform: matrix(1, 0, 0, 1, -640, 250); }
    &[data-tn-seat-id="229490"] { transform: matrix(1, 0, 0, 1, -650, 250); }
    &[data-tn-seat-id="229491"] { transform: matrix(1, 0, 0, 1, -660, 250); }
    &[data-tn-seat-id="229492"] { transform: matrix(1, 0, 0, 1, -670, 250); }
    &[data-tn-seat-id="229493"] { transform: matrix(1, 0, 0, 1, -680, 250); }

    //Row 15
    &[data-tn-seat-id="229405"] { transform: matrix(1, 0, 0, 1, -490, 210); }
    &[data-tn-seat-id="229406"] { transform: matrix(1, 0, 0, 1, -500, 210); }
    &[data-tn-seat-id="229407"] { transform: matrix(1, 0, 0, 1, -510, 210); }
    &[data-tn-seat-id="229408"] { transform: matrix(1, 0, 0, 1, -520, 210); }
    &[data-tn-seat-id="229409"] { transform: matrix(1, 0, 0, 1, -530, 210); }
    &[data-tn-seat-id="229410"] { transform: matrix(1, 0, 0, 1, -540, 210); }
    &[data-tn-seat-id="229411"] { transform: matrix(1, 0, 0, 1, -550, 210); }
    &[data-tn-seat-id="229412"] { transform: matrix(1, 0, 0, 1, -560, 210); }
}

.tn-syos-seat-map__seat {
    //SMAP ID 252 The Lyric Opera Map
    //Row 1
    &[data-tn-seat-id="269336"] { transform: matrix(1, 0, 0, 1, -90, 1020); }
    &[data-tn-seat-id="269337"] { transform: matrix(1, 0, 0, 1, -110, 970); }
    &[data-tn-seat-id="269338"] { transform: matrix(1, 0, 0, 1, -130, 920); }
    &[data-tn-seat-id="269339"] { transform: matrix(1, 0, 0, 1, -160, 870); }
    &[data-tn-seat-id="269340"] { transform: matrix(1, 0, 0, 1, -180, 820); }

    &[data-tn-seat-id="269341"] { transform: matrix(1, 0, 0, 1, -180, 790); }
    &[data-tn-seat-id="269342"] { transform: matrix(1, 0, 0, 1, -190, 790); }
    &[data-tn-seat-id="269343"] { transform: matrix(1, 0, 0, 1, -200, 790); }
    &[data-tn-seat-id="269344"] { transform: matrix(1, 0, 0, 1, -210, 790); }
    &[data-tn-seat-id="269345"] { transform: matrix(1, 0, 0, 1, -220, 790); }
    &[data-tn-seat-id="269346"] { transform: matrix(1, 0, 0, 1, -230, 790); }
    &[data-tn-seat-id="269347"] { transform: matrix(1, 0, 0, 1, -240, 790); }
    &[data-tn-seat-id="269348"] { transform: matrix(1, 0, 0, 1, -250, 790); }
    &[data-tn-seat-id="269349"] { transform: matrix(1, 0, 0, 1, -260, 790); }
    &[data-tn-seat-id="269350"] { transform: matrix(1, 0, 0, 1, -270, 790); }
    &[data-tn-seat-id="269351"] { transform: matrix(1, 0, 0, 1, -280, 790); }
    &[data-tn-seat-id="269352"] { transform: matrix(1, 0, 0, 1, -290, 790); }
    &[data-tn-seat-id="269353"] { transform: matrix(1, 0, 0, 1, -300, 790); }
    &[data-tn-seat-id="269354"] { transform: matrix(1, 0, 0, 1, -310, 790); }
    &[data-tn-seat-id="269355"] { transform: matrix(1, 0, 0, 1, -320, 790); }
    &[data-tn-seat-id="269356"] { transform: matrix(1, 0, 0, 1, -330, 790); }
    &[data-tn-seat-id="269357"] { transform: matrix(1, 0, 0, 1, -340, 790); }

    &[data-tn-seat-id="269358"] { transform: matrix(1, 0, 0, 1, -330, 820); }
    &[data-tn-seat-id="269359"] { transform: matrix(1, 0, 0, 1, -360, 870); }
    &[data-tn-seat-id="269360"] { transform: matrix(1, 0, 0, 1, -380, 920); }

    // Row 2
    &[data-tn-seat-id="269256"] { transform: matrix(1, 0, 0, 1, -140, 990); }
    &[data-tn-seat-id="269257"] { transform: matrix(1, 0, 0, 1, -160, 940); }
    &[data-tn-seat-id="269258"] { transform: matrix(1, 0, 0, 1, -180, 880); }
    &[data-tn-seat-id="269259"] { transform: matrix(1, 0, 0, 1, -200, 830); }
    &[data-tn-seat-id="269260"] { transform: matrix(1, 0, 0, 1, -220, 780); }

    &[data-tn-seat-id="269261"] { transform: matrix(1, 0, 0, 1, -220, 750); }
    &[data-tn-seat-id="269262"] { transform: matrix(1, 0, 0, 1, -230, 750); }
    &[data-tn-seat-id="269263"] { transform: matrix(1, 0, 0, 1, -240, 750); }
    &[data-tn-seat-id="269264"] { transform: matrix(1, 0, 0, 1, -250, 750); }
    &[data-tn-seat-id="269265"] { transform: matrix(1, 0, 0, 1, -260, 750); }
    &[data-tn-seat-id="269266"] { transform: matrix(1, 0, 0, 1, -270, 750); }
    &[data-tn-seat-id="269267"] { transform: matrix(1, 0, 0, 1, -280, 750); }
    &[data-tn-seat-id="269268"] { transform: matrix(1, 0, 0, 1, -290, 750); }
    &[data-tn-seat-id="269269"] { transform: matrix(1, 0, 0, 1, -300, 750); }
    &[data-tn-seat-id="269270"] { transform: matrix(1, 0, 0, 1, -310, 750); }
    &[data-tn-seat-id="269271"] { transform: matrix(1, 0, 0, 1, -320, 750); }
    &[data-tn-seat-id="269272"] { transform: matrix(1, 0, 0, 1, -330, 750); }
    &[data-tn-seat-id="269273"] { transform: matrix(1, 0, 0, 1, -340, 750); }
    &[data-tn-seat-id="269274"] { transform: matrix(1, 0, 0, 1, -350, 750); }
    &[data-tn-seat-id="269275"] { transform: matrix(1, 0, 0, 1, -360, 750); }
    &[data-tn-seat-id="269276"] { transform: matrix(1, 0, 0, 1, -370, 750); }
    &[data-tn-seat-id="269277"] { transform: matrix(1, 0, 0, 1, -380, 750); }
    &[data-tn-seat-id="269278"] { transform: matrix(1, 0, 0, 1, -390, 750); }

    //Row 3
    &[data-tn-seat-id="269174"] { transform: matrix(1, 0, 0, 1, -90, 980); }
    &[data-tn-seat-id="269175"] { transform: matrix(1, 0, 0, 1, -110, 930); }
    &[data-tn-seat-id="269176"] { transform: matrix(1, 0, 0, 1, -130, 880); }
    &[data-tn-seat-id="269177"] { transform: matrix(1, 0, 0, 1, -150, 830); }
    &[data-tn-seat-id="269178"] { transform: matrix(1, 0, 0, 1, -170, 780); }
    &[data-tn-seat-id="269179"] { transform: matrix(1, 0, 0, 1, -190, 730); }

    &[data-tn-seat-id="269180"] { transform: matrix(1, 0, 0, 1, -190, 710); }
    &[data-tn-seat-id="269181"] { transform: matrix(1, 0, 0, 1, -200, 710); }
    &[data-tn-seat-id="269182"] { transform: matrix(1, 0, 0, 1, -210, 710); }
    &[data-tn-seat-id="269183"] { transform: matrix(1, 0, 0, 1, -220, 710); }
    &[data-tn-seat-id="269184"] { transform: matrix(1, 0, 0, 1, -230, 710); }
    &[data-tn-seat-id="269185"] { transform: matrix(1, 0, 0, 1, -240, 710); }
    &[data-tn-seat-id="269186"] { transform: matrix(1, 0, 0, 1, -250, 710); }
    &[data-tn-seat-id="269187"] { transform: matrix(1, 0, 0, 1, -260, 710); }
    &[data-tn-seat-id="269188"] { transform: matrix(1, 0, 0, 1, -270, 710); }
    &[data-tn-seat-id="269189"] { transform: matrix(1, 0, 0, 1, -280, 710); }
    &[data-tn-seat-id="269190"] { transform: matrix(1, 0, 0, 1, -290, 710); }
    &[data-tn-seat-id="269191"] { transform: matrix(1, 0, 0, 1, -300, 710); }
    &[data-tn-seat-id="269192"] { transform: matrix(1, 0, 0, 1, -310, 710); }
    &[data-tn-seat-id="269193"] { transform: matrix(1, 0, 0, 1, -320, 710); }
    &[data-tn-seat-id="269194"] { transform: matrix(1, 0, 0, 1, -330, 710); }
    &[data-tn-seat-id="269195"] { transform: matrix(1, 0, 0, 1, -340, 710); }
    &[data-tn-seat-id="269196"] { transform: matrix(1, 0, 0, 1, -350, 710); }
    &[data-tn-seat-id="269197"] { transform: matrix(1, 0, 0, 1, -360, 710); }
    &[data-tn-seat-id="269198"] { transform: matrix(1, 0, 0, 1, -370, 710); }
    &[data-tn-seat-id="269199"] { transform: matrix(1, 0, 0, 1, -370, 750); }
    &[data-tn-seat-id="269200"] { transform: matrix(1, 0, 0, 1, -390, 800); }
    &[data-tn-seat-id="269201"] { transform: matrix(1, 0, 0, 1, -410, 850); }

    //Row 4
    &[data-tn-seat-id="269097"] { transform: matrix(1, 0, 0, 1, -240, 830); }
    &[data-tn-seat-id="269098"] { transform: matrix(1, 0, 0, 1, -260, 780); }
    &[data-tn-seat-id="269099"] { transform: matrix(1, 0, 0, 1, -280, 730); }
    &[data-tn-seat-id="269100"] { transform: matrix(1, 0, 0, 1, -300, 680); }
    &[data-tn-seat-id="269101"] { transform: matrix(1, 0, 0, 1, -300, 660); }
    &[data-tn-seat-id="269102"] { transform: matrix(1, 0, 0, 1, -310, 660); }
    &[data-tn-seat-id="269103"] { transform: matrix(1, 0, 0, 1, -320, 660); }
    &[data-tn-seat-id="269104"] { transform: matrix(1, 0, 0, 1, -330, 660); }
    &[data-tn-seat-id="269105"] { transform: matrix(1, 0, 0, 1, -340, 660); }
    &[data-tn-seat-id="269106"] { transform: matrix(1, 0, 0, 1, -350, 660); }
    &[data-tn-seat-id="269107"] { transform: matrix(1, 0, 0, 1, -360, 660); }
    &[data-tn-seat-id="269108"] { transform: matrix(1, 0, 0, 1, -370, 660); }
    &[data-tn-seat-id="269109"] { transform: matrix(1, 0, 0, 1, -380, 660); }
    &[data-tn-seat-id="269110"] { transform: matrix(1, 0, 0, 1, -390, 660); }
    &[data-tn-seat-id="269111"] { transform: matrix(1, 0, 0, 1, -400, 660); }
    &[data-tn-seat-id="269112"] { transform: matrix(1, 0, 0, 1, -410, 660); }
    &[data-tn-seat-id="269113"] { transform: matrix(1, 0, 0, 1, -420, 660); }
    &[data-tn-seat-id="269114"] { transform: matrix(1, 0, 0, 1, -430, 660); }
    &[data-tn-seat-id="269115"] { transform: matrix(1, 0, 0, 1, -440, 660); }
    &[data-tn-seat-id="269116"] { transform: matrix(1, 0, 0, 1, -450, 660); }
    &[data-tn-seat-id="269117"] { transform: matrix(1, 0, 0, 1, -460, 660); }

    //Row 5
    &[data-tn-seat-id="269015"] { transform: matrix(1, 0, 0, 1, -170, 830); }
    &[data-tn-seat-id="269016"] { transform: matrix(1, 0, 0, 1, -190, 780); }
    &[data-tn-seat-id="269017"] { transform: matrix(1, 0, 0, 1, -210, 730); }
    &[data-tn-seat-id="269018"] { transform: matrix(1, 0, 0, 1, -240, 680); }
    &[data-tn-seat-id="269017"] { transform: matrix(1, 0, 0, 1, -220, 730); }
    &[data-tn-seat-id="269016"] { transform: matrix(1, 0, 0, 1, -200, 780); }

    &[data-tn-seat-id="269019"] { transform: matrix(1, 0, 0, 1, -250, 630); }
    &[data-tn-seat-id="269020"] { transform: matrix(1, 0, 0, 1, -240, 620); }
    &[data-tn-seat-id="269021"] { transform: matrix(1, 0, 0, 1, -250, 620); }
    &[data-tn-seat-id="269022"] { transform: matrix(1, 0, 0, 1, -260, 620); }
    &[data-tn-seat-id="269023"] { transform: matrix(1, 0, 0, 1, -270, 620); }
    &[data-tn-seat-id="269024"] { transform: matrix(1, 0, 0, 1, -280, 620); }
    &[data-tn-seat-id="269025"] { transform: matrix(1, 0, 0, 1, -290, 620); }
    &[data-tn-seat-id="269026"] { transform: matrix(1, 0, 0, 1, -300, 620); }
    &[data-tn-seat-id="269027"] { transform: matrix(1, 0, 0, 1, -310, 620); }
    &[data-tn-seat-id="269028"] { transform: matrix(1, 0, 0, 1, -320, 620); }
    &[data-tn-seat-id="269029"] { transform: matrix(1, 0, 0, 1, -330, 620); }
    &[data-tn-seat-id="269030"] { transform: matrix(1, 0, 0, 1, -340, 620); }
    &[data-tn-seat-id="269031"] { transform: matrix(1, 0, 0, 1, -350, 620); }
    &[data-tn-seat-id="269032"] { transform: matrix(1, 0, 0, 1, -360, 620); }
    &[data-tn-seat-id="269033"] { transform: matrix(1, 0, 0, 1, -370, 620); }
    &[data-tn-seat-id="269034"] { transform: matrix(1, 0, 0, 1, -380, 620); }
    &[data-tn-seat-id="269035"] { transform: matrix(1, 0, 0, 1, -390, 620); }
    &[data-tn-seat-id="269036"] { transform: matrix(1, 0, 0, 1, -400, 620); }
    &[data-tn-seat-id="269037"] { transform: matrix(1, 0, 0, 1, -410, 620); }
    &[data-tn-seat-id="269038"] { transform: matrix(1, 0, 0, 1, -420, 620); }

    //Row 6
    &[data-tn-seat-id="268934"] { transform: matrix(1, 0, 0, 1, -180, 810); }
    &[data-tn-seat-id="268935"] { transform: matrix(1, 0, 0, 1, -210, 760); }
    &[data-tn-seat-id="268936"] { transform: matrix(1, 0, 0, 1, -230, 710); }
    &[data-tn-seat-id="268937"] { transform: matrix(1, 0, 0, 1, -250, 660); }
    &[data-tn-seat-id="268938"] { transform: matrix(1, 0, 0, 1, -270, 610); }
    &[data-tn-seat-id="268939"] { transform: matrix(1, 0, 0, 1, -270, 580); }

    &[data-tn-seat-id="268940"] { transform: matrix(1, 0, 0, 1, -270, 580); }
    &[data-tn-seat-id="268941"] { transform: matrix(1, 0, 0, 1, -280, 580); }
    &[data-tn-seat-id="268942"] { transform: matrix(1, 0, 0, 1, -290, 580); }
    &[data-tn-seat-id="268943"] { transform: matrix(1, 0, 0, 1, -300, 580); }
    &[data-tn-seat-id="268944"] { transform: matrix(1, 0, 0, 1, -310, 580); }
    &[data-tn-seat-id="268945"] { transform: matrix(1, 0, 0, 1, -320, 580); }
    &[data-tn-seat-id="268946"] { transform: matrix(1, 0, 0, 1, -330, 580); }
    &[data-tn-seat-id="268947"] { transform: matrix(1, 0, 0, 1, -340, 580); }
    &[data-tn-seat-id="268948"] { transform: matrix(1, 0, 0, 1, -350, 580); }
    &[data-tn-seat-id="268949"] { transform: matrix(1, 0, 0, 1, -360, 580); }
    &[data-tn-seat-id="268950"] { transform: matrix(1, 0, 0, 1, -370, 580); }
    &[data-tn-seat-id="268951"] { transform: matrix(1, 0, 0, 1, -380, 580); }
    &[data-tn-seat-id="268952"] { transform: matrix(1, 0, 0, 1, -390, 580); }
    &[data-tn-seat-id="268953"] { transform: matrix(1, 0, 0, 1, -400, 580); }
    &[data-tn-seat-id="268954"] { transform: matrix(1, 0, 0, 1, -410, 580); }
    &[data-tn-seat-id="268955"] { transform: matrix(1, 0, 0, 1, -420, 580); }
    &[data-tn-seat-id="268956"] { transform: matrix(1, 0, 0, 1, -430, 580); }
    &[data-tn-seat-id="268957"] { transform: matrix(1, 0, 0, 1, -440, 580); }
    &[data-tn-seat-id="268958"] { transform: matrix(1, 0, 0, 1, -450, 580); }
    &[data-tn-seat-id="268959"] { transform: matrix(1, 0, 0, 1, -460, 580); }

    //Row 7
    &[data-tn-seat-id="268853"] { transform: matrix(1, 0, 0, 1, -190, 800); }
    &[data-tn-seat-id="268854"] { transform: matrix(1, 0, 0, 1, -220, 750); }
    &[data-tn-seat-id="268855"] { transform: matrix(1, 0, 0, 1, -240, 700); }
    &[data-tn-seat-id="268856"] { transform: matrix(1, 0, 0, 1, -260, 650); }
    &[data-tn-seat-id="268857"] { transform: matrix(1, 0, 0, 1, -280, 600); }
    &[data-tn-seat-id="268858"] { transform: matrix(1, 0, 0, 1, -300, 560); }
    &[data-tn-seat-id="268859"] { transform: matrix(1, 0, 0, 1, -300, 540); }
    &[data-tn-seat-id="268860"] { transform: matrix(1, 0, 0, 1, -290, 540); }
    &[data-tn-seat-id="268861"] { transform: matrix(1, 0, 0, 1, -300, 540); }
    &[data-tn-seat-id="268862"] { transform: matrix(1, 0, 0, 1, -310, 540); }
    &[data-tn-seat-id="268863"] { transform: matrix(1, 0, 0, 1, -320, 540); }
    &[data-tn-seat-id="268864"] { transform: matrix(1, 0, 0, 1, -330, 540); }
    &[data-tn-seat-id="268865"] { transform: matrix(1, 0, 0, 1, -340, 540); }
    &[data-tn-seat-id="268866"] { transform: matrix(1, 0, 0, 1, -350, 540); }
    &[data-tn-seat-id="268867"] { transform: matrix(1, 0, 0, 1, -360, 540); }
    &[data-tn-seat-id="268868"] { transform: matrix(1, 0, 0, 1, -370, 540); }
    &[data-tn-seat-id="268869"] { transform: matrix(1, 0, 0, 1, -380, 540); }
    &[data-tn-seat-id="268870"] { transform: matrix(1, 0, 0, 1, -390, 540); }
    &[data-tn-seat-id="268871"] { transform: matrix(1, 0, 0, 1, -400, 540); }
    &[data-tn-seat-id="268872"] { transform: matrix(1, 0, 0, 1, -410, 540); }
    &[data-tn-seat-id="268873"] { transform: matrix(1, 0, 0, 1, -420, 540); }
    &[data-tn-seat-id="268874"] { transform: matrix(1, 0, 0, 1, -430, 540); }
    &[data-tn-seat-id="268875"] { transform: matrix(1, 0, 0, 1, -440, 540); }
    &[data-tn-seat-id="268876"] { transform: matrix(1, 0, 0, 1, -450, 540); }
    &[data-tn-seat-id="268877"] { transform: matrix(1, 0, 0, 1, -460, 540); }
    &[data-tn-seat-id="268878"] { transform: matrix(1, 0, 0, 1, -470, 540); }
    &[data-tn-seat-id="268879"] { transform: matrix(1, 0, 0, 1, -480, 540); }
    &[data-tn-seat-id="268880"] { transform: matrix(1, 0, 0, 1, -490, 540); }

    //Row 8
    &[data-tn-seat-id="268772"] { transform: matrix(1, 0, 0, 1, -200, 800); }
    &[data-tn-seat-id="268773"] { transform: matrix(1, 0, 0, 1, -230, 750); }
    &[data-tn-seat-id="268774"] { transform: matrix(1, 0, 0, 1, -260, 700); }
    &[data-tn-seat-id="268775"] { transform: matrix(1, 0, 0, 1, -280, 650); }
    &[data-tn-seat-id="268774"] { transform: matrix(1, 0, 0, 1, -250, 710); }
    &[data-tn-seat-id="268773"] { transform: matrix(1, 0, 0, 1, -220, 750); }
    &[data-tn-seat-id="268774"] { transform: matrix(1, 0, 0, 1, -240, 700); }
    &[data-tn-seat-id="268775"] { transform: matrix(1, 0, 0, 1, -260, 650); }
    &[data-tn-seat-id="268776"] { transform: matrix(1, 0, 0, 1, -280, 600); }
    &[data-tn-seat-id="268777"] { transform: matrix(1, 0, 0, 1, -300, 550); }
    &[data-tn-seat-id="268778"] { transform: matrix(1, 0, 0, 1, -300, 510); }
    &[data-tn-seat-id="268779"] { transform: matrix(1, 0, 0, 1, -290, 500); }
    &[data-tn-seat-id="268780"] { transform: matrix(1, 0, 0, 1, -300, 500); }
    &[data-tn-seat-id="268781"] { transform: matrix(1, 0, 0, 1, -310, 500); }
    &[data-tn-seat-id="268782"] { transform: matrix(1, 0, 0, 1, -320, 500); }
    &[data-tn-seat-id="268783"] { transform: matrix(1, 0, 0, 1, -330, 500); }
    &[data-tn-seat-id="268784"] { transform: matrix(1, 0, 0, 1, -340, 500); }
    &[data-tn-seat-id="268785"] { transform: matrix(1, 0, 0, 1, -350, 500); }
    &[data-tn-seat-id="268786"] { transform: matrix(1, 0, 0, 1, -360, 500); }
    &[data-tn-seat-id="268787"] { transform: matrix(1, 0, 0, 1, -370, 500); }
    &[data-tn-seat-id="268788"] { transform: matrix(1, 0, 0, 1, -380, 500); }
    &[data-tn-seat-id="268789"] { transform: matrix(1, 0, 0, 1, -390, 500); }
    &[data-tn-seat-id="268790"] { transform: matrix(1, 0, 0, 1, -400, 500); }
    &[data-tn-seat-id="268791"] { transform: matrix(1, 0, 0, 1, -410, 500); }
    &[data-tn-seat-id="268792"] { transform: matrix(1, 0, 0, 1, -420, 500); }
    &[data-tn-seat-id="268793"] { transform: matrix(1, 0, 0, 1, -430, 500); }
    &[data-tn-seat-id="268794"] { transform: matrix(1, 0, 0, 1, -440, 500); }
    &[data-tn-seat-id="268795"] { transform: matrix(1, 0, 0, 1, -450, 500); }
    &[data-tn-seat-id="268796"] { transform: matrix(1, 0, 0, 1, -460, 500); }
    &[data-tn-seat-id="268797"] { transform: matrix(1, 0, 0, 1, -470, 500); }
    &[data-tn-seat-id="268798"] { transform: matrix(1, 0, 0, 1, -480, 500); }
    &[data-tn-seat-id="268799"] { transform: matrix(1, 0, 0, 1, -490, 500); }
    &[data-tn-seat-id="268800"] { transform: matrix(1, 0, 0, 1, -500, 500); }
    &[data-tn-seat-id="268801"] { transform: matrix(1, 0, 0, 1, -510, 500); }

    //Row 9
    &[data-tn-seat-id="268690"] { transform: matrix(1, 0, 0, 1, -140, 790); }
    &[data-tn-seat-id="268691"] { transform: matrix(1, 0, 0, 1, -160, 740); }
    &[data-tn-seat-id="268692"] { transform: matrix(1, 0, 0, 1, -180, 690); }
    &[data-tn-seat-id="268693"] { transform: matrix(1, 0, 0, 1, -210, 640); }
    &[data-tn-seat-id="268694"] { transform: matrix(1, 0, 0, 1, -230, 590); }
    &[data-tn-seat-id="268695"] { transform: matrix(1, 0, 0, 1, -250, 540); }
    &[data-tn-seat-id="268696"] { transform: matrix(1, 0, 0, 1, -270, 490); }
    &[data-tn-seat-id="268697"] { transform: matrix(1, 0, 0, 1, -280, 460); }
    &[data-tn-seat-id="268698"] { transform: matrix(1, 0, 0, 1, -290, 450); }
    &[data-tn-seat-id="268699"] { transform: matrix(1, 0, 0, 1, -300, 450); }
    &[data-tn-seat-id="268700"] { transform: matrix(1, 0, 0, 1, -310, 450); }
    &[data-tn-seat-id="268701"] { transform: matrix(1, 0, 0, 1, -320, 450); }
    &[data-tn-seat-id="268702"] { transform: matrix(1, 0, 0, 1, -330, 450); }
    &[data-tn-seat-id="268703"] { transform: matrix(1, 0, 0, 1, -340, 450); }
    &[data-tn-seat-id="268704"] { transform: matrix(1, 0, 0, 1, -350, 450); }
    &[data-tn-seat-id="268705"] { transform: matrix(1, 0, 0, 1, -360, 450); }
    &[data-tn-seat-id="268706"] { transform: matrix(1, 0, 0, 1, -370, 450); }
    &[data-tn-seat-id="268707"] { transform: matrix(1, 0, 0, 1, -380, 450); }
    &[data-tn-seat-id="268708"] { transform: matrix(1, 0, 0, 1, -390, 450); }
    &[data-tn-seat-id="268709"] { transform: matrix(1, 0, 0, 1, -400, 450); }
    &[data-tn-seat-id="268710"] { transform: matrix(1, 0, 0, 1, -410, 450); }
    &[data-tn-seat-id="268711"] { transform: matrix(1, 0, 0, 1, -420, 450); }
    &[data-tn-seat-id="268712"] { transform: matrix(1, 0, 0, 1, -430, 450); }
    &[data-tn-seat-id="268713"] { transform: matrix(1, 0, 0, 1, -440, 450); }
    &[data-tn-seat-id="268714"] { transform: matrix(1, 0, 0, 1, -450, 450); }
    &[data-tn-seat-id="268715"] { transform: matrix(1, 0, 0, 1, -460, 450); }
    &[data-tn-seat-id="268716"] { transform: matrix(1, 0, 0, 1, -470, 450); }
    &[data-tn-seat-id="268717"] { transform: matrix(1, 0, 0, 1, -480, 450); }
    &[data-tn-seat-id="268718"] { transform: matrix(1, 0, 0, 1, -490, 450); }
    &[data-tn-seat-id="268719"] { transform: matrix(1, 0, 0, 1, -500, 450); }
    &[data-tn-seat-id="268720"] { transform: matrix(1, 0, 0, 1, -510, 450); }
    &[data-tn-seat-id="268721"] { transform: matrix(1, 0, 0, 1, -520, 450); }
    &[data-tn-seat-id="268722"] { transform: matrix(1, 0, 0, 1, -530, 450); }

    //Path
    &[data-tn-seat-id="268923"] { transform: matrix(1, 0, 0, 1, 50, 710); }
    &[data-tn-seat-id="268924"] { transform: matrix(1, 0, 0, 1, 30, 660); }

    //Row 10
    &[data-tn-seat-id="268609"] { transform: matrix(1, 0, 0, 1, -150, 790); }
    &[data-tn-seat-id="268610"] { transform: matrix(1, 0, 0, 1, -170, 740); }
    &[data-tn-seat-id="268611"] { transform: matrix(1, 0, 0, 1, -190, 690); }
    &[data-tn-seat-id="268612"] { transform: matrix(1, 0, 0, 1, -210, 640); }
    &[data-tn-seat-id="268613"] { transform: matrix(1, 0, 0, 1, -230, 590); }
    &[data-tn-seat-id="268614"] { transform: matrix(1, 0, 0, 1, -250, 540); }
    &[data-tn-seat-id="268615"] { transform: matrix(1, 0, 0, 1, -270, 490); }
    &[data-tn-seat-id="268616"] { transform: matrix(1, 0, 0, 1, -290, 440); }

    &[data-tn-seat-id="268617"] { transform: matrix(1, 0, 0, 1, -300, 410); }
    &[data-tn-seat-id="268618"] { transform: matrix(1, 0, 0, 1, -310, 410); }
    &[data-tn-seat-id="268619"] { transform: matrix(1, 0, 0, 1, -320, 410); }
    &[data-tn-seat-id="268620"] { transform: matrix(1, 0, 0, 1, -330, 410); }
    &[data-tn-seat-id="268621"] { transform: matrix(1, 0, 0, 1, -340, 410); }
    &[data-tn-seat-id="268622"] { transform: matrix(1, 0, 0, 1, -350, 410); }
    &[data-tn-seat-id="268623"] { transform: matrix(1, 0, 0, 1, -360, 410); }
    &[data-tn-seat-id="268624"] { transform: matrix(1, 0, 0, 1, -370, 410); }
    &[data-tn-seat-id="268625"] { transform: matrix(1, 0, 0, 1, -380, 410); }
    &[data-tn-seat-id="268626"] { transform: matrix(1, 0, 0, 1, -390, 410); }
    &[data-tn-seat-id="268627"] { transform: matrix(1, 0, 0, 1, -400, 410); }
    &[data-tn-seat-id="268628"] { transform: matrix(1, 0, 0, 1, -410, 410); }
    &[data-tn-seat-id="268629"] { transform: matrix(1, 0, 0, 1, -420, 410); }
    &[data-tn-seat-id="268630"] { transform: matrix(1, 0, 0, 1, -430, 410); }
    &[data-tn-seat-id="268631"] { transform: matrix(1, 0, 0, 1, -440, 410); }
    &[data-tn-seat-id="268632"] { transform: matrix(1, 0, 0, 1, -450, 410); }
    &[data-tn-seat-id="268633"] { transform: matrix(1, 0, 0, 1, -460, 410); }
    &[data-tn-seat-id="268634"] { transform: matrix(1, 0, 0, 1, -470, 410); }
    &[data-tn-seat-id="268635"] { transform: matrix(1, 0, 0, 1, -480, 410); }
    &[data-tn-seat-id="268636"] { transform: matrix(1, 0, 0, 1, -490, 410); }
    &[data-tn-seat-id="268637"] { transform: matrix(1, 0, 0, 1, -500, 410); }
    &[data-tn-seat-id="268638"] { transform: matrix(1, 0, 0, 1, -510, 410); }
    &[data-tn-seat-id="268639"] { transform: matrix(1, 0, 0, 1, -520, 410); }
    &[data-tn-seat-id="268640"] { transform: matrix(1, 0, 0, 1, -530, 410); }
    &[data-tn-seat-id="268641"] { transform: matrix(1, 0, 0, 1, -540, 410); }
    &[data-tn-seat-id="268642"] { transform: matrix(1, 0, 0, 1, -550, 410); }
    &[data-tn-seat-id="268643"] { transform: matrix(1, 0, 0, 1, -560, 410); }

    //Row 11
    &[data-tn-seat-id="268528"] { transform: matrix(1, 0, 0, 1, -160, 770); }
    &[data-tn-seat-id="268529"] { transform: matrix(1, 0, 0, 1, -180, 720); }
    &[data-tn-seat-id="268530"] { transform: matrix(1, 0, 0, 1, -210, 670); }
    &[data-tn-seat-id="268531"] { transform: matrix(1, 0, 0, 1, -230, 620); }
    &[data-tn-seat-id="268532"] { transform: matrix(1, 0, 0, 1, -260, 570); }
    &[data-tn-seat-id="268533"] { transform: matrix(1, 0, 0, 1, -280, 530); }
    &[data-tn-seat-id="268532"] { transform: matrix(1, 0, 0, 1, -250, 570); }
    &[data-tn-seat-id="268533"] { transform: matrix(1, 0, 0, 1, -270, 520); }
    &[data-tn-seat-id="268534"] { transform: matrix(1, 0, 0, 1, -290, 470); }
    &[data-tn-seat-id="268535"] { transform: matrix(1, 0, 0, 1, -310, 420); }
    &[data-tn-seat-id="268536"] { transform: matrix(1, 0, 0, 1, -330, 370); }
    &[data-tn-seat-id="268537"] { transform: matrix(1, 0, 0, 1, -340, 360); }
    &[data-tn-seat-id="268538"] { transform: matrix(1, 0, 0, 1, -350, 360); }
    &[data-tn-seat-id="268539"] { transform: matrix(1, 0, 0, 1, -360, 360); }
    &[data-tn-seat-id="268540"] { transform: matrix(1, 0, 0, 1, -370, 360); }
    &[data-tn-seat-id="268541"] { transform: matrix(1, 0, 0, 1, -380, 360); }
    &[data-tn-seat-id="268542"] { transform: matrix(1, 0, 0, 1, -390, 360); }
    &[data-tn-seat-id="268543"] { transform: matrix(1, 0, 0, 1, -400, 360); }
    &[data-tn-seat-id="268544"] { transform: matrix(1, 0, 0, 1, -410, 360); }
    &[data-tn-seat-id="268545"] { transform: matrix(1, 0, 0, 1, -420, 360); }
    &[data-tn-seat-id="268546"] { transform: matrix(1, 0, 0, 1, -430, 360); }
    &[data-tn-seat-id="268547"] { transform: matrix(1, 0, 0, 1, -440, 360); }
    &[data-tn-seat-id="268548"] { transform: matrix(1, 0, 0, 1, -450, 360); }
    &[data-tn-seat-id="268549"] { transform: matrix(1, 0, 0, 1, -460, 360); }
    &[data-tn-seat-id="268550"] { transform: matrix(1, 0, 0, 1, -470, 360); }
    &[data-tn-seat-id="268551"] { transform: matrix(1, 0, 0, 1, -480, 360); }
    &[data-tn-seat-id="268552"] { transform: matrix(1, 0, 0, 1, -490, 360); }
    &[data-tn-seat-id="268553"] { transform: matrix(1, 0, 0, 1, -500, 360); }
    &[data-tn-seat-id="268554"] { transform: matrix(1, 0, 0, 1, -510, 360); }
    &[data-tn-seat-id="268555"] { transform: matrix(1, 0, 0, 1, -520, 360); }
    &[data-tn-seat-id="268556"] { transform: matrix(1, 0, 0, 1, -530, 360); }
    &[data-tn-seat-id="268557"] { transform: matrix(1, 0, 0, 1, -540, 360); }
    &[data-tn-seat-id="268558"] { transform: matrix(1, 0, 0, 1, -550, 360); }
    &[data-tn-seat-id="268559"] { transform: matrix(1, 0, 0, 1, -560, 360); }
    &[data-tn-seat-id="268560"] { transform: matrix(1, 0, 0, 1, -570, 360); }
    &[data-tn-seat-id="268561"] { transform: matrix(1, 0, 0, 1, -580, 360); }
    &[data-tn-seat-id="268562"] { transform: matrix(1, 0, 0, 1, -590, 360); }
    &[data-tn-seat-id="268563"] { transform: matrix(1, 0, 0, 1, -600, 360); }
    &[data-tn-seat-id="268564"] { transform: matrix(1, 0, 0, 1, -610, 360); }

    //Row 12
    &[data-tn-seat-id="268447"] { transform: matrix(1, 0, 0, 1, -130, 720); }
    &[data-tn-seat-id="268448"] { transform: matrix(1, 0, 0, 1, -150, 670); }
    &[data-tn-seat-id="268449"] { transform: matrix(1, 0, 0, 1, -170, 620); }
    &[data-tn-seat-id="268450"] { transform: matrix(1, 0, 0, 1, -190, 570); }
    &[data-tn-seat-id="268451"] { transform: matrix(1, 0, 0, 1, -210, 520); }
    &[data-tn-seat-id="268452"] { transform: matrix(1, 0, 0, 1, -230, 470); }
    &[data-tn-seat-id="268453"] { transform: matrix(1, 0, 0, 1, -250, 420); }
    &[data-tn-seat-id="268454"] { transform: matrix(1, 0, 0, 1, -270, 370); }
    &[data-tn-seat-id="268455"] { transform: matrix(1, 0, 0, 1, -290, 330); }
    &[data-tn-seat-id="268456"] { transform: matrix(1, 0, 0, 1, -300, 320); }
    &[data-tn-seat-id="268457"] { transform: matrix(1, 0, 0, 1, -310, 320); }
    &[data-tn-seat-id="268458"] { transform: matrix(1, 0, 0, 1, -320, 320); }
    &[data-tn-seat-id="268459"] { transform: matrix(1, 0, 0, 1, -330, 320); }
    &[data-tn-seat-id="268460"] { transform: matrix(1, 0, 0, 1, -340, 320); }
    &[data-tn-seat-id="268461"] { transform: matrix(1, 0, 0, 1, -350, 320); }
    &[data-tn-seat-id="268462"] { transform: matrix(1, 0, 0, 1, -360, 320); }
    &[data-tn-seat-id="268463"] { transform: matrix(1, 0, 0, 1, -370, 320); }
    &[data-tn-seat-id="268464"] { transform: matrix(1, 0, 0, 1, -380, 320); }
    &[data-tn-seat-id="268465"] { transform: matrix(1, 0, 0, 1, -390, 320); }
    &[data-tn-seat-id="268466"] { transform: matrix(1, 0, 0, 1, -400, 320); }
    &[data-tn-seat-id="268467"] { transform: matrix(1, 0, 0, 1, -410, 320); }
    &[data-tn-seat-id="268468"] { transform: matrix(1, 0, 0, 1, -420, 320); }
    &[data-tn-seat-id="268469"] { transform: matrix(1, 0, 0, 1, -430, 320); }
    &[data-tn-seat-id="268470"] { transform: matrix(1, 0, 0, 1, -440, 320); }
    &[data-tn-seat-id="268471"] { transform: matrix(1, 0, 0, 1, -450, 320); }
    &[data-tn-seat-id="268472"] { transform: matrix(1, 0, 0, 1, -460, 320); }
    &[data-tn-seat-id="268473"] { transform: matrix(1, 0, 0, 1, -470, 320); }
    &[data-tn-seat-id="268474"] { transform: matrix(1, 0, 0, 1, -480, 320); }
    &[data-tn-seat-id="268475"] { transform: matrix(1, 0, 0, 1, -490, 320); }
    &[data-tn-seat-id="268476"] { transform: matrix(1, 0, 0, 1, -500, 320); }
    &[data-tn-seat-id="268477"] { transform: matrix(1, 0, 0, 1, -510, 320); }
    &[data-tn-seat-id="268478"] { transform: matrix(1, 0, 0, 1, -520, 320); }
    &[data-tn-seat-id="268479"] { transform: matrix(1, 0, 0, 1, -530, 320); }
    &[data-tn-seat-id="268480"] { transform: matrix(1, 0, 0, 1, -540, 320); }
    &[data-tn-seat-id="268481"] { transform: matrix(1, 0, 0, 1, -550, 320); }
    &[data-tn-seat-id="268482"] { transform: matrix(1, 0, 0, 1, -560, 320); }

    //Row 13
    &[data-tn-seat-id="268368"] { transform: matrix(1, 0, 0, 1, -220, 660); }
    &[data-tn-seat-id="268369"] { transform: matrix(1, 0, 0, 1, -240, 610); }
    &[data-tn-seat-id="268370"] { transform: matrix(1, 0, 0, 1, -270, 560); }

    &[data-tn-seat-id="268373"] { transform: matrix(1, 0, 0, 1, -320, 420); }
    &[data-tn-seat-id="268374"] { transform: matrix(1, 0, 0, 1, -340, 370); }
    &[data-tn-seat-id="268375"] { transform: matrix(1, 0, 0, 1, -360, 330); }
    &[data-tn-seat-id="268376"] { transform: matrix(1, 0, 0, 1, -380, 290); }

    &[data-tn-seat-id="268377"] { transform: matrix(1, 0, 0, 1, -380, 290); }
    &[data-tn-seat-id="268378"] { transform: matrix(1, 0, 0, 1, -390, 290); }
    &[data-tn-seat-id="268379"] { transform: matrix(1, 0, 0, 1, -400, 290); }
    &[data-tn-seat-id="268380"] { transform: matrix(1, 0, 0, 1, -410, 290); }
    &[data-tn-seat-id="268381"] { transform: matrix(1, 0, 0, 1, -420, 290); }
    &[data-tn-seat-id="268382"] { transform: matrix(1, 0, 0, 1, -430, 290); }
    &[data-tn-seat-id="268383"] { transform: matrix(1, 0, 0, 1, -440, 290); }
    &[data-tn-seat-id="268384"] { transform: matrix(1, 0, 0, 1, -450, 290); }
    &[data-tn-seat-id="268385"] { transform: matrix(1, 0, 0, 1, -460, 290); }
    &[data-tn-seat-id="268386"] { transform: matrix(1, 0, 0, 1, -470, 290); }
    &[data-tn-seat-id="268387"] { transform: matrix(1, 0, 0, 1, -480, 290); }
    &[data-tn-seat-id="268388"] { transform: matrix(1, 0, 0, 1, -490, 290); }
    &[data-tn-seat-id="268389"] { transform: matrix(1, 0, 0, 1, -500, 290); }
    &[data-tn-seat-id="268390"] { transform: matrix(1, 0, 0, 1, -510, 290); }
    &[data-tn-seat-id="268391"] { transform: matrix(1, 0, 0, 1, -520, 290); }
    &[data-tn-seat-id="268392"] { transform: matrix(1, 0, 0, 1, -530, 290); }
    &[data-tn-seat-id="268393"] { transform: matrix(1, 0, 0, 1, -540, 290); }
    &[data-tn-seat-id="268394"] { transform: matrix(1, 0, 0, 1, -550, 290); }
    &[data-tn-seat-id="268395"] { transform: matrix(1, 0, 0, 1, -560, 290); }
    &[data-tn-seat-id="268396"] { transform: matrix(1, 0, 0, 1, -570, 290); }
    &[data-tn-seat-id="268397"] { transform: matrix(1, 0, 0, 1, -575, 290); }
    &[data-tn-seat-id="268398"] { transform: matrix(1, 0, 0, 1, -580, 290); }
    &[data-tn-seat-id="268399"] { transform: matrix(1, 0, 0, 1, -585, 290); }
    &[data-tn-seat-id="268400"] { transform: matrix(1, 0, 0, 1, -590, 290); }
    &[data-tn-seat-id="268401"] { transform: matrix(1, 0, 0, 1, -595, 290); }
    &[data-tn-seat-id="268402"] { transform: matrix(1, 0, 0, 1, -600, 290); }
    &[data-tn-seat-id="268403"] { transform: matrix(1, 0, 0, 1, -605, 290); }
    &[data-tn-seat-id="268404"] { transform: matrix(1, 0, 0, 1, -610, 290); }

    //Row 14
    &[data-tn-seat-id="268295"] { transform: matrix(1, 0, 0, 1, -490, 360); }
    &[data-tn-seat-id="268296"] { transform: matrix(1, 0, 0, 1, -510, 310); }
    &[data-tn-seat-id="268297"] { transform: matrix(1, 0, 0, 1, -530, 260); }
    &[data-tn-seat-id="268298"] { transform: matrix(1, 0, 0, 1, -530, 250); }
    &[data-tn-seat-id="268299"] { transform: matrix(1, 0, 0, 1, -540, 250); }
    &[data-tn-seat-id="268300"] { transform: matrix(1, 0, 0, 1, -550, 250); }
    &[data-tn-seat-id="268301"] { transform: matrix(1, 0, 0, 1, -560, 250); }
    &[data-tn-seat-id="268302"] { transform: matrix(1, 0, 0, 1, -570, 250); }
    &[data-tn-seat-id="268303"] { transform: matrix(1, 0, 0, 1, -580, 250); }
    &[data-tn-seat-id="268304"] { transform: matrix(1, 0, 0, 1, -590, 250); }
    &[data-tn-seat-id="268305"] { transform: matrix(1, 0, 0, 1, -600, 250); }
    &[data-tn-seat-id="268306"] { transform: matrix(1, 0, 0, 1, -610, 250); }
    &[data-tn-seat-id="268307"] { transform: matrix(1, 0, 0, 1, -620, 250); }
    &[data-tn-seat-id="268308"] { transform: matrix(1, 0, 0, 1, -630, 250); }
    &[data-tn-seat-id="268309"] { transform: matrix(1, 0, 0, 1, -640, 250); }
    &[data-tn-seat-id="268310"] { transform: matrix(1, 0, 0, 1, -650, 250); }
    &[data-tn-seat-id="268311"] { transform: matrix(1, 0, 0, 1, -660, 250); }
    &[data-tn-seat-id="268312"] { transform: matrix(1, 0, 0, 1, -670, 250); }
    &[data-tn-seat-id="268313"] { transform: matrix(1, 0, 0, 1, -680, 250); }
    &[data-tn-seat-id="268314"] { transform: matrix(1, 0, 0, 1, -690, 250); }
}

}
