@supports not (-ms-high-contrast: none) {
   /* Non-IE styles here */

.tn-syos-seat-map__seat {
    //balcony
    &[data-tn-seat-id="232312"] { transform: matrix(1, 0, 0, 1, -650, 530); }
    &[data-tn-seat-id="232313"] { transform: matrix(1, 0, 0, 1, -670, 580); }
    &[data-tn-seat-id="232314"] { transform: matrix(1, 0, 0, 1, -680, 640); }
    &[data-tn-seat-id="232315"] { transform: matrix(1, 0, 0, 1, -690, 700); }
    &[data-tn-seat-id="232232"] { transform: matrix(1, 0, 0, 1, -610, 450); }
    &[data-tn-seat-id="232233"] { transform: matrix(1, 0, 0, 1, -630, 500); }
    &[data-tn-seat-id="232234"] { transform: matrix(1, 0, 0, 1, -640, 560); }
    &[data-tn-seat-id="232235"] { transform: matrix(1, 0, 0, 1, -650, 610); }
    &[data-tn-seat-id="232236"] { transform: matrix(1, 0, 0, 1, -670, 670); }
    &[data-tn-seat-id="232235"] { transform: matrix(1, 0, 0, 1, -650, 620); }
    &[data-tn-seat-id="232152"] { transform: matrix(1, 0, 0, 1, -560, 390); }
    &[data-tn-seat-id="232153"] { transform: matrix(1, 0, 0, 1, -580, 440); }
    &[data-tn-seat-id="232154"] { transform: matrix(1, 0, 0, 1, -600, 490); }
    &[data-tn-seat-id="232155"] { transform: matrix(1, 0, 0, 1, -610, 550); }
    &[data-tn-seat-id="232156"] { transform: matrix(1, 0, 0, 1, -620, 610); }
    &[data-tn-seat-id="232157"] { transform: matrix(1, 0, 0, 1, -640, 660); }
    &[data-tn-seat-id="231844"] { transform: matrix(1, 0, 0, 1, -810, 1040); }
    &[data-tn-seat-id="231845"] { transform: matrix(1, 0, 0, 1, -820, 1120); }
    &[data-tn-seat-id="232072"] { transform: matrix(1, 0, 0, 1, -520, 330); }
    &[data-tn-seat-id="232073"] { transform: matrix(1, 0, 0, 1, -540, 380); }
    &[data-tn-seat-id="232074"] { transform: matrix(1, 0, 0, 1, -560, 430); }
    &[data-tn-seat-id="232075"] { transform: matrix(1, 0, 0, 1, -580, 480); }
    &[data-tn-seat-id="232076"] { transform: matrix(1, 0, 0, 1, -600, 530); }
    &[data-tn-seat-id="232077"] { transform: matrix(1, 0, 0, 1, -620, 580); }
    &[data-tn-seat-id="232078"] { transform: matrix(1, 0, 0, 1, -640, 630); }
    &[data-tn-seat-id="231992"] { transform: matrix(1, 0, 0, 1, -460, 270); }
    &[data-tn-seat-id="231993"] { transform: matrix(1, 0, 0, 1, -480, 320); }
    &[data-tn-seat-id="231994"] { transform: matrix(1, 0, 0, 1, -500, 370); }
    &[data-tn-seat-id="231995"] { transform: matrix(1, 0, 0, 1, -520, 420); }
    &[data-tn-seat-id="231996"] { transform: matrix(1, 0, 0, 1, -540, 470); }
    &[data-tn-seat-id="231997"] { transform: matrix(1, 0, 0, 1, -560, 520); }
    &[data-tn-seat-id="231998"] { transform: matrix(1, 0, 0, 1, -580, 570); }
    &[data-tn-seat-id="232078"] { transform: matrix(1, 0, 0, 1, -630, 630); }
    &[data-tn-seat-id="231912"] { transform: matrix(1, 0, 0, 1, -410, 210); }
    &[data-tn-seat-id="231913"] { transform: matrix(1, 0, 0, 1, -430, 260); }
    &[data-tn-seat-id="231914"] { transform: matrix(1, 0, 0, 1, -450, 310); }
    &[data-tn-seat-id="231915"] { transform: matrix(1, 0, 0, 1, -470, 360); }
    &[data-tn-seat-id="231916"] { transform: matrix(1, 0, 0, 1, -490, 410); }
    &[data-tn-seat-id="231917"] { transform: matrix(1, 0, 0, 1, -510, 460); }
    &[data-tn-seat-id="231918"] { transform: matrix(1, 0, 0, 1, -530, 510); }

    // ROW 1
    // &[data-tn-seat-id="232262"] { transform: matrix(1, 0, 0, 1, 590, 820); }
    // &[data-tn-seat-id="232263"] { transform: matrix(1, 0, 0, 1, 580, 760); }
    // &[data-tn-seat-id="232184"] { transform: matrix(1, 0, 0, 1, 430, 500); }
    // &[data-tn-seat-id="232105"] { transform: matrix(1, 0, 0, 1, 290, 640); }
    // &[data-tn-seat-id="232026"] { transform: matrix(1, 0, 0, 1, 170, 340); }
    // &[data-tn-seat-id="231947"] { transform: matrix(1, 0, 0, 1, 60, 500); }
    // &[data-tn-seat-id="231788"] { transform: matrix(1, 0, 0, 1, 110, 470); }
    // &[data-tn-seat-id="231789"] { transform: matrix(1, 0, 0, 1, 90, 420); }
    // &[data-tn-seat-id="231790"] { transform: matrix(1, 0, 0, 1, 70, 370); }
    // &[data-tn-seat-id="231791"] { transform: matrix(1, 0, 0, 1, 50, 320); }
    // &[data-tn-seat-id="231792"] { transform: matrix(1, 0, 0, 1, 30, 270); }
    // &[data-tn-seat-id="231793"] { transform: matrix(1, 0, 0, 1, 10, 220); }
    // &[data-tn-seat-id="231794"] { transform: matrix(1, 0, 0, 1, -10, 170); }
    // &[data-tn-seat-id="231795"] { transform: matrix(1, 0, 0, 1, -30, 110); }
    // &[data-tn-seat-id="231796"] { transform: matrix(1, 0, 0, 1, -50, 60); }
    // &[data-tn-seat-id="231797"] { transform: matrix(1, 0, 0, 1, -70, 10); }
    // &[data-tn-seat-id="231805"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    // &[data-tn-seat-id="231806"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    // &[data-tn-seat-id="231807"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    // &[data-tn-seat-id="231808"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    // &[data-tn-seat-id="231809"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    // &[data-tn-seat-id="231810"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    // &[data-tn-seat-id="231811"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    // &[data-tn-seat-id="231812"] { transform: matrix(1, 0, 0, 1, -380, -20); }
    // &[data-tn-seat-id="231813"] { transform: matrix(1, 0, 0, 1, -380, -20); }
    // &[data-tn-seat-id="231812"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    // &[data-tn-seat-id="231813"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    // &[data-tn-seat-id="231814"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    // &[data-tn-seat-id="231815"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    // &[data-tn-seat-id="231816"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    // &[data-tn-seat-id="231817"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    // &[data-tn-seat-id="231818"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    // &[data-tn-seat-id="231819"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    // &[data-tn-seat-id="231820"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    // &[data-tn-seat-id="231821"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    // &[data-tn-seat-id="231822"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    // &[data-tn-seat-id="231823"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    // &[data-tn-seat-id="231824"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    // &[data-tn-seat-id="231825"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    // &[data-tn-seat-id="231826"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    // &[data-tn-seat-id="231827"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    // &[data-tn-seat-id="231828"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    // &[data-tn-seat-id="231829"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    // &[data-tn-seat-id="231830"] { transform: matrix(1, 0, 0, 1, -390, 0); }
    // &[data-tn-seat-id="231831"] { transform: matrix(1, 0, 0, 1, -400, 50); }
    // &[data-tn-seat-id="231832"] { transform: matrix(1, 0, 0, 1, -400, 100); }
    // &[data-tn-seat-id="231833"] { transform: matrix(1, 0, 0, 1, -410, 160); }

    // ROW 1

    &[data-tn-seat-id="232262"] { transform: matrix(1, 0, 0, 1, 580, 830); }
    &[data-tn-seat-id="232263"] { transform: matrix(1, 0, 0, 1, 580, 760); }
    &[data-tn-seat-id="232184"] { transform: matrix(1, 0, 0, 1, 430, 560); }
    &[data-tn-seat-id="232105"] { transform: matrix(1, 0, 0, 1, 290, 700); }
    &[data-tn-seat-id="232026"] { transform: matrix(1, 0, 0, 1, 200, 450); }
    &[data-tn-seat-id="231947"] { transform: matrix(1, 0, 0, 1, 60, 590); }
    &[data-tn-seat-id="231788"] { transform: matrix(1, 0, 0, 1, 110, 470); }
    &[data-tn-seat-id="231789"] { transform: matrix(1, 0, 0, 1, 90, 420); }
    &[data-tn-seat-id="231790"] { transform: matrix(1, 0, 0, 1, 70, 370); }
    &[data-tn-seat-id="231791"] { transform: matrix(1, 0, 0, 1, 50, 320); }
    &[data-tn-seat-id="231792"] { transform: matrix(1, 0, 0, 1, 30, 270); }
    &[data-tn-seat-id="231793"] { transform: matrix(1, 0, 0, 1, 10, 220); }
    &[data-tn-seat-id="231794"] { transform: matrix(1, 0, 0, 1, -10, 170); }
    &[data-tn-seat-id="231795"] { transform: matrix(1, 0, 0, 1, -30, 110); }
    &[data-tn-seat-id="231796"] { transform: matrix(1, 0, 0, 1, -50, 60); }
    &[data-tn-seat-id="231797"] { transform: matrix(1, 0, 0, 1, -70, 10); }
    &[data-tn-seat-id="231805"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="231806"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="231807"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="231808"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="231809"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="231810"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="231811"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="231812"] { transform: matrix(1, 0, 0, 1, -380, -20); }
    &[data-tn-seat-id="231813"] { transform: matrix(1, 0, 0, 1, -380, -20); }
    &[data-tn-seat-id="231812"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="231813"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="231814"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="231815"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="231816"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="231817"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="231818"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="231819"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="231820"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="231821"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="231822"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="231823"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="231824"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="231825"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="231826"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="231827"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="231828"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="231829"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="231830"] { transform: matrix(1, 0, 0, 1, -390, 0); }
    &[data-tn-seat-id="231831"] { transform: matrix(1, 0, 0, 1, -400, 50); }
    &[data-tn-seat-id="231832"] { transform: matrix(1, 0, 0, 1, -400, 100); }
    &[data-tn-seat-id="231833"] { transform: matrix(1, 0, 0, 1, -410, 160); }

    &[data-tn-seat-id="231834"] { transform: matrix(1, 0, 0, 1, -420, 210); }
    &[data-tn-seat-id="231835"] { transform: matrix(1, 0, 0, 1, -430, 260); }
    &[data-tn-seat-id="231836"] { transform: matrix(1, 0, 0, 1, -450, 310); }
    &[data-tn-seat-id="231836"] { transform: matrix(1, 0, 0, 1, -450, 310); }
    &[data-tn-seat-id="231836"] { transform: matrix(1, 0, 0, 1, -450, 310); }
    &[data-tn-seat-id="231837"] { transform: matrix(1, 0, 0, 1, -470, 360); }
    &[data-tn-seat-id="231838"] { transform: matrix(1, 0, 0, 1, -490, 410); }
    &[data-tn-seat-id="231838"] { transform: matrix(1, 0, 0, 1, -490, 410); }

    //row 2

    &[data-tn-seat-id="231707"] { transform: matrix(1, 0, 0, 1, 110, 430); }
    &[data-tn-seat-id="231708"] { transform: matrix(1, 0, 0, 1, 90, 380); }
    &[data-tn-seat-id="231709"] { transform: matrix(1, 0, 0, 1, 70, 330); }
    &[data-tn-seat-id="231710"] { transform: matrix(1, 0, 0, 1, 50, 280); }
    &[data-tn-seat-id="231711"] { transform: matrix(1, 0, 0, 1, 30, 230); }
    &[data-tn-seat-id="231712"] { transform: matrix(1, 0, 0, 1, 10, 180); }
    &[data-tn-seat-id="231713"] { transform: matrix(1, 0, 0, 1, -10, 130); }
    &[data-tn-seat-id="231714"] { transform: matrix(1, 0, 0, 1, -30, 80); }
    &[data-tn-seat-id="231715"] { transform: matrix(1, 0, 0, 1, -50, 30); }
    &[data-tn-seat-id="231716"] { transform: matrix(1, 0, 0, 1, -70, -20); }

    &[data-tn-seat-id="231724"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="231725"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="231726"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="231727"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="231728"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="231729"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="231730"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="231731"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="231732"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="231733"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="231734"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="231735"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="231736"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="231737"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="231738"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="231739"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="231740"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="231741"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="231742"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="231743"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="231744"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="231745"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="231746"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="231747"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="231748"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="231749"] { transform: matrix(1, 0, 0, 1, -330, -90); }

    &[data-tn-seat-id="231750"] { transform: matrix(1, 0, 0, 1, -310, -60); }
    &[data-tn-seat-id="231751"] { transform: matrix(1, 0, 0, 1, -330, -10); }
    &[data-tn-seat-id="231752"] { transform: matrix(1, 0, 0, 1, -350, 40); }
    &[data-tn-seat-id="231753"] { transform: matrix(1, 0, 0, 1, -370, 90); }
    &[data-tn-seat-id="231754"] { transform: matrix(1, 0, 0, 1, -380, 140); }
    &[data-tn-seat-id="231755"] { transform: matrix(1, 0, 0, 1, -400, 190); }
    &[data-tn-seat-id="231756"] { transform: matrix(1, 0, 0, 1, -420, 240); }
    &[data-tn-seat-id="231757"] { transform: matrix(1, 0, 0, 1, -440, 290); }
    &[data-tn-seat-id="231758"] { transform: matrix(1, 0, 0, 1, -460, 340); }
    &[data-tn-seat-id="231759"] { transform: matrix(1, 0, 0, 1, -480, 390); }

    //row 3

    &[data-tn-seat-id="231626"] { transform: matrix(1, 0, 0, 1, 150, 350); }
    &[data-tn-seat-id="231627"] { transform: matrix(1, 0, 0, 1, 130, 300); }
    &[data-tn-seat-id="231628"] { transform: matrix(1, 0, 0, 1, 110, 250); }
    &[data-tn-seat-id="231629"] { transform: matrix(1, 0, 0, 1, 90, 200); }
    &[data-tn-seat-id="231630"] { transform: matrix(1, 0, 0, 1, 70, 150); }
    &[data-tn-seat-id="231631"] { transform: matrix(1, 0, 0, 1, 50, 100); }
    &[data-tn-seat-id="231632"] { transform: matrix(1, 0, 0, 1, 30, 50); }
    &[data-tn-seat-id="231633"] { transform: matrix(1, 0, 0, 1, 10, -10); }
    &[data-tn-seat-id="231634"] { transform: matrix(1, 0, 0, 1, -10, -60); }
    &[data-tn-seat-id="231635"] { transform: matrix(1, 0, 0, 1, -30, -110); }

    &[data-tn-seat-id="231643"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="231644"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="231645"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="231646"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="231647"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="231648"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="231649"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="231650"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="231651"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="231652"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="231653"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="231654"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="231655"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="231656"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="231657"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="231658"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="231659"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="231660"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="231661"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="231662"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="231663"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="231664"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="231665"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="231666"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="231667"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="231668"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="231669"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="231670"] { transform: matrix(1, 0, 0, 1, -310, -140); }

    &[data-tn-seat-id="231671"] { transform: matrix(1, 0, 0, 1, -300, -100); }
    &[data-tn-seat-id="231672"] { transform: matrix(1, 0, 0, 1, -320, -50); }
    &[data-tn-seat-id="231673"] { transform: matrix(1, 0, 0, 1, -340, 0); }
    &[data-tn-seat-id="231674"] { transform: matrix(1, 0, 0, 1, -360, 50); }
    &[data-tn-seat-id="231675"] { transform: matrix(1, 0, 0, 1, -380, 100); }
    &[data-tn-seat-id="231676"] { transform: matrix(1, 0, 0, 1, -400, 150); }
    &[data-tn-seat-id="231677"] { transform: matrix(1, 0, 0, 1, -420, 200); }
    &[data-tn-seat-id="231678"] { transform: matrix(1, 0, 0, 1, -440, 250); }
    &[data-tn-seat-id="231679"] { transform: matrix(1, 0, 0, 1, -460, 300); }
    &[data-tn-seat-id="231680"] { transform: matrix(1, 0, 0, 1, -480, 350); }

    // row 4
    &[data-tn-seat-id="231546"] { transform: matrix(1, 0, 0, 1, 120, 260); }
    &[data-tn-seat-id="231547"] { transform: matrix(1, 0, 0, 1, 100, 210); }
    &[data-tn-seat-id="231548"] { transform: matrix(1, 0, 0, 1, 80, 160); }
    &[data-tn-seat-id="231549"] { transform: matrix(1, 0, 0, 1, 60, 110); }
    &[data-tn-seat-id="231550"] { transform: matrix(1, 0, 0, 1, 40, 60); }
    &[data-tn-seat-id="231551"] { transform: matrix(1, 0, 0, 1, 20, 10); }
    &[data-tn-seat-id="231552"] { transform: matrix(1, 0, 0, 1, 0, -40); }
    &[data-tn-seat-id="231553"] { transform: matrix(1, 0, 0, 1, -20, -90); }
    &[data-tn-seat-id="231554"] { transform: matrix(1, 0, 0, 1, -40, -140); }

    &[data-tn-seat-id="231562"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231563"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231564"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231565"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231566"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231567"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231568"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231569"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231570"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231571"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231572"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231573"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231574"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231575"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231576"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231577"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231578"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231579"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231580"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231581"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231582"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231583"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231584"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231585"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231586"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231587"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231588"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231589"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231590"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="231591"] { transform: matrix(1, 0, 0, 1, -290, -200); }

    &[data-tn-seat-id="231592"] { transform: matrix(1, 0, 0, 1, -300, -150); }
    &[data-tn-seat-id="231593"] { transform: matrix(1, 0, 0, 1, -320, -100); }
    &[data-tn-seat-id="231594"] { transform: matrix(1, 0, 0, 1, -340, -50); }
    &[data-tn-seat-id="231595"] { transform: matrix(1, 0, 0, 1, -360, 0); }
    &[data-tn-seat-id="231596"] { transform: matrix(1, 0, 0, 1, -380, 50); }
    &[data-tn-seat-id="231597"] { transform: matrix(1, 0, 0, 1, -400, 100); }
    &[data-tn-seat-id="231598"] { transform: matrix(1, 0, 0, 1, -420, 150); }
    &[data-tn-seat-id="231599"] { transform: matrix(1, 0, 0, 1, -440, 200); }
    &[data-tn-seat-id="231600"] { transform: matrix(1, 0, 0, 1, -460, 250); }


    //row 5
    &[data-tn-seat-id="231466"] { transform: matrix(1, 0, 0, 1, 90, 170); }
    &[data-tn-seat-id="231467"] { transform: matrix(1, 0, 0, 1, 70, 120); }
    &[data-tn-seat-id="231468"] { transform: matrix(1, 0, 0, 1, 50, 70); }
    &[data-tn-seat-id="231469"] { transform: matrix(1, 0, 0, 1, 30, 20); }
    &[data-tn-seat-id="231470"] { transform: matrix(1, 0, 0, 1, 10, -30); }
    &[data-tn-seat-id="231471"] { transform: matrix(1, 0, 0, 1, -10, -80); }
    &[data-tn-seat-id="231472"] { transform: matrix(1, 0, 0, 1, -30, -130); }
    &[data-tn-seat-id="231473"] { transform: matrix(1, 0, 0, 1, -50, -180); }
    &[data-tn-seat-id="231474"] { transform: matrix(1, 0, 0, 1, -70, -230); }

    &[data-tn-seat-id="231482"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231483"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231484"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231485"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231486"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231487"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231488"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231489"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231490"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231491"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231492"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231493"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231494"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231495"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231496"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231497"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231498"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231499"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231500"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231501"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231502"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231503"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231504"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231505"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231506"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231507"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231508"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231509"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231510"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231511"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="231512"] { transform: matrix(1, 0, 0, 1, -350, -250); }

    &[data-tn-seat-id="231513"] { transform: matrix(1, 0, 0, 1, -350, -230); }
    &[data-tn-seat-id="231514"] { transform: matrix(1, 0, 0, 1, -370, -180); }
    &[data-tn-seat-id="231515"] { transform: matrix(1, 0, 0, 1, -390, -130); }
    &[data-tn-seat-id="231516"] { transform: matrix(1, 0, 0, 1, -410, -80); }
    &[data-tn-seat-id="231517"] { transform: matrix(1, 0, 0, 1, -430, -30); }
    &[data-tn-seat-id="231518"] { transform: matrix(1, 0, 0, 1, -450, 20); }
    &[data-tn-seat-id="231519"] { transform: matrix(1, 0, 0, 1, -470, 70); }
    &[data-tn-seat-id="231520"] { transform: matrix(1, 0, 0, 1, -490, 120); }

    //row 6

    &[data-tn-seat-id="231386"] { transform: matrix(1, 0, 0, 1, 60, 80); }
    &[data-tn-seat-id="231387"] { transform: matrix(1, 0, 0, 1, 40, 30); }
    &[data-tn-seat-id="231388"] { transform: matrix(1, 0, 0, 1, 20, -20); }
    &[data-tn-seat-id="231389"] { transform: matrix(1, 0, 0, 1, 0, -70); }
    &[data-tn-seat-id="231390"] { transform: matrix(1, 0, 0, 1, -20, -120); }
    &[data-tn-seat-id="231391"] { transform: matrix(1, 0, 0, 1, -40, -180); }
    &[data-tn-seat-id="231392"] { transform: matrix(1, 0, 0, 1, -60, -230); }
    &[data-tn-seat-id="231393"] { transform: matrix(1, 0, 0, 1, -80, -280); }

    &[data-tn-seat-id="231401"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231402"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231403"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231404"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231405"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231406"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231407"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231408"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231409"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231410"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231411"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231412"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231413"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231414"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231415"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231416"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231417"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231418"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231419"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231420"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231421"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231422"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231423"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231424"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231425"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231426"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231427"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231428"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231429"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231430"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231431"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="231432"] { transform: matrix(1, 0, 0, 1, -340, -320); }

    &[data-tn-seat-id="231433"] { transform: matrix(1, 0, 0, 1, -330, -300); }
    &[data-tn-seat-id="231434"] { transform: matrix(1, 0, 0, 1, -340, -250); }
    &[data-tn-seat-id="231435"] { transform: matrix(1, 0, 0, 1, -360, -200); }
    &[data-tn-seat-id="231436"] { transform: matrix(1, 0, 0, 1, -380, -150); }
    &[data-tn-seat-id="231437"] { transform: matrix(1, 0, 0, 1, -400, -100); }
    &[data-tn-seat-id="231438"] { transform: matrix(1, 0, 0, 1, -420, -50); }
    &[data-tn-seat-id="231439"] { transform: matrix(1, 0, 0, 1, -440, 0); }

    //row 7
    &[data-tn-seat-id="231305"] { transform: matrix(1, 0, 0, 1, 20, 50); }
    &[data-tn-seat-id="231307"] { transform: matrix(1, 0, 0, 1, -20, -50); }
    &[data-tn-seat-id="231308"] { transform: matrix(1, 0, 0, 1, -40, -100); }
    &[data-tn-seat-id="231309"] { transform: matrix(1, 0, 0, 1, -60, -150); }
    &[data-tn-seat-id="231310"] { transform: matrix(1, 0, 0, 1, -80, -200); }
    &[data-tn-seat-id="231311"] { transform: matrix(1, 0, 0, 1, -100, -250); }
    &[data-tn-seat-id="231312"] { transform: matrix(1, 0, 0, 1, -120, -310); }
    &[data-tn-seat-id="231313"] { transform: matrix(1, 0, 0, 1, -130, -370); }
    &[data-tn-seat-id="231320"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231321"] { transform: matrix(1, 0, 0, 1, -350, -390); }
    &[data-tn-seat-id="231320"] { transform: matrix(1, 0, 0, 1, -370, -390); }
    &[data-tn-seat-id="231321"] { transform: matrix(1, 0, 0, 1, -370, -390); }
    &[data-tn-seat-id="231320"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231321"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231322"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231323"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231324"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231325"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231326"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231327"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231328"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231329"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231330"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231331"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231332"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231333"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231334"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231335"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231336"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231337"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231338"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231339"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231340"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231341"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231342"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231343"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231344"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231345"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231346"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231347"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231348"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231349"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231350"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231351"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231352"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="231353"] { transform: matrix(1, 0, 0, 1, -360, -390); }

    &[data-tn-seat-id="231354"] { transform: matrix(1, 0, 0, 1, -360, -360); }
    &[data-tn-seat-id="231355"] { transform: matrix(1, 0, 0, 1, -380, -310); }
    &[data-tn-seat-id="231356"] { transform: matrix(1, 0, 0, 1, -400, -260); }
}

.tn-syos-seat-map__seat {
    //SMAP ID 252 The Lyric Opera Map
    //balcony
    &[data-tn-seat-id="271132"] { transform: matrix(1, 0, 0, 1, -650, 530); }//AA4
    &[data-tn-seat-id="271133"] { transform: matrix(1, 0, 0, 1, -670, 580); }
    &[data-tn-seat-id="271134"] { transform: matrix(1, 0, 0, 1, -680, 640); }
    &[data-tn-seat-id="271135"] { transform: matrix(1, 0, 0, 1, -690, 700); }//AA1

    &[data-tn-seat-id="271052"] { transform: matrix(1, 0, 0, 1, -610, 450); }//BB5
    &[data-tn-seat-id="271053"] { transform: matrix(1, 0, 0, 1, -630, 500); }
    &[data-tn-seat-id="271054"] { transform: matrix(1, 0, 0, 1, -640, 560); }
    &[data-tn-seat-id="271055"] { transform: matrix(1, 0, 0, 1, -650, 610); }
    &[data-tn-seat-id="271056"] { transform: matrix(1, 0, 0, 1, -670, 670); }//BB1

    // &[data-tn-seat-id="232235"] { transform: matrix(1, 0, 0, 1, -650, 620); }
    &[data-tn-seat-id="270972"] { transform: matrix(1, 0, 0, 1, -560, 390); }//CC6
    &[data-tn-seat-id="270973"] { transform: matrix(1, 0, 0, 1, -580, 440); }
    &[data-tn-seat-id="270974"] { transform: matrix(1, 0, 0, 1, -600, 490); }
    &[data-tn-seat-id="270975"] { transform: matrix(1, 0, 0, 1, -610, 550); }
    &[data-tn-seat-id="270976"] { transform: matrix(1, 0, 0, 1, -620, 610); }
    &[data-tn-seat-id="270977"] { transform: matrix(1, 0, 0, 1, -640, 660); }//CC1

    &[data-tn-seat-id="270664"] { transform: matrix(1, 0, 0, 1, -810, 1040); }//GG2
    &[data-tn-seat-id="270665"] { transform: matrix(1, 0, 0, 1, -820, 1120); }//GG1

    &[data-tn-seat-id="270892"] { transform: matrix(1, 0, 0, 1, -520, 330); }//DD7
    &[data-tn-seat-id="270893"] { transform: matrix(1, 0, 0, 1, -540, 380); }
    &[data-tn-seat-id="270894"] { transform: matrix(1, 0, 0, 1, -560, 430); }
    &[data-tn-seat-id="270895"] { transform: matrix(1, 0, 0, 1, -580, 480); }
    &[data-tn-seat-id="270896"] { transform: matrix(1, 0, 0, 1, -600, 530); }
    &[data-tn-seat-id="270897"] { transform: matrix(1, 0, 0, 1, -620, 580); }
    &[data-tn-seat-id="270898"] { transform: matrix(1, 0, 0, 1, -640, 630); }//DD1

    &[data-tn-seat-id="270812"] { transform: matrix(1, 0, 0, 1, -460, 270); }//EE7
    &[data-tn-seat-id="270813"] { transform: matrix(1, 0, 0, 1, -480, 320); }
    &[data-tn-seat-id="270814"] { transform: matrix(1, 0, 0, 1, -500, 370); }
    &[data-tn-seat-id="270815"] { transform: matrix(1, 0, 0, 1, -520, 420); }
    &[data-tn-seat-id="270816"] { transform: matrix(1, 0, 0, 1, -540, 470); }
    &[data-tn-seat-id="270817"] { transform: matrix(1, 0, 0, 1, -560, 520); }
    &[data-tn-seat-id="270818"] { transform: matrix(1, 0, 0, 1, -580, 570); }//EE1
    // &[data-tn-seat-id="232078"] { transform: matrix(1, 0, 0, 1, -630, 630); }

    &[data-tn-seat-id="270732"] { transform: matrix(1, 0, 0, 1, -410, 210); }//FF7
    &[data-tn-seat-id="270733"] { transform: matrix(1, 0, 0, 1, -430, 260); }
    &[data-tn-seat-id="270734"] { transform: matrix(1, 0, 0, 1, -450, 310); }
    &[data-tn-seat-id="270735"] { transform: matrix(1, 0, 0, 1, -470, 360); }
    &[data-tn-seat-id="270736"] { transform: matrix(1, 0, 0, 1, -490, 410); }
    &[data-tn-seat-id="270737"] { transform: matrix(1, 0, 0, 1, -510, 460); }
    &[data-tn-seat-id="270738"] { transform: matrix(1, 0, 0, 1, -530, 510); }//FF1

    //Row 1
    &[data-tn-seat-id="271082"] { transform: matrix(1, 0, 0, 1, 580, 830); } //A50
    &[data-tn-seat-id="271083"] { transform: matrix(1, 0, 0, 1, 580, 760); } //A49
    &[data-tn-seat-id="271004"] { transform: matrix(1, 0, 0, 1, 430, 560); } //A48
    &[data-tn-seat-id="270925"] { transform: matrix(1, 0, 0, 1, 290, 700); } //A47
    &[data-tn-seat-id="270846"] { transform: matrix(1, 0, 0, 1, 200, 450); } //A46
    &[data-tn-seat-id="270767"] { transform: matrix(1, 0, 0, 1, 60, 590); } //A45

    &[data-tn-seat-id="270608"] { transform: matrix(1, 0, 0, 1, 110, 470); } //A44
    &[data-tn-seat-id="270609"] { transform: matrix(1, 0, 0, 1, 90, 420); }
    &[data-tn-seat-id="270610"] { transform: matrix(1, 0, 0, 1, 70, 370); }
    &[data-tn-seat-id="270611"] { transform: matrix(1, 0, 0, 1, 50, 320); }
    &[data-tn-seat-id="270612"] { transform: matrix(1, 0, 0, 1, 30, 270); }
    &[data-tn-seat-id="270613"] { transform: matrix(1, 0, 0, 1, 10, 220); }
    &[data-tn-seat-id="270614"] { transform: matrix(1, 0, 0, 1, -10, 170); }
    &[data-tn-seat-id="270615"] { transform: matrix(1, 0, 0, 1, -30, 110); }
    &[data-tn-seat-id="270616"] { transform: matrix(1, 0, 0, 1, -50, 60); }
    &[data-tn-seat-id="270617"] { transform: matrix(1, 0, 0, 1, -70, 10); }

    &[data-tn-seat-id="270625"] { transform: matrix(1, 0, 0, 1, -390, -20); } //A34
    &[data-tn-seat-id="270626"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="270627"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="270628"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="270629"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="270630"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="270631"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="270632"] { transform: matrix(1, 0, 0, 1, -380, -20); }
    &[data-tn-seat-id="270633"] { transform: matrix(1, 0, 0, 1, -380, -20); }
    &[data-tn-seat-id="270632"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="270633"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="270634"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="270635"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="270636"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="270637"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="270638"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="270639"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="270640"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="270641"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="270642"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="270643"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="270644"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="270645"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="270646"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="270647"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="270648"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="270649"] { transform: matrix(1, 0, 0, 1, -390, -20); }
    &[data-tn-seat-id="270650"] { transform: matrix(1, 0, 0, 1, -390, 0); }
    &[data-tn-seat-id="270651"] { transform: matrix(1, 0, 0, 1, -400, 50); }
    &[data-tn-seat-id="270652"] { transform: matrix(1, 0, 0, 1, -400, 100); }
    &[data-tn-seat-id="270653"] { transform: matrix(1, 0, 0, 1, -410, 160); }

    &[data-tn-seat-id="270654"] { transform: matrix(1, 0, 0, 1, -420, 210); }
    &[data-tn-seat-id="270655"] { transform: matrix(1, 0, 0, 1, -430, 260); }
    &[data-tn-seat-id="270656"] { transform: matrix(1, 0, 0, 1, -450, 310); }
    &[data-tn-seat-id="270657"] { transform: matrix(1, 0, 0, 1, -470, 360); }
    &[data-tn-seat-id="270658"] { transform: matrix(1, 0, 0, 1, -490, 410); } //A1

    //Row 2
    &[data-tn-seat-id="270527"] { transform: matrix(1, 0, 0, 1, 110, 430); }
    &[data-tn-seat-id="270528"] { transform: matrix(1, 0, 0, 1, 90, 380); }
    &[data-tn-seat-id="270529"] { transform: matrix(1, 0, 0, 1, 70, 330); }
    &[data-tn-seat-id="270530"] { transform: matrix(1, 0, 0, 1, 50, 280); }
    &[data-tn-seat-id="270531"] { transform: matrix(1, 0, 0, 1, 30, 230); }
    &[data-tn-seat-id="270532"] { transform: matrix(1, 0, 0, 1, 10, 180); }
    &[data-tn-seat-id="270533"] { transform: matrix(1, 0, 0, 1, -10, 130); }
    &[data-tn-seat-id="270534"] { transform: matrix(1, 0, 0, 1, -30, 80); }
    &[data-tn-seat-id="270535"] { transform: matrix(1, 0, 0, 1, -50, 30); }
    &[data-tn-seat-id="270536"] { transform: matrix(1, 0, 0, 1, -70, -20); } //B37

    &[data-tn-seat-id="270544"] { transform: matrix(1, 0, 0, 1, -330, -90); } //B36
    &[data-tn-seat-id="270545"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="270546"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="270547"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="270548"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="270549"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="270550"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="270551"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="270552"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="270553"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="270554"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="270555"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="270556"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="270557"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="270558"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="270559"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="270560"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="270561"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="270562"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="270563"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="270564"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="270565"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="270566"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="270567"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="270568"] { transform: matrix(1, 0, 0, 1, -330, -90); }
    &[data-tn-seat-id="270569"] { transform: matrix(1, 0, 0, 1, -330, -90); }

    &[data-tn-seat-id="270570"] { transform: matrix(1, 0, 0, 1, -310, -60); }
    &[data-tn-seat-id="270571"] { transform: matrix(1, 0, 0, 1, -330, -10); }
    &[data-tn-seat-id="270572"] { transform: matrix(1, 0, 0, 1, -350, 40); }
    &[data-tn-seat-id="270573"] { transform: matrix(1, 0, 0, 1, -370, 90); }
    &[data-tn-seat-id="270574"] { transform: matrix(1, 0, 0, 1, -380, 140); }
    &[data-tn-seat-id="270575"] { transform: matrix(1, 0, 0, 1, -400, 190); }
    &[data-tn-seat-id="270576"] { transform: matrix(1, 0, 0, 1, -420, 240); }
    &[data-tn-seat-id="270577"] { transform: matrix(1, 0, 0, 1, -440, 290); }
    &[data-tn-seat-id="270578"] { transform: matrix(1, 0, 0, 1, -460, 340); }
    &[data-tn-seat-id="270579"] { transform: matrix(1, 0, 0, 1, -480, 390); } //B1

    //Row 3
    &[data-tn-seat-id="270446"] { transform: matrix(1, 0, 0, 1, 150, 350); } //C48
    &[data-tn-seat-id="270447"] { transform: matrix(1, 0, 0, 1, 130, 300); }
    &[data-tn-seat-id="270448"] { transform: matrix(1, 0, 0, 1, 110, 250); }
    &[data-tn-seat-id="270449"] { transform: matrix(1, 0, 0, 1, 90, 200); }
    &[data-tn-seat-id="270450"] { transform: matrix(1, 0, 0, 1, 70, 150); }
    &[data-tn-seat-id="270451"] { transform: matrix(1, 0, 0, 1, 50, 100); }
    &[data-tn-seat-id="270452"] { transform: matrix(1, 0, 0, 1, 30, 50); }
    &[data-tn-seat-id="270453"] { transform: matrix(1, 0, 0, 1, 10, -10); }
    &[data-tn-seat-id="270454"] { transform: matrix(1, 0, 0, 1, -10, -60); }
    &[data-tn-seat-id="270455"] { transform: matrix(1, 0, 0, 1, -30, -110); } //C39

    &[data-tn-seat-id="270463"] { transform: matrix(1, 0, 0, 1, -310, -140); } //C38
    &[data-tn-seat-id="270464"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="270465"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="270466"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="270467"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="270468"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="270469"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="270470"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="270471"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="270472"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="270473"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="270474"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="270475"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="270476"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="270477"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="270478"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="270479"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="270480"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="270481"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="270482"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="270483"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="270484"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="270485"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="270486"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="270487"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="270488"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="270489"] { transform: matrix(1, 0, 0, 1, -310, -140); }
    &[data-tn-seat-id="270490"] { transform: matrix(1, 0, 0, 1, -310, -140); }

    &[data-tn-seat-id="270491"] { transform: matrix(1, 0, 0, 1, -300, -100); }
    &[data-tn-seat-id="270492"] { transform: matrix(1, 0, 0, 1, -320, -50); }
    &[data-tn-seat-id="270493"] { transform: matrix(1, 0, 0, 1, -340, 0); }
    &[data-tn-seat-id="270494"] { transform: matrix(1, 0, 0, 1, -360, 50); }
    &[data-tn-seat-id="270495"] { transform: matrix(1, 0, 0, 1, -380, 100); }
    &[data-tn-seat-id="270496"] { transform: matrix(1, 0, 0, 1, -400, 150); }
    &[data-tn-seat-id="270497"] { transform: matrix(1, 0, 0, 1, -420, 200); }
    &[data-tn-seat-id="270498"] { transform: matrix(1, 0, 0, 1, -440, 250); }
    &[data-tn-seat-id="270499"] { transform: matrix(1, 0, 0, 1, -460, 300); }
    &[data-tn-seat-id="270500"] { transform: matrix(1, 0, 0, 1, -480, 350); }

    // row 4
    &[data-tn-seat-id="270366"] { transform: matrix(1, 0, 0, 1, 120, 260); } //D48
    &[data-tn-seat-id="270367"] { transform: matrix(1, 0, 0, 1, 100, 210); }
    &[data-tn-seat-id="270368"] { transform: matrix(1, 0, 0, 1, 80, 160); }
    &[data-tn-seat-id="270369"] { transform: matrix(1, 0, 0, 1, 60, 110); }
    &[data-tn-seat-id="270370"] { transform: matrix(1, 0, 0, 1, 40, 60); }
    &[data-tn-seat-id="270371"] { transform: matrix(1, 0, 0, 1, 20, 10); }
    &[data-tn-seat-id="270372"] { transform: matrix(1, 0, 0, 1, 0, -40); }
    &[data-tn-seat-id="270373"] { transform: matrix(1, 0, 0, 1, -20, -90); }
    &[data-tn-seat-id="270374"] { transform: matrix(1, 0, 0, 1, -40, -140); } //D40

    &[data-tn-seat-id="270382"] { transform: matrix(1, 0, 0, 1, -290, -200); } //D39
    &[data-tn-seat-id="270383"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="270384"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="270385"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="270386"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="270387"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="270388"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="270389"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="270390"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="270391"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="270392"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="270393"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="270394"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="270395"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="270396"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="270397"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="270398"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="270399"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="270400"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="270401"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="270402"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="270403"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="270404"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="270405"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="270406"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="270407"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="270408"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="270409"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="270410"] { transform: matrix(1, 0, 0, 1, -290, -200); }
    &[data-tn-seat-id="270411"] { transform: matrix(1, 0, 0, 1, -290, -200); }

    &[data-tn-seat-id="270412"] { transform: matrix(1, 0, 0, 1, -300, -150); }
    &[data-tn-seat-id="270413"] { transform: matrix(1, 0, 0, 1, -320, -100); }
    &[data-tn-seat-id="270414"] { transform: matrix(1, 0, 0, 1, -340, -50); }
    &[data-tn-seat-id="270415"] { transform: matrix(1, 0, 0, 1, -360, 0); }
    &[data-tn-seat-id="270416"] { transform: matrix(1, 0, 0, 1, -380, 50); }
    &[data-tn-seat-id="270417"] { transform: matrix(1, 0, 0, 1, -400, 100); }
    &[data-tn-seat-id="270418"] { transform: matrix(1, 0, 0, 1, -420, 150); }
    &[data-tn-seat-id="270419"] { transform: matrix(1, 0, 0, 1, -440, 200); }
    &[data-tn-seat-id="270420"] { transform: matrix(1, 0, 0, 1, -460, 250); } //D1

    //Row 5
    &[data-tn-seat-id="270286"] { transform: matrix(1, 0, 0, 1, 90, 170); }//E48
    &[data-tn-seat-id="270287"] { transform: matrix(1, 0, 0, 1, 70, 120); }
    &[data-tn-seat-id="270288"] { transform: matrix(1, 0, 0, 1, 50, 70); }
    &[data-tn-seat-id="270289"] { transform: matrix(1, 0, 0, 1, 30, 20); }
    &[data-tn-seat-id="270290"] { transform: matrix(1, 0, 0, 1, 10, -30); }
    &[data-tn-seat-id="270291"] { transform: matrix(1, 0, 0, 1, -10, -80); }
    &[data-tn-seat-id="270292"] { transform: matrix(1, 0, 0, 1, -30, -130); }
    &[data-tn-seat-id="270293"] { transform: matrix(1, 0, 0, 1, -50, -180); }
    &[data-tn-seat-id="270294"] { transform: matrix(1, 0, 0, 1, -70, -230); }//E40

    &[data-tn-seat-id="270302"] { transform: matrix(1, 0, 0, 1, -360, -260); }//E39
    &[data-tn-seat-id="270303"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270304"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270305"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270306"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270307"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270308"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270309"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270310"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270311"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270312"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270313"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270314"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270315"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270316"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270317"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270318"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270319"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270320"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270321"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270322"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270323"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270324"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270325"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270326"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270327"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270328"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270329"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270330"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270331"] { transform: matrix(1, 0, 0, 1, -360, -260); }
    &[data-tn-seat-id="270332"] { transform: matrix(1, 0, 0, 1, -350, -250); }

    &[data-tn-seat-id="270333"] { transform: matrix(1, 0, 0, 1, -350, -230); }
    &[data-tn-seat-id="270334"] { transform: matrix(1, 0, 0, 1, -370, -180); }
    &[data-tn-seat-id="270335"] { transform: matrix(1, 0, 0, 1, -390, -130); }
    &[data-tn-seat-id="270336"] { transform: matrix(1, 0, 0, 1, -410, -80); }
    &[data-tn-seat-id="270337"] { transform: matrix(1, 0, 0, 1, -430, -30); }
    &[data-tn-seat-id="270338"] { transform: matrix(1, 0, 0, 1, -450, 20); }
    &[data-tn-seat-id="270339"] { transform: matrix(1, 0, 0, 1, -470, 70); }
    &[data-tn-seat-id="270340"] { transform: matrix(1, 0, 0, 1, -490, 120); }//E1

    //Row 6
    &[data-tn-seat-id="270206"] { transform: matrix(1, 0, 0, 1, 60, 80); }//F47
    &[data-tn-seat-id="270207"] { transform: matrix(1, 0, 0, 1, 40, 30); }
    &[data-tn-seat-id="270208"] { transform: matrix(1, 0, 0, 1, 20, -20); }
    &[data-tn-seat-id="270209"] { transform: matrix(1, 0, 0, 1, 0, -70); }
    &[data-tn-seat-id="270210"] { transform: matrix(1, 0, 0, 1, -20, -120); }
    &[data-tn-seat-id="270211"] { transform: matrix(1, 0, 0, 1, -40, -180); }
    &[data-tn-seat-id="270212"] { transform: matrix(1, 0, 0, 1, -60, -230); }
    &[data-tn-seat-id="270213"] { transform: matrix(1, 0, 0, 1, -80, -280); }//F40

    &[data-tn-seat-id="270221"] { transform: matrix(1, 0, 0, 1, -340, -320); }//F39
    &[data-tn-seat-id="270222"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270223"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270224"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270225"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270226"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270227"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270228"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270229"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270230"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270231"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270232"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270233"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270234"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270235"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270236"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270237"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270238"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270239"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270240"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270241"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270242"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270243"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270244"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270245"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270246"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270247"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270248"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270249"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270250"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270251"] { transform: matrix(1, 0, 0, 1, -340, -320); }
    &[data-tn-seat-id="270252"] { transform: matrix(1, 0, 0, 1, -340, -320); }

    &[data-tn-seat-id="270253"] { transform: matrix(1, 0, 0, 1, -330, -300); }
    &[data-tn-seat-id="270254"] { transform: matrix(1, 0, 0, 1, -340, -250); }
    &[data-tn-seat-id="270255"] { transform: matrix(1, 0, 0, 1, -360, -200); }
    &[data-tn-seat-id="270256"] { transform: matrix(1, 0, 0, 1, -380, -150); }
    &[data-tn-seat-id="270257"] { transform: matrix(1, 0, 0, 1, -400, -100); }
    &[data-tn-seat-id="270258"] { transform: matrix(1, 0, 0, 1, -420, -50); }
    &[data-tn-seat-id="270259"] { transform: matrix(1, 0, 0, 1, -440, 0); }//F1

    //Row 7
    &[data-tn-seat-id="270125"] { transform: matrix(1, 0, 0, 1, 20, 50); }//G46
    &[data-tn-seat-id="270127"] { transform: matrix(1, 0, 0, 1, -20, -50); }
    &[data-tn-seat-id="270128"] { transform: matrix(1, 0, 0, 1, -40, -100); }
    &[data-tn-seat-id="270129"] { transform: matrix(1, 0, 0, 1, -60, -150); }
    &[data-tn-seat-id="270130"] { transform: matrix(1, 0, 0, 1, -80, -200); }
    &[data-tn-seat-id="270131"] { transform: matrix(1, 0, 0, 1, -100, -250); }
    &[data-tn-seat-id="270132"] { transform: matrix(1, 0, 0, 1, -120, -310); }
    &[data-tn-seat-id="270133"] { transform: matrix(1, 0, 0, 1, -130, -370); }//G38

    &[data-tn-seat-id="270140"] { transform: matrix(1, 0, 0, 1, -360, -390); }//G37
    &[data-tn-seat-id="270141"] { transform: matrix(1, 0, 0, 1, -350, -390); }
    &[data-tn-seat-id="270140"] { transform: matrix(1, 0, 0, 1, -370, -390); }
    &[data-tn-seat-id="270141"] { transform: matrix(1, 0, 0, 1, -370, -390); }
    &[data-tn-seat-id="270140"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270141"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270142"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270143"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270144"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270145"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270146"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270147"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270148"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270149"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270150"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270151"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270152"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270153"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270154"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270155"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270156"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270157"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270158"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270159"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270160"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270161"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270162"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270163"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270164"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270165"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270166"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270167"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270168"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270169"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270170"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270171"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270172"] { transform: matrix(1, 0, 0, 1, -360, -390); }
    &[data-tn-seat-id="270173"] { transform: matrix(1, 0, 0, 1, -360, -390); }

    &[data-tn-seat-id="270174"] { transform: matrix(1, 0, 0, 1, -360, -360); }
    &[data-tn-seat-id="270175"] { transform: matrix(1, 0, 0, 1, -380, -310); }
    &[data-tn-seat-id="270176"] { transform: matrix(1, 0, 0, 1, -400, -260); }//G1
}

}
