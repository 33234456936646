
.max-width {
    @media (min-width: $screen-xs-max) {
        width: 90%;
    }
    max-width: $max-site-width;
    margin-left: auto;
    margin-right: auto;
}
.global-header {
    padding: 60px 0 30px;
    text-align: center;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}
.page-wrap {
    background-color: $page-background;
}
.content-wrap {
    background: $white;
    padding-bottom: 50px;
    min-height: 90vh;
    @media (min-width: $screen-xs-max) {
        padding: 1% 3%;
    }
}
// inset style used throughout

@mixin inset() {
    background: rgba($white, 0.8);
    border: 1px solid  rgba(0,0,0,0.09);
    padding: 20px;
    border-radius: 4px;
}

.tn-events-filter-component,
.tn-listing-component,
.tn-calendar-component,
.tn-cart-component,
.tn-listing-legend-container {
    @include inset();
}

// subnav bar
.tn-subnav-component {
    margin: 80px 0;
    width: 100%;
    background-color: $light-bg;
    padding: 8px;
    border-bottom: 1px solid rgba(0,0,0,0.1);

    #tn-apply-promo {
        #tn-subnav-promo-code {
            padding: 3.5px; //match Submit button
            font-size: 100%;
            line-height: inherit;
        }
    
        #tn-subnav-promo-button {
            text-transform: capitalize;
            text-decoration: none;
            @extend .btn;
            @extend .btn-primary;
            @extend .btn-sm;
        }
    }

    @include mq($until: desktop) {
        flex-wrap: wrap;
        .tn-login-link .tn-logout-link {
            display: inline !important;
        }

        .tn-promo-box {
            flex-basis: 100%;
            text-align: left;
            margin-top: $inuit-global-spacing-unit-tiny;
        }
    }
}

.tn-heading {
    font-size: 200%;
    text-align: center;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    padding: 0 0 20px 0;
    @media (max-width: $screen-xs-max) {
        font-size: 140%;
    }
}

// event page head

.tn-detail-page-header {
    padding: 5% 6%;
    border: 1px solid rgba(0,0,0,0.1);
    background: rgba(255,255,255,0.7);
    margin-left: -5%;
}

// cart

.tn-subnav-component .tn-cart-link  .tn-link {
    // display: none;
}
