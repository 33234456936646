/* ==========================================================================
   #GLOBL HEADER styles
   ========================================================================== */

.c-global-header {
    width: 100%;
    max-width: $max-site-width;
    background-color: $white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: $header-height-mobile;
    z-index: 600;
    -webkit-box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.1);

    .admin-bar & {
        top: 32px;
    }
}

.c-global-header__logo {
    background-image: url('#{$relative-path}/img/trp-logo-orange.svg');
    background-repeat: no-repeat;
    width: 130px;
    height: 42px;
    background-size: contain;
    display: inline-block;
    vertical-align: top;
    position: absolute;
    top: 15px;
    left: 10px;


    @include mq($from: tablet) {
        top: 10px;
        width: 165px;
        height: 42px;
    }

    @include mq($from: desktop) {
        left: 50%;
        @include vendor(transform, translateX(-50%));
    }
}

.c-global-header__lhs {
    width: calc(50% - 80px);
    text-align: center;
    display: none;

    @include mq($from: desktop) {
        display: inline-block;
    }
}

.c-global-header__rhs {
}

.c-main-navigation {
    margin-top: 17px;
    font-size: 120%;
    @include font-bold;

    a {
        margin: 0 3%;
        position: relative;

        &::after {
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: darken($grey--lighter, 5%);
            content: '';
            opacity: 0;
            @include vendor(transform, translateY(10px));
            @include transition();
        }

        [data-whatintent="mouse"] & {
            &:hover::after {
                @include vendor(transform, translateY(0));
                opacity: 1;
            }
        }
    }
}

.c-account-navigation {
    text-align: center;
    @include font-bold;
    font-size: 85%;
    text-transform: uppercase;
    opacity: 0.6;
    text-align: center;
    position: absolute;
    right: 110px;
    top: 0;
    letter-spacing: 0.05em;
    margin-top: 22px;

    @include mq($from: tablet) {
        right: 190px;
    }

    @include mq($from: desktop) {
        right: 20px;
        font-size: 75%;
        width: calc(50% + 30px);
    }

    a {
        margin: 0 5px;
        position: relative;

        @include mq($from: desktop) {
            margin: 0 20px;
        }

        &::after {
            position: absolute;
            bottom: -10px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: darken($grey--lighter, 5%);
            content: '';
            opacity: 0;
            @include vendor(transform, translateY(10px));
            @include transition();
        }

        [data-whatintent="mouse"] & {
            &:hover::after {
                @include vendor(transform, translateY(0));
                opacity: 1;
            }
        }
    }

    span {
        margin-left: 10px;

        @include mq($until: desktop) {
            display: none;
        }
    }
    span.c-cart-value {
        margin-left: 5px;
    }
}

.c-navigation-trigger {

    &:before {
        position: absolute;
        content: '';
        height: 60%;
        width: 1px;
        background-color: $grey--lighter;
        left: 0;
        top: 12px;
    }

    position: absolute;
    right: 0;
    top: 0;
    font-size: 90%;
    @include font-bold;
    padding: 22px 15px 17px;
    cursor: pointer;

    @include mq($from: tablet) {
        padding: 17px 40px 15px;
        font-size: 120%;
    }

    [data-whatintent="mouse"] & {
        &:hover {
            background-color: $orange;

            &:before {
                opacity: 0;
            }
        }
    }

    .fas {
        font-size: 80%;
        @include font-light;
        margin-left: 10px;
    }

    .fa-plus{
        .nav-open & {
            display: none;
        }
    }

    .fa-minus {
        display: none;

        .nav-open & {
            display: inline;
        }
    }
}

.c-explore-menu {
    height: 0;
    overflow: hidden;
    background-color: $orange;
    width: 100%;
    max-width: 2000px;
    top: $header-height-mobile;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    position: fixed;
    @include transition();
    z-index: 1000;

    .nav-open & {
        height: calc(100vh - #{$header-height-mobile});
    }
}

.c-explore-menu__wrapper {
    padding: 5%;
}

body.nav-open, html.nav-open, body.booking--open, html.booking--open {
    overflow: hidden;
    height: 100%;
}

.c-explore-menu-navigation {
    margin: 0 0 30px;
    padding: 0;
    list-style-type: none;

    li {
        display: block;
        font-size: 120%;
        margin: 0;
        padding: 4px 10px;
        position: relative;
        @include transition();

        &::after {
            position: absolute;
            top: 50%;
            @include vendor(transform, translateY(-50%));
            right: 15px;
            content: '\f054';
            font-family: 'Font Awesome 5 Pro';
            opacity: 0;
            font-size: 90%;
            @include font-bold;
            @include transition();
        }

        [data-whatintent="mouse"] & {
            &:hover {
                background-color: rgba($black, 0.1);

                &::after {
                    opacity: 1;
                }
            }
        }

        a {
            display: block;
            width: 100%;
        }
    }
}

.c-explore-menu-wrapper {
    @include mq($until: tablet) {
        border-bottom: 1px solid rgba($black, 0.3);
    }
}

.c-explore-menu__title {
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    padding-right: 40px;

    &::after {
        position: absolute;
        right: 0;
        top: 50%;
        @include vendor(transform, translateY(-50%));
        font-family: 'Font Awesome 5 Pro';
        content: '\f054';
        @include font-bold;
        font-size: 70%;
    }
}

.c-explore-menu__title {
    position: relative;
    width: 100%;
    margin: 0;
    font-size: $font-size-medium;

    padding: 20px 40px 20px 10px;

    @include mq($from: tablet) {
        font-size: 200%;
        padding: 10px 40px 10px 10px;
    }
}

a .c-explore-menu__title {
    @include transition();

    [data-whatintent="mouse"] & {
        &:hover {
            background-color: rgba($black, 0.1);
        }
    }

    &::after {
        position: absolute;
        right: 10px;
        top: 50%;
        @include vendor(transform, translateY(-50%));
        font-family: 'Font Awesome 5 Pro';
        content: '\f054';
        @include font-bold;
        font-size: 70%;
    }
}

.c-explore-menu__whats-on {
    @include mq($from: mobile) {
        margin-bottom: $inuit-global-spacing-unit;
    }
    @include mq($from: tablet) {
        margin-bottom: $inuit-global-spacing-unit-large;
    }
    @include mq($from: desktop) {
        margin-bottom: $inuit-global-spacing-unit-huge;
    }
}
