.tn-prod-listing {
    a {
        color: $text-color;
    }
}
.tn-perf-listing-action {
    @extend .btn;
    @extend .btn-primary;
    text-align: center;
}

.tn-events-filter-component {
    font-size: 80%;
    .row:first-of-type {
        border-bottom: 1px solid darken($light-bg, 10%);
    }
    h3 {
        font-size: 100%;
    }
}

.tn-listing-results-calendar {

}
.fc-event {
    border-radius: 2px;
    font-size: 80%;
    color: $white;
    background: $brand-success;
    &:hover {
        color: $white;
        background: lighten($brand-success, 10%);
    }
}

.tn-btn-datepicker__btn-container {

    .glyphicon-calendar {
        color: $black;
    }

}
