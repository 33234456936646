//
// Contact Preferences
//
.tn-component-contact-permissions__settings__item {
    .tn-component__radio-group {
        .ng-scope {
            display: inline-block;
            width: auto;
            margin-right: 12px;
        }
    }
}

.tn-account-interests-page {
    .tn-component-contact-permissions {
        padding: $inuit-global-spacing-unit-tiny 0;
        @include mq($from: tablet) {
            padding: $inuit-global-spacing-unit 0;
        }
    }
}
